@font-face {
	font-family: 'Hoefler Text';
	src: url('../assets/fonts/HoeflerText-RegularItalic.eot'); /* IE9 Compat Modes */
	src: url('../assets/fonts/HoeflerText-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../assets/fonts/HoeflerText-RegularItalic.woff') format('woff'), /* Modern Browsers */
			url('../assets/fonts/HoeflerText-RegularItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
			url('../assets/fonts/HoeflerText-RegularItalic.svg#17dc1282a026ce147355ea4020cc6aeb') format('svg'); /* Legacy iOS */

	font-style:   italic;
	font-weight:  400;
}

@font-face {
	font-family: 'circular book';
	src: url('../assets/fonts/CircularStd_Book/CircularStd_Book.eot'); /* IE9 Compat Modes */
	src: url('../assets/fonts/CircularStd_Book/CircularStd_Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.woff') format('woff'), /* Modern Browsers */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.ttf')  format('truetype'), /* Safari, Android, iOS */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.svg#dd452d63ae05ddb466f19713a7d09fd5') format('svg'); /* Legacy iOS */
	font-style:   italic;
	font-weight:  400;
}
@font-face {
	font-family: 'circular book';
	src: url('../assets/fonts/CircularStd_Book/CircularStd_Book.eot'); /* IE9 Compat Modes */
	src: url('../assets/fonts/CircularStd_Book/CircularStd_Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.woff') format('woff'), /* Modern Browsers */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.ttf')  format('truetype'), /* Safari, Android, iOS */
			url('../assets/fonts/CircularStd_Book/CircularStd_Book.svg#dd452d63ae05ddb466f19713a7d09fd5') format('svg'); /* Legacy iOS */
	font-weight:  400;
}
