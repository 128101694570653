.article-share {
	& {
		padding: 40px 0 0;
		text-align: center;
	}
	h1 {
		margin: 0;
		@include font(sans);
		font-size: 30px;
	}
	.as-links {
		padding: 40px 0 0;
	}
	a {
		display: inline-block;
		padding: 15px;
		margin: 0 -2px;
		border-top: 1px solid $c-dark;
		border-bottom: 1px solid $c-dark;
		border-left: 1px solid $c-dark;
		&:nth-of-type(5),
		&:last-child {
			padding: 15px 11.25px;
		}
		&:last-child {
			border-right: 1px solid $c-dark;
		}

		@include breakpoint(medium) {
			padding: 20px;
			transition: all 0.3s ease;
			&:nth-of-type(5),
			&:last-child {
				padding: 20px 15px;
			}
			&:hover {
				background-color: $c-dark;
				svg {
					fill: $c-light;
				}
			}
		}
	}
	svg {
		height: 20px;
		vertical-align: middle;
		fill: $c-dark;

		@include breakpoint(medium) {
			transition: all 0.3s ease;
		}
	}
}
