// Hero
.WCSH-btns{
	display: block;
	height: 100%;
	width: 100%;
}
.WCSH-slider-track{
	display: block;
	height: 100%;
	width: 100%;
}
.WCSH-header{
	padding: rem(20px) rem(12px) rem(20px) rem(20px);
}

// Info
.work-case-study-info{
	@extend %cf;
	@include col(1,1,both);
	margin: rem(40px) 0;
	@include breakpoint(small){
		position: relative;
		margin: rem(180px) 0;
	}
	@include max-width();
}

// Small Info
.WCSI-services{
	font-size: rem(16px);
	margin-bottom: rem(30px);
	text-align: center;
	@include breakpoint(small){
		margin-bottom: rem(80px);
	}
}

// Press

.work-case-study-press{
	@include col(1,1,false);
	padding: rem(40px) 0;
	margin: rem(40px) 0 rem(60px);
	background: $c-light-grey;
	@include breakpoint(small){
		padding: rem(80px) 0;
		margin: rem(100px) 0 rem(180px);
	}
}
.WCSP-header{
	font-size: rem(16px);
	margin-bottom: rem(30px);
	text-align: center;
	@include breakpoint(small){
		margin-bottom: rem(60px);
	}
}
.WCSP-logos{
	@include max-width();
}

// Service contain
.work-case-study-service-contain{
	display: block;
	padding-bottom: rem(40px);
	border-bottom: 1px solid $c-light-grey;
	margin-bottom: rem(40px);
}
%WCSW-modules{
	margin-top: rem(80px);
	margin-bottom: rem(80px);
	@include breakpoint(small){
		margin-top: rem(120px);
		margin-bottom: rem(120px);
	}
}

// Work caption
%project-caption{
	@include col(1,1,both);
	display: block;
	width: 100%;
	margin-bottom: rem(40px);
	@include breakpoint(small){
		padding: 0;
		float: left;
		margin-bottom: rem(80px);
	}
}
.project-caption-long{
	@extend %project-caption;
	@include breakpoint(small){
		@include col(7,9,false);
	}
}
.project-caption-small{
	@extend %project-caption;
	@include breakpoint(small){
		@include col(4,9,false);
	}
	& + &{ @include breakpoint(small){
		@include col(4,9,false,1);
	}}
}
.PC-header{
	@include font(sans, 3);
	display: block;
	margin: 0 0 rem(20px);
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: .2em;
	@include breakpoint(small){
		&:before{
			content: "";
			display: block;
			width: rem(60px);
			height: rem(2px);
			margin: 0 0 rem(20px);
			background: $c-med-grey;
		}
	}
}
.PC-text{
	margin-bottom: 0;
}

// Work intro
.work-case-study-work-intro{
	@extend %cf2;
	@extend %WCSW-modules;
	@include breakpoint(small){
		@include col(10,12,false,1);
	}
	@include max-width();
}
.WCSWI-title{
	margin: 0 0 rem(40px);
	@include breakpoint(small){
		margin: 0 0 rem(60px);
	}
}

// Work slider
.work-case-study-work-slider{
	@extend %cf2;
	@extend %WCSW-modules;
	margin-bottom: rem(40px);
	@include breakpoint(small){
		margin-top: 30px;
		margin-bottom: 120px;
	}
}
$WCSWS-Height: rem(460px);
.WCSWS-btns{
	height: #{$WCSWS-Height};
}
.WCSWS-content{
	@include breakpoint(small){
		@include col(8,12,both,2);
		position: relative;
		margin-top: 50px;
		margin-bottom: 50px;
	}
	@include max-width();
}
.WCSWS-pager{
	display: none;
	@include breakpoint(small){
		@include col(1,12,false);
		@include center(v);
		display: block;
		position: absolute;
		margin-top: 0;
	}
}
.WCSWS-text-content{
	@include col(1,1,both);
	@include breakpoint(small){
		@include col(10,12,false,2);
	}
}
.WCSWS-slider-photo{
	position: relative;
	z-index: 1;
	height:0;
	width: 100%;
	height: #{$WCSWS-Height};
	background: $c-light-grey;
	>img{
		@include center(h);
		position: absolute;
		top: 0;
		display: block;
		height: 100%;
		margin: 0 auto;
	}
	&::after{
		content: "";
		display: block;
		width: 100%;
		padding-bottom: 60%;
	}
}

//work container
.work-case-study-work{
	@extend %cf2;
	@include breakpoint(small){
		@include col(10,12,false,1);
	}
	@include max-width();
}

// Work module 1
.work-module-1{
	@extend %cf2;
	@extend %WCSW-modules;
	@include breakpoint(small){
		@include col(10,12,false,1);
		margin-top: 30px;
		margin-bottom: 30px;
	}
	@include max-width();
}
.WM1-image{
	@include col(1,1,both);
	position: relative;
	display: block;
	float: left;
	@include breakpoint(small){
		padding: 0;
	}
	>img{
		width: 100%;
	}
}

// Work module 2
.work-module-2{
	@extend %cf2;
	@extend %WCSW-modules;
	@include breakpoint(small){
		@include col(10,12,false,1);
		margin-top: 30px;
		margin-bottom: 120px;
	}
	@include max-width();
}
.WM2-content-ctn{
	@extend %cf2;
	position: relative;
	display: block;
	width: 100%;
	@include breakpoint(small){
		margin-top: rem(120px);
	}
}
.WM2-image{
	@include col(1,1,both);
	position: relative;
	display: block;
	float: left;
	@include breakpoint(small){
		@include col(1,2,false);
		padding: 0;
	}
	>img{
		width: 100%;
	}
}
.WM2-text{
	@include col(1,1,both);
	margin-top: rem(40px);
	@include breakpoint(small){
		@include col(1,2,both, 1);
		@include center(v);
		position: absolute;
		padding-left: rem(20px);
		margin-top: 0;
		margin-bottom: 0;
		font-size: rem(44px);
		line-height: rem(54px);
	}
}
.WM2-text > p{
	@extend .text-stylized-large;
}

// Work module 3
.work-module-3{
	@extend %cf2;
	@extend %WCSW-modules;
	@include col(1,1,both);
	@include breakpoint(small){
		@include col(10,12,false,1);
	}
	@include max-width();
}
.WM3-image{
	@include col(1,1,false);
	position: relative;
	display: block;
	float: left;
	>img{
		width: 100%;
	}
}

// Work module 4
.work-module-4{
	@extend %cf2;
	@extend %WCSW-modules;
	@include col(1,1,both);
	@include breakpoint(small){
		@include col(6,12,false,3);
	}
	@include max-width();
}

.site-btn-ctn{
	display: block;
	width: 100%;
	text-align: center;
}



// new site work pages
.work-modules{
	@media (min-width: 1024px){
		.one-up-carousel-module{
			&{}
			.prev-button-container{
				z-index: 10;
			}
			.next-button-container{
				margin-left: 0;
				z-index: 10;
			}
			.captions .left{
				width: 60%;
				right: 180px;
				z-index: 10;
			}
			.slide-pager-container {
				right: 0;
				z-index: 10;
				margin-left: 0;
			}
		}
	}
}

.one-up-carousel-module .captions h2::before{
	margin-top: 0!important;
}
