.home-footer {
	& {
		@extend %shoot-up-element;
		width: 100%;
		padding: 0 0 20px;

		@include breakpoint(small) {
			@include max-width();
			padding: 20px 0 40px;
		}
		@include breakpoint(medium) {
			width: grid(10);
			margin: 0 auto;
			padding: 0 0 40px;
		}
	}
	> a {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.HF-background {
		position: relative;
		width: 100%;
		height: 300px;
		padding: 40px 10px;
		background-size: cover;
		background-repeat: no-repeat;

		@include breakpoint(small) {
			height: 0;
			padding: 0 0 41.7%;
		}
		// @include breakpoint(medium) {
		// 	transition: transform 0.3s ease;
		// 	&:hover {
		// 		transform: scale(1.05);
		// 	}
		// }
	}
	.HF-copy {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		transform: translate3d(-50%,-50%,0);

		@include breakpoint(small) {
			width: 435px;
			// filter: blur(0);
		}
	}
	h5 {
		@include font(sans);
		font-size: 16px;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		color: $c-light;
		text-align: center;
		margin: 0;
	}
	h1 {
		margin: 15px 0;
		font-size: 48px;
		@include font(sans);
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: $c-light;
	}
	h1 span {
		display: block;
		position: relative;
		width: 300px;
		height: 32px;
		margin: 0 auto;

		@include breakpoint(small) {
			width: 380px;
			height: 40px;
		}
	}
	h1 svg {
		width: 100%;
		height: 100%;
		fill: $c-light;
		position: absolute;
		top: 0;
		left: 0;
	}
	p {
		font-size: 18px;
		letter-spacing: 0.04em;
		text-align: center;
		color: $c-light;
		margin: 0 0 20px;
	}
}
