section#newSpeaker {
	display: none;
	color: #000;
	background-color: #f2f3f3;;
	border-bottom: none;
	h2,
	h3,
	h4,
	p,
	label {
		color: #000;
	}
}