%birdwatch-entry-outer{
	margin: 30px 0;
	padding: 30px 30px 0;
	border-top: rem(1px) solid rgba(107, 107, 107, 0.4);
	
	    @include breakpoint(medium) {
			display: block;
			width: 78%;
			max-width: rem(1300px);
			padding: 80px 0 0;
			margin: 80px auto;

		}
	.intro{
		width: 100%;
	}
	.intro h2 {
		color: #101010;
		font-family: 'NeuzeitSLTW01-BookHeavy';
		font-weight: bold;
		font-size: 32px;
		margin: 0 0 45px;

		@include breakpoint(medium) {
			font-size: 44px;
		}
	}
	.intro h2::after{
		content: "";
		display: block;
		height: rem(3px);
		width: rem(54px);
		background-color: #dadada;
		margin: 25px 0 0;
	}
	.intro p{
		color: #848484;
		font-family: 'Freight Big W01 Light Regular', serif;
		font-size: 18px;
		line-height: 1.4em;
		letter-spacing: 0.04em;
		@include breakpoint(medium) {
			font-size: 22px;
			width: 60%;
		}
	}
	.intro a {
		position: relative;
	    z-index: 1;
	    display: inline-block;
	    font-weight: bold;
	    line-height: 1.25em;
	    color: $c-dark;
	    text-decoration: none;
	    border-bottom: 1px solid $c-dark;
	    &:before {
	    	content: "";
		    position: absolute;
		    z-index: -1;
		    top: 0;
		    left: 0;
		    display: block;
		    width: 0;
		    height: 100%;
		    background-color: #ddd;
		    transition: .3s ease-out;
	    }
	    &:hover:before {
	    	width: 100%;
    		transition: .2s ease-out;
	    }
	}
	.postro{}
	.postro h2{
		color: black;
		font-family: 'NeuzeitSLTW01-BookHeavy';
		font-size: 20px;
	}
	.postro p{
		color: #848484;
		font-family: 'Freight Big W01 Light Regular', serif;
		font-size: 18px;
		line-height: 1.4em;
		letter-spacing: 0.04em;

		@include breakpoint(medium) {
			font-size: 22px;
			width: 60%;
		}
	}
}
