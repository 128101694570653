.birdwatchAudio {
	@extend %birdwatch-entry-outer;
	
	.birdwatch-recording {
		background-color: #000;
		max-width: 100%;
		margin-top: 40px;
		// max-width: 1100px;
	}
	.highlighted-radio-audio-caption {
		background: #fff;
	    border: 5px solid $c-dark;
	    padding: 10px;
	    font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	    font-size: 16px;
	    font-weight: 200;
	}
	.highlighted-radio-audio-section {
		&.paused .highlighted-radio-audio-controls {
			background-image: url('../assets/audio/play-white.png');
		}
	}
	.highlighted-radio-audio-controls {
		background-image: url('../assets/audio/pause-white.png');
	    display: table-cell;
	    width: 3.125rem;
	    height: 2.5rem;
	    border-right: 0.0625rem solid #fff;
	    background-color: $c-dark;
	    background-repeat: no-repeat;
	    background-position: center center;
	    background-size: 25% 50%;
	    cursor: pointer;
	}
	.highlighted-radio-audio-timer {
		display: table-cell;
	    vertical-align: middle;
	    width: 4.375rem;
	    text-align: center;
	    background: $c-dark;
	    color: #fff;
	    border-left: 1px solid #fff;
	    font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	    font-size: 0.75rem;
	    font-weight: 200;
	}
	.highlighted-radio-audio-wave-ctn {
		display: table;
		width: 100%;
		height: 2rem !important;
		background: $c-dark;
	}
	.highlighted-radio-audio-wave {
	    position: relative;
	    display: table-cell;
	    background: $c-dark;
	    color: #fff;
	    font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	    font-size: 0.75rem;
	}
	.highlighted-radio-audio-wave wave,
	.highlighted-radio-audio-wave canvas {
	    height: 40px !important;
	}
	.highlighted-radio-audio-section-fallback {
		display: none;
	}
}
