.PF-contact-new-business {
  background: #f2f3f3;
}
.PF-contact-new-business.birdwatch,
.PF-contact-new-business.holiday {
  .PFC-header {
    color: $c-dark;
  }
}
#newBusinessPlaceholder {
  height: 0;
  opacity: 0;
  overflow: hidden;
  background: #f2f3f3;
  @include font(sans);
  &:after {
    content: "loading...";
    display: block;
    width: 100%;
    padding: 300px 0;
    font-size: 18px;
    line-height: 1em;
    letter-spacing: 0.15em;
    text-align: center;
  }
  .expanded &{
    height: 620px;
    opacity: 1;
    transition: 0.3s ease-out;
  }
  .new-business-form-loaded &{
    pointer-events: none;
    height: 0;
    transition: none;
  }
}

#error {
  display: none;
  padding: 30px 15px;
  @include font(sans);
  font-size: 20px;
  text-align: center;
}

#error {
  background: #282828;
  color: red;
}
#newBusinessCtn {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.45s ease-in-out;
  .expanded &{
    height: auto;
    transition: 0.45s ease-out;
  }
  .new-business-form-loading & {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
#newBusiness {
  height: auto;
  opacity: 1;
  &.new-business-form-submitted{
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }
}
.new-business-form-standalone{
  background: #EFEFEF;
}
.new-business-form-ctn {
  position: relative;
  background: #EFEFEF;

  &.new-business-form-submitting {
    &:before {
      content: "submitting...";
      position: absolute;
      display: block;
      width: 100%;
      padding: 125px 0;
      @include font(sans);
      font-size: 18px;
      line-height: 1em;
      letter-spacing: 0.15em;
      text-align: center;
      @include breakpoint(medium) {
        padding: 230px 0;
      }
    }
  }
}
.new-business-form {
  @include max-width();
  margin: 0 auto;
  padding: 70px 15px;
  color: #282828;
  transition: 0.3s ease-out;
  border-bottom: 1px solid #989898;
  @include breakpoint(small) {
    padding: 140px 40px;
  }
  @include breakpoint(medium) {
    padding: 200px 80px;
  }
  .new-business-form-submitting & {
    opacity: 0;
    height: 265px;
    transition: 0.15s ease-out;
    @include breakpoint(medium) {
      height: 454px;
    }
  }
  input[type="text"],
  input[type="email"] {
    border-radius: 0;
    padding: 10px;
    margin: 0 0 50px;
    background: transparent;
    border: 0;
    @include font(freight);
    font-size: 22px;
    color: #949494;
    letter-spacing: 0.02em;
    line-height: 1.75em;
    outline: 0;
    @include breakpoint(small) {
      padding: 0;
    }

    &:-moz-placeholder { /* Firefox 18- */
      padding-left: 2px;
      opacity: 1;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      padding-left: 2px;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      padding-left: 2px;
      opacity: 1;
    }
    &:focus::-webkit-input-placeholder {
      opacity: 0.6;
    }

    &:focus:-moz-placeholder { /* Firefox 18- */
      opacity: 0.6;
    }

    &:focus::-moz-placeholder {  /* Firefox 19+ */
      opacity: 0.6;
    }

    &:focus:-ms-input-placeholder {
      opacity: 0.6;
    }
  }
  .error{
    position: absolute;
    transform: translateY(-100%);
    display: block;
    opacity: 0;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 1em;
    color: #ff0000;
    font-family: 'NeuzeitSLTW01-BookHeavy';
    @include breakpoint(small) {
      font-family: 'circular book';
    }
  }
  input[type="text"].invalid ~ .error,
  input[type="email"].invalid ~ .error{
    border-radius: 0;
    opacity: 1;
  }
  textarea {
    padding: 10px;
    margin: 0 0 20px;
    background: #fff;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    border-radius: none;
    font-size: 16px;
    color: #282828;
    letter-spacing: 0.02em;
    line-height: 1em;
    outline: 0;
    font-family: 'NeuzeitSLTW01-BookHeavy';
    @include breakpoint(small) {
      font-family: 'circular book';
      padding: 20px;
    }
    &:focus::-webkit-input-placeholder {
       opacity: 0.6;
    }

    &:focus:-moz-placeholder { /* Firefox 18- */
       opacity: 0.6;
    }

    &:focus::-moz-placeholder {  /* Firefox 19+ */
       opacity: 0.6;
    }

    &:focus:-ms-input-placeholder {
       opacity: 0.6;
    }
  }
  input[name="zipcode"] {
    border-radius: 0;
    display: none;
  }
  & ::-webkit-input-placeholder {
     color: #949494;
  }

  & :-moz-placeholder { /* Firefox 18- */
     color: #949494;
  }

  & ::-moz-placeholder {  /* Firefox 19+ */
     color: #949494;
  }

  & :-ms-input-placeholder {
     color: #949494;
  }
}
.nbf{
  &-title {
    margin: 0 0 20px;
    padding: 0 15px;
    text-transform: uppercase;
    text-align: center;
    @include font(sans);
    font-size: 14px;
    letter-spacing: 0.5em;
    @include breakpoint(small) {
      padding: 0 40px;
    }
    @include breakpoint(medium) {
      padding: 0 80px;
    }
  }
  &-headline {
    margin: 0;
    padding: 0 15px;
    text-align: center;
    @include font(sans);
    font-size: 38px;
    @include breakpoint(small) {
      padding: 0 40px;
    }
    @include breakpoint(medium) {
      padding: 0 80px 20px;
      font-size: 60px;
    }
  }
  &-subtitle {
    margin: 0 0 10px;
    padding: 0 15px;
    span {
      @include font(freight);
      font-size: 18px;
      color: #949494;
      @media screen and (max-width: 640px) {
        display: block;
      }
    }
    @include font(sans);
    font-size: 32px;
    @include breakpoint(small) {
      padding: 0 40px;
    }
    @include breakpoint(medium) {
      padding: 0 80px;
    }
  }
  &-copy {
    margin: 0 auto 10px;
    padding: 0 15px;
    font-size: 22px;
    max-width: 700px;
    color: #949494;
    white-space: pre-wrap;
    letter-spacing: 0.01em;
    text-align: center;
    #newBusinessForm & {
      text-align: left;
    }
    @include breakpoint(small) {
      padding: 0 40px;
      letter-spacing: 0.04em;
    }
    @include breakpoint(medium) {
      padding: 0 80px 40px;
    }
  }
  &-client-info {
    margin: 20px 0 0;
    padding: 0 15px 0;
    @include breakpoint(small) {
      margin: 30px 0 0;
      padding: 0 40px 0;
    }
    @include breakpoint(medium) {
      margin: 30px 0 0;
      padding: 0 80px 0;
    }

    .nbf-client-input-field {
      border-radius: 0;
      width: 100%;
      @include breakpoint(medium) {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        &:nth-child(2n-1) {
          padding-right: 10px;
        }
        &:nth-child(2n+0) {
          padding-left: 10px;
        }
        &:nth-child(3) {
          margin-bottom: 15px;
        }
      }
      input {
        border-radius: 0;
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 10px 10px 10px 0;
        margin-bottom: 30px;
        color: #282828;
        background-color: transparent;
        border-bottom: #a9aaab 1px solid;
        border-radius: none;
        font-family: 'NeuzeitSLTW01-BookHeavy';
        @include breakpoint(small) {
          font-family: 'circular book';
          width: 98%;
        }
      }

      label {
        @include font(sans);
        display: block;
        font-size: 20px;
        line-height: 1em;
        color: #282828;
        margin: 0 0 15px;
      }
    }
  }
  &-client-inputs {
    @extend %cf;
    font-size: 0;
  }
  &-options-group-ctn {
    @extend %cf;
    margin: 10px 0 0;
    margin-bottom: 20px;
    padding: 0 15px;
    @include breakpoint(small) {
      font-size: 0;
      padding: 10px 40px 0px 40px;
    }
    @include breakpoint(medium) {
      margin: 0;
      padding: 0px 80px 0px 80px;
    }
  }
  &-option-group {
    @extend %cf;
    float: none;
    width: 100%;
    padding: 0;
    @include breakpoint(small) {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin: 40px 0 0;
      padding: 0 0 0 0;
      &:nth-child(2n-1) {
        padding-right: 10px;
      }
      &:nth-child(2n+0) {
        padding-left: 10px;
      }
    }
    &:last-of-type {
      @include breakpoint(small) {
        margin-right: 0;
      }
    }
  }
  &-option-title {
    margin: 0 0 25px;
    @include font(sans);
    font-size: 18px;
    @media screen and (max-width: 640px) {
      margin-top: 10px;
    }
    span {
      cursor: pointer;
    }
  }
  &-options-wrapper {
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 1;
    height: auto;
  }
  .collapsed > &-options-wrapper {
    opacity: 0;
    height: 0;
    transition: height 0.3s ease, opacity 0.45s ease-out;
  }
  &-options-ctn {
    @extend %cf;
  }
  &-option {
    margin: 0 0 20px;
    font-size: 15px;
    letter-spacing: 0.04em;
    color: #757677;
    input[type="checkbox"] {
      display: none;
    }
    label {
      display: block;
      span {
        display: inline-block;
        width: 75%;
        line-height: 1em;
        vertical-align: middle;
      }
    }
    font-family: 'NeuzeitSLTW01-BookHeavy';
    @include breakpoint(small) {
      font-family: 'circular book';
      font-size: 16px;
    }
    .nbf-checkbox {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: #dfe0e1;
      border: 1px solid #d3d4d5;
      border-radius: 2px;
      cursor: pointer;
      .check{
        margin-top: -2px;
        opacity: 0;
        color:#282828;
        transform: scale(0.75);
        transition: transform 0.1s ease, opacity 0.15s ease-out
      }
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        transform: scale(0.75);
        opacity: 0;
          background: #fff;
        transition: transform 0.1s ease, opacity 0.15s ease-out
      }
    }
    input[type="checkbox"]:checked + label > .nbf-checkbox:before,
    input[type="checkbox"]:checked + label > .nbf-checkbox:after {
      transform: scale(1);
      opacity: 1;
      transition: all 0.1s ease;
    }
    input[type="checkbox"]:checked + label .check{
      transform: scale(1);
      opacity: 1;
      transition: all 0.1s ease;
    }
    input[type="checkbox"]:checked + label span{
      color: #232323;
    }
  }
  &-selected {
    display: none;
    margin: 0 0 10px;
    padding: 0 15px;
    @include breakpoint(small) {
      padding: 0 40px;
      margin: 0 0 30px;
    }
    @include breakpoint(medium) {
      padding: 0 80px;
    }
    span {
      @include font(sans);
      font-size: 16px;
      font-style: normal;
      display: inline-block;
      position: relative;
      margin: 0 10px 10px 0;
      padding: 16px 32px;
      background: #ddd;
      border: 0;
      border-radius: 0;
      color: #7e7e7e;
      cursor: pointer;
      transition: 0.2s ease-out;
      &:before {
        content: "x";
        position: absolute;
        left: 20px;
        vertical-align: top;
        line-height: 1.5em;
        font-family: arial;
        font-size: 14px;
        color: #999;
        opacity: 0;
        transition: 0.25s ease-out;
      }
      &:hover {
        background: #bdbdbd;
        padding: 16px 27px 16px 37px;
        &:before {
          opacity: 1;
        }
      }
    }
  }
  &-textarea {
    font-size: 14px;
    border-radius: 0;
    -webkit-appearance: none;
    letter-spacing: 0.01em;
    color: #282828;
    padding: 0 15px;
    margin: 40px 0 0;
    font-family: 'NeuzeitSLTW01-BookHeavy';
    @include breakpoint(small) {
      font-family: 'circular book';
      padding: 0 40px;
    }
    @include breakpoint(medium) {
      padding: 0 80px;
    }
    label {
      @include font(sans);
      display: block;
      font-size: 20px;
      line-height: 1em;
      color: #282828;
      margin: 0 0 15px;
    }
    textarea {
      width: 100%;
      resize: none;
      font-size: 14px;
      border: 1px solid #d3d4d5;
      border-radius: 0;
      -webkit-appearance: none;
      font-family: 'NeuzeitSLTW01-BookHeavy';
      @include breakpoint(small) {
        font-family: 'circular book';
      }
    }
  }
  &-timeline {
    margin: 0;
    padding: 0 15px;
    @include breakpoint(small) {
      padding: 0 40px;
    }
    @include breakpoint(medium) {
      padding: 0 80px;
    }
    input {
      border-radius: 0;
      width: 100%;
      @include breakpoint(medium) {
        width: 48%;
      }
    }
  }
  &-submit {
    @include font(sans);
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    input {
      border-radius: 0;
      padding: 25px 80px;
      background: #2e2f30;
      border: 2px solid #2e2f30;
      color: #fff;
      letter-spacing: .04em;
      outline: 0;
      transition: background 0.3s ease-out, color 0.2s ease-out;
      &:hover {
        background: #fff;
        color: #282828;
        border: 2px solid #282828;
      }
    }
  }
}
.birdwatch h3.nbf-headline{
  @include breakpoint(max){
    font-size: 40px;
  };
}
.ty-ctn{
  &{
    padding: 0 20px;
    text-align: center;
  }
  .thank-you-header{
    margin: 70px 0 20px;
    color: #272829;
  }
  .thank-you-copy{
    margin: 20px auto;
    padding: 0;
    color: #555a5a;
  }
	.view-our-work-button{
		&{
			@include font(sans,3);
			@include font-smoothing(true);
			display: inline-block;
			padding: 20px 60px;
			border: 1px solid $c-dark;
      margin-top: 60px;
			font-size: 14;
			letter-spacing: .01em;
			background: $c-dark;
			color: $c-light;
			transition: .3s ease-out;
			cursor: pointer;
      text-decoration: none;
		}
		&:hover{
      background: #fff;
      color: #282828;
      border: 2px solid #282828;
		}
	}
}

.wpcf7-form-control-wrap{
	position: static;
}
div.wpcf7-mail-sent-ok,
div.wpcf7-validation-errors{
	display: none !important;
}
.nbf-security{
	position: relative;
	padding-bottom: 40px;
}
.wpcf7-recaptcha div{
	margin: 0 auto;
}
.nbf-security .wpcf7-not-valid-tip{
	position: absolute;
	display: block;
	width: 100%;
	padding-top: 10px;
	font-size: 16px;
	letter-spacing: 0.02em;
	line-height: 1em;
	color: #ff0000;
	text-align: center;
	font-family: 'NeuzeitSLTW01-BookHeavy';
	@include breakpoint(small) {
		font-family: 'circular book';
	}
}
div.wpcf7-spam-blocked{
	display: block;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.02em;
	line-height: 1em;
	color: #2e2f30;
	text-align: center;
	font-family: 'NeuzeitSLTW01-BookHeavy';
	border: none;
}
