.work-filter-contain{
	&{
		position: relative;
		z-index: 2;
		margin: 0 auto 70px;
	}
	.content{
		@include max-width;
		position: relative;
		@include breakpoint(medium){
			text-align: center;
		}
	}
	.filter-ctn{
		position: relative;
		z-index: 1;
		margin: 10px;
		@include breakpoint(medium){
			display: inline-block;
			width: 325px;
			text-align: left;
		}
	}
	.filter-ctn:first-of-type{
		z-index: 2;
	}
	.filter{
		@include font(sans,3);
		@include font-smoothing(true);
		position: relative;
		z-index: 2;
		padding: 0;
		border: 1px solid $c-dark;
		letter-spacing: .1em;
		text-transform: uppercase;
		background: $c-light;
		color: $c-dark;
		fill: $c-dark;
		@include breakpoint(medium){
			font-size: 12px;
			padding: 0;
		}
	}
	.filter .filter-text{
		display: block;
		padding: 30px 20px 26px;
		@include breakpoint(medium){
			padding: 20px 36px 18px;
		}
	}
	.filter-ctn.user-changed:not(.display-filter) .filter{
		background: $c-dark;
		color: $c-light;
		fill: $c-light;
	}
	select{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		outline: none;
		opacity: 0;
		cursor: pointer;
		-webkit-appearance: none;
	}
	.icon{
		position: absolute;
		width: 11px;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		pointer-events: none;
		fill: $c-dark;
	}
	.filter-ctn.user-changed:not(.display-filter) .icon{
		fill: $c-light;
	}
	.display-filter .icon{
		transform: translateY(-50%) rotate(180deg);
	}
	.toggle-menu{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 3;
		opacity: 0;
	}
	.options{
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		transform: translateY(-2px);
		z-index: 2;
		width: 100%;
		border-bottom: 1px solid $c-dark;
	}
	.display-filter .options{
		display: block;
	}
	.option{
		&{
			position: relative;
			padding: 20px 36px 18px;
			border: 1px solid $c-dark;
			border-width: 0 1px;
			background: $c-light;
			color: $c-dark;
		}
		&:hover,
		&.active{
			background: $c-dark;
			color: $c-light;
		}
		button{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			height: 100%;
			width: 100%;
			opacity: 0;
			cursor: pointer;
		}
		span{
			@include font(sans,3);
			@include font-smoothing(true);
			position: relative;
			font-size: 12px;
			letter-spacing: .1em;
			text-transform: uppercase;
		}
	}
	.clear-filter-option{
		&{
			display: none;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 0 0 10px;
			opacity: 0;
			transition: .3s ease-out;
			pointer-events: none;
			@include breakpoint(medium){
				display: inline-block;
			}
		}
		button{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
		}
		span{
			@include font(sans,3);
			@include font-smoothing(true);
			border-bottom: 1px solid $c-dark;
			font-size: 12px;
			letter-spacing: .1em;
			text-transform: uppercase;
			color: $c-dark;
		}
	}
	.user-changed ~ .clear-filter-option{
		opacity: 1;
		pointer-events: initial;
	}
}
.work-load-more-container{
	&{
		display: block;
		text-align: center;
		margin-bottom: 80px;
	}
	button{
		&{
			@include font(sans,3);
			@include font-smoothing(true);
			display: inline-block;
			padding: 20px 60px;
			border: 1px solid $c-dark;
			font-size: 14;
			letter-spacing: .01em;
			background: $c-dark;
			color: $c-light;
			transition: .3s ease-out;
			cursor: pointer;
		}
		.no-load-more &{
			opacity: 0;
			transform: translateY(50px);
		}
		&:hover{
			background: $c-light;
			color: $c-dark;
		}
	}
}
