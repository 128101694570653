.birdwatchCarousel{
  @extend %birdwatch-entry-outer;
	@extend %bw-carousel;
  &{
    position: relative;
  }
  .next-slide{
    top: 20%;
    transform: translate(0, -75%);
    left: auto;
    right: 0;

    @include breakpoint(medium) {
      top: 50%;
    }
  }
  .prev-slide{
    top: 20%;
    transform: translate(0, -75%);
    left: 0;

    @include breakpoint(medium) {
      top: 50%;
    }
  }
  .intro{
    @include breakpoint(medium) {
      margin-bottom: 80px;
    }
  }
  .carousel-contain{
    position: relative;
    padding: 0 40px;
    @include breakpoint(medium) {
      padding: 0 80px;
    }
  }

  img {
    max-height: none;

    @include breakpoint(medium) {
      padding-right: 135px;
    }
  }

  .bw-lightbox-pager {
    right: -50px;
    buttom: auto;
    top: 150px;

    @include breakpoint(medium) {
      top: auto;
      bottom: auto;
      right: 100px;
      transform: translate(100%, -100%);
    }
  }

  .bw-lightbox-pager .current-slide,
  .bw-lightbox-pager .total-slide {
    padding: 10px;

    @include breakpoint(medium) {
      padding: 10px 30px 10px 10px;
    }
  }

  .postro {
    padding-top: 50px;
  }
}
