// Copy style
.text-copy{
	@include font(freight, 0, none);
	margin: rem(20px) 0;
	font-size: rem(14px);
	letter-spacing: .04em;
	line-height: rem(28px);
	text-align: left;
	color: $c-global;
	@include breakpoint(small){
		font-size: rem(20px);
		line-height: rem(24px);
	}
}
.text-large-copy{
	@include font(sans, 0);
	margin: rem(20px) 0;
	font-size: rem(16px);
	letter-spacing: .02em;
	line-height: 2em;
	text-align: left;
	color: $c-dark;
	@include breakpoint(small){
		font-size: rem(20px);
	}
}
.text-stylized{
	@include font(serif, 0);
	margin: rem(20px) 0;
	font-size: rem(20px);
	letter-spacing: .02em;
	line-height: rem(30px);
	text-align: left;
	color: $c-dark;
	@include breakpoint(small){
		font-size: rem(34px);
		line-height: rem(45px);
	}
}
.text-stylized-large{
	@extend .text-stylized;
	@include breakpoint(small){
		font-size: rem(50px);
		line-height: rem(55px);
	}
}

// Header styles
.header-page{
	@include font(sans, 3);
	@include font-smoothing(true);
	display: inline-block;
	padding: 0;
	text-align: center;
	text-transform: none;
	background: transparent;
	color: #fff;
	font-size: 40px;
	@include breakpoint(small){
		font-size: rem(70px);
		letter-spacing: 0;
		padding: 0;
	}
	@include breakpoint(max) {
		padding: 0;
	}
}
.header-headline{
	@include font(sans, 3);
	@include font-smoothing(yes);
	display: block;
	margin: 0;
	font-size: rem(20px);
	letter-spacing: 0;
	line-height: rem(26px);
	@include breakpoint(small){
		font-size: rem(30px);
	}
}
%block-w-underline{
	@include font(sans, 3);
	@include font-smoothing(yes);
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 0 auto;
	font-size: rem(12px);
	line-height: rem(12px);
	text-align: center;
	
	&::after{
		$white-space: 0;
		
		content: "";
		position: absolute;
		bottom: 0;
		display: block;
		height: rem(1px);
		width: 100%;
		width: calc(100% - #{$white-space});
	}

	@include breakpoint(small){
		font-size: rem(14px);
	}
}
.header-underlined-black{
	@extend %block-w-underline;
	padding-bottom: rem(28px);
	color: $c-dark;
	&::after{
		background: $c-dark;
	}
}
.header-underlined-white{
	@extend %block-w-underline;
	padding-bottom: rem(14px);
	color: $c-light;
	&::after{
		background: $c-light;
	}
}
.header-italic-script{
	@include font(serif);
	@include font-smoothing(yes);
	font-size: rem(36px);
	letter-spacing: .02em;
	line-height: rem(42px);
	margin: rem(42px) 0;
	
	@include breakpoint(small){
		font-size: rem(44px);
		line-height: rem(50px);
	}
}
h1 {
	color: $c-dark;
}
