
//------------------------------------- |
//  PAGE HEADER
//------------------------------------- |
.WCSEM--header {
  &_content {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: top center;
  }
  &_text {
    position: absolute;
    bottom: 0;
    width: 90%;
    padding: 20px 10px;
    &-title {
      margin: 0 0 20px;
      color: #fff;
      @include font(sans, 3);
      font-size: rem(48px);
      font-style: normal;
      letter-spacing: 0em;
      line-height: 1em;
    }
    &-copy {
      margin: 0;
      color: #fff;
      @include font(freight-text, 3);
      font-size: rem(18px);
      font-style: normal;
      letter-spacing: 0.040em;
      line-height: 1.35em;
    }
    &-link {
      display: inline-block;
      margin: 10px 0 0;
      padding: 2px 2px 2px 0;
      color: #fff;
      text-decoration: none;
      @include font(sans, 3);
      font-size: rem(18px);
      letter-spacing: 0.040em;
      line-height: 1em;
      border-bottom: 1px solid #fff;
    }
  }
}
//------------------------------------- |
//  INFO SECTION
//------------------------------------- |
.WCSEM--info {
  &_services {
    padding: 30px 20px;
    background: #ebebeb;
    &-title {
      margin: 0;
      color: #111;
      text-transform: uppercase;
      @include font(sans, 3);
      font-size: rem(10px);
      font-style: normal;
      letter-spacing: 0.200em;
      line-height: 1em;
      &:after {
        content: "";
        display: block;
        width: 50px;
        height: 3px;
        margin: 5px 0 0;
        background: #111;
        opacity: 0.15;
      }
    }
    &-list {
      margin: 0;
      padding: 15px 0 0 18px;
      li {
        color: #7b7b7b;
        margin: 0 0 10px;
        @include font(serif, 3);
        font-size: rem(15px);
        font-style: normal;
        letter-spacing: 0.050em;
        line-height: 1em;
      }
    }
  }
  &_awards {
    padding: 30px 20px 20px;
    &-title {
      margin: 0 0 5px;
      color: #111;
      text-transform: uppercase;
      @include font(sans, 3);
      font-size: rem(10px);
      font-style: normal;
      letter-spacing: 0.200em;
      line-height: 1em;
      &:after {
        content: "";
        display: block;
        width: 50px;
        height: 3px;
        margin: 5px 0 0;
        background: #111;
        opacity: 0.15;
      }
    }
    &-list {
      display: table;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
				padding: 10px 0 5px;
			}
      &--copy{
        display: table-cell;
        vertical-align: middle;
        &_site {
          display: block;
          color: #111;
          text-transform: uppercase;
          @include font(sans, 3);
          font-size: rem(10px);
          font-style: normal;
          letter-spacing: 0.200em;
          line-height: 1.5em;

        }
        &_type {
          color: #7c7c7c;
          text-decoration: none;
          @include font(serif, 3);
          font-size: rem(16px);
          font-style: normal;
          letter-spacing: 0.050em;
          line-height: 1em;
        }
      }
    }
  }
}

//------------------------------------- |
//  MOBILE MODULES
//------------------------------------- |
%content {
  margin: 0;
  padding: 40px 0 30px;
}
%subheading {
  margin: 0 0 20px;
  padding: 0 20px;
  pre {
    margin: 0;
    text-transform: uppercase;
    @include font(sans, 3);
    font-size: rem(10px);
    font-style: normal;
    letter-spacing: 0.150em;
    line-height: 1em;
  }
}
%heading {
  margin: 0 0 40px;
  padding: 0 20px;
  color: inherit;
  pre {
    margin: 0;
    color: inherit;
    @include font(sans, 3);
    font-size: rem(30px);
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1.2em;
  }
}
%mod-heading {
  margin: 0;
  padding: 0 20px;
  color: inherit;
  pre {
    margin: 0;
    @include font(sans, 3);
    font-size: rem(30px);
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1.2em;
  }
  &:after {
    content: "";
    display: block;
    width: 55px;
    height: 3px;
    margin: 20px 0 25px;
    background-color: rgb(0, 0, 0);
    opacity: 0.35;
  }
}
%copy-serif {
  margin: 0;
  padding: 0 20px;
  opacity: 0.8;
  p {
    margin: 0 0 40px;
    color: inherit;
    @include font(freight-text, 3);
    font-size: rem(19px);
    font-style: normal;
    letter-spacing: 0.04em;
    line-height: 1.75em;
  }
}
%quote {
  position: relative;
  margin: 0 0 40px;
  padding: 0 10px 0 30px;
  p {
    color: inherit;
    @include font(serif, 3);
    font-size: rem(24px);
    font-style: normal;
    letter-spacing: 0.04em;
    line-height: 1.375em;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    display: block;
    width: 1px;
    height: 100%;
  }
}
%img {
  margin: 10px 0 40px;
  padding: 0 10px;
  img {
    width: 100%;
    height: auto;
  }
}

// video 3:4 ratio
%video {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 75%;
  margin: 0 0 30px;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
//------------------------------------- |
//  INTRO SECTION
//------------------------------------- |
.WCSEM--intro {
  &_content {
    @extend %content;
    color: #fff;
  }
  &_subheading {
    @extend %subheading;
  }
  &_heading {
    @extend %heading;
  }
  &_copy {
    @extend %copy-serif;
    color: #fff;
  }
  &_quote {
    @extend %quote;
    color: #fff;
    &:before {
      background: #fff;
    }
  }
  &_image {
    @extend %img;
  }
}
//------------------------------------- |
//  MODULES SECTION
//------------------------------------- |
.WCSEM--mod {
  &_content {
    @extend %content;
    color: #111;
  }
  &_subheading {
    @extend %subheading;
  }
  &_heading {
    @extend %mod-heading;
  }
  &_copy {
    @extend %copy-serif;
  }
  &_quote {
    @extend %quote;
    &:before {
      background: #111;
    }
  }
  &_image {
    @extend %img;
  }
  &_slideshow-ctn {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;
    padding-bottom: 90px;
  }
  &_slideshow-accent-color {
    position: absolute;
    bottom: 30px;
    left: 0;
    display: block;
    width: 100%;
    height: 75%;
  }
  &_slideshow {
    position: relative;
    @extend %cf;
    .slick-list {
      width: 95%;
      &:focus {
        outline: none;
      }
    }
    .slick-slide {
      float: left;
      padding: 10px 0 10px 20px;
      img {
        width: 100%;
        height: auto;
        backface-visibility: hidden;
        filter: brightness(70%);
        transition: all .3s ease-out;
        outline: none;
      }
    }
    .slick-active img {
      filter: brightness(100%);
    }
  }
  &_slideshow-caption {
    @extend %cf;
    .slick-list {
      width: 100%;
    }
    .slick-slide {
      float: left;
      padding: 0 20px;
    }
    pre {
      margin: 0 {

      }
    }
    h4{
      margin: 10px 0 15px;
      color: #fff;
      @include font(sans, 3);
      font-size: rem(16px);
      font-style: normal;
      letter-spacing: 0.02em;
      line-height: 1em;
    }
    p {
      margin: 0 0 10px;
      color: #fff;
      @include font(serif, 3);
      font-size: rem(16px);
      font-style: normal;
      letter-spacing: 0.04em;
      line-height: 1.35em;
      opacity: 0.8;
    }
  }
  &_slideshow-controls {
    position: relative;
    .slick-prev,
    .slick-next {
      position: absolute;
      bottom: -90px;
      width: 60px;
      height: 60px;
      border: 0;
      outline: none;
      border-radius: 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        opacity: 0.5;
        display: block;
        width: 100%;
        height: 100%;
      }
      svg {
        position: relative;
        width: 50%;
      }
    }
    .slick-next {
      right: 0;
    }
    .slick-prev {
      svg {
        left: 0;
        transform: rotate(180deg);
      }
    }
  }
  &_video {
    @extend %video;
  }
}
//------------------------------------- |
//  NEW BUSINESS SECTION
//------------------------------------- |
.WCSEM--new-business {
  &_content {
    padding: 0 10px 50px;
    text-align: center;
    color: #111;
  }
  &_title {
    margin: 0 0 25px;
    text-transform: uppercase;
    @include font(sans, 3);
    font-size: rem(14px);
    font-style: normal;
    letter-spacing: 0.4em;
    line-height: 1em;
    span {
      display: inline-block;
      &:after {
        content: "";
        position: relative;
        top: 4px;
        left: -1px;
        display: block;
        width: 100%;
        height: 1px;
        background: #111;
      }
    }
  }
  &_contact {
    margin: 0 0 10px;
    @include font(serif, 3);
    font-size: rem(22px);
    font-style: normal;
    letter-spacing: 0.04em;
    line-height: 1em;
  }
  &_email {
    margin: 0;
    @include font(sans, 3);
    font-style: rem(18px);
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1em;
    a {
      &:link,
      &:visited {
        padding: 5px;
        color: #111;
        text-decoration: none;
      }
    }
  }
}

//------------------------------------- |
//  RELATED ARTICLES
//------------------------------------- |
.WCSEM--more {
  &_content {
    color: #fff;
  }
  &_related {
    display: block;
    position: relative;
    margin: 0 0 30px;
    background: #121c25;
		&:nth-of-type(2) {
			margin: 0;
		}
    &-img {
      width: 100%;
      height: 0;
      padding: 0 0 50%;
      opacity: 0.4;
      background-size: cover;
      background-position: top center;
    }
    &-copy {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0,-50%);
      padding: 10px 10px 10px 35px;
      &__title {
        margin: 15px 0;
        color: #fff;
        @include font(sans, 3);
        font-size: rem(24px);
        font-style: normal;
        letter-spacing: 0em;
        line-height: 1em;

      }
      &__tags {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin: 0 0 10px;
          @include font(serif, 3);
          font-size: rem(16px);
          font-style: normal;
          letter-spacing: 0.04em;
          line-height: 1em;
        }
				p {
					margin: 0;
					line-height: 1.5em;
				}
      }
    }
  }
  &_all {
    display: block;
    text-decoration: none;
    margin: 10px 0;
    padding: 50px 10px 50px 35px;
    background: #fff;
    color: #121c25;
    @include font(sans, 3);
    font-size: rem(24px);
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1em;
		text-align: center;
    svg {
      width: 25px;
      height: 20px;
      margin: 0 0 0 20px;
    }
  }
}
