// Work hero
.WH-btns{
	display: block;
	height: 100%;
	width: 100%;
}
.WH-slider-track{
	display: block;
	height: 100%;
	width: 100%;
}
.WH-slider-track > .SS-slide{
	position: relative;
}
.WH-photo{
	position: absolute;
	z-index: 1;
	display: block;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
}
.WH-slide-content{
	@include col(10,12,false,1);
	@include center(v);
	position: absolute;
	z-index: 2;
	text-align: center;
	background-color: $c-light;
	padding: rem(45px) 0;
	@include breakpoint(small){
		@include center(false);
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-left: 0;
		z-index: 2;
		display: block;
		margin-bottom: rem(80px);
		padding: rem(60px) 0;
		text-align: left;
	}
}
.WH-slide-header{
	margin: 0;
	color: $c-dark;
	text-transform: none;
	letter-spacing: 0;
	line-height: 1em;
	font-size: rem(40px);

	@include breakpoint(medium) {
		font-size: rem(46px);
	}
	@include breakpoint(max) {
		font-size: rem(60px);
	}
}
.WH-large-content h3 {
	@include font(sans);
	color: $c-dark;
	margin: 0 0 15px;
}
.WH-slide-text{
	font-size: rem(18px);
	line-height: rem(22px);
	color: $c-global;
	margin-bottom: rem(16px);
	text-align: center;
	letter-spacing: 0.04em;
	@include breakpoint(small){
		margin-bottom: rem(30px);
		text-align: left;
	}
}

.WH-small-content {
	padding: 0 10px;

	@include breakpoint(small) {
		padding: 0 rem(40px);
	}
	@media (min-width: 767px) and (orientation: portrait) {
		padding: 0 10px;
	}

}

// Work list
.work-list{
	margin: rem(50px) 0;
	border-bottom: 1px solid $c-med-grey;
	@include breakpoint(small){
		margin: rem(64px) 0;
	}
}

.WL-header {
	margin: 0 0 rem(24px);
	@include font(sans, 3);
	font-size: rem(32px);
	text-align: center;
}

.WL-hover {
	position: absolute;
	bottom: 0;
	left: 20px;
	opacity: 0;
	color: $c-light;
	transition: all 0.3s ease;
	svg#lock {
		display: block;
		width: 48px;
		margin-bottom: 25px;
		fill: $c-light;
		vertical-align: middle;
	}
	p {
		margin: 0;
		font-size: 20px;
		letter-spacing: 0.04em;
	}
	.WL-link {
		display: inline-block;
		margin-top: 20px;
		position: relative;
		padding-bottom: 5px;
		@include font(sans);
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.04em;
		svg {
			display: inline-block;
			width: 25px;
			height: 15px;
			fill: $c-light;
			vertical-align: middle;
			margin-left: 50px;
		}
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background: white;
			opacity: 0;
			bottom: -10px;
			left: 0;
			transition: all 0.3s ease 0.1s;
		}
	}
}

.work-MB1{

	@include breakpoint(small){
		margin: 0 auto rem(40px);
	}
}

.work-MB1 .MB1 {
	@include col(1,1,none);
	padding: 0;
	@extend %shoot-up-element;
	$delay: 0s;
	@for $i from 0 to 7 {
		&:nth-child(#{$i}) {
			transition-delay: $delay;
		}
		$delay: $delay + .15s;
	}
	@include breakpoint(small) {
		@include col(1,2,both);
	}
	@include breakpoint(medium) {
		@include col(4,12,none);
		padding: 0 rem(10px);
	}
}

.work-MB1 .MB1-photo {
	position: relative;
	z-index: 1;
	display: block;
	height: rem(250px);
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: #282828;
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $c-dark;
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&:hover:before {
		opacity: 0.6;
	}
	&:hover .WL-hover {
		opacity: 1;
		bottom: 20px;
	}
	&:hover .WL-link:after {
		opacity: 1;
		bottom: 0;
	}

	@include breakpoint(small) {
		height: rem(300px);
	}
}

.work-MB1 .MB1-title {
	text-transform: none;
	font-size: rem(40px);
	letter-spacing: 0;
	padding: 0;
  	margin: 0;
	&:before {
		margin-bottom: rem(15px);
	}
	@include breakpoint(small){
		font-size: rem(24px);
		margin: 10px 0;
		padding: 30px 20px 0 0;
	}
}

.work-MB1 .MB1-text {
	font-size: rem(20px);
	padding: 0;
	margin: rem(8px) 0 0;
	@include breakpoint(small){
		margin: 0;
		padding: 0 18px 20px 0;
	}
}

.work-MB1 .text {
	position: relative;
	z-index: 2;
	padding-top: rem(30px);
	padding-right: rem(30px);
	padding-bottom: rem(30px);
	padding-left: rem(50px);
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 1.875rem;
		height: 100%;
		background: #282828;
	}

	@include breakpoint(small) {
		padding: 0;
		&:before {
			display: none;
		}
	}
}

// Work services
.work-services{
	@extend %shoot-up-element;
	margin: rem(60px) 0  rem(80px);
	text-align: center;
	@include breakpoint(small){
		margin: rem(80px) 0  rem(100px);
	}
	@include breakpoint(medium) {
	    .underlined-button-new {
	      transform: translateX(20px);
	      transition: color 0.3s ease, transform 0.3s ease 0.4s;
	      &:hover {
	        transform: translateX(0);
	        transition: color 0.3s ease, transform 0.3s ease;
	      }
	    }
	}
}
.WS-header{
	@include col(1,1,both);
	font-size: rem(40px);
	line-height: 1em;
	letter-spacing: 0.02em;
	text-transform: none;
	margin-bottom: rem(20px);
	color: $c-dark;
	@include breakpoint(small){
		font-size: rem(32px);
		margin-bottom: rem(40px);
	}
}
.WS-text{
	@include col(1,1,both);
	margin: 0 auto rem(25px);
	text-align: left;
	font-size: rem(18px);
	line-height: 1.2em;
	@include breakpoint(small){
		@include col(8,12,true);
		max-width: rem(535px);
		margin: 0 auto rem(50px);
	}
}
.WS-btn{

}

// Work Contact
.work-contact{
	&{
	}
	h1{
		@extend .header-headline;
	}
}
