.birdwatchVideo {
	@extend .birdwatchSlideshare;
	.embed-container { 
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		margin-top: 60px;
	} 

	.embed-container iframe, 
	.embed-container object, 
	.embed-container embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}