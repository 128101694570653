@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=783d6aa7-558c-41a7-8e6d-c14c5a4f928e");
@font-face{
	font-family:"Freight Display W01 Book";
	src:url("../assets/fonts/font-kit/01a64895-dd56-4be9-bfe6-9e43e321fba2.eot?#iefix");
	src:url("../assets/fonts/font-kit/01a64895-dd56-4be9-bfe6-9e43e321fba2.eot?#iefix") format("eot"),url("../assets/fonts/font-kit/589f520d-e0dd-4be8-810b-253337737b36.woff2") format("woff2"),url("../assets/fonts/font-kit/36ee7795-1253-4cc0-8a35-795884c20960.woff") format("woff"),url("../assets/fonts/font-kit/e1434c67-44d2-47f1-8823-ee610ba7d037.ttf") format("truetype"),url("../assets/fonts/font-kit/62e3589c-2b7c-4e95-a3d2-429c2d489f43.svg#62e3589c-2b7c-4e95-a3d2-429c2d489f43") format("svg");
}
@font-face{
	font-family:"Freight Big W01 Light Regular";
	src:url("../assets/fonts/font-kit/58f13271-f69d-4144-b279-88dda0b677d2.eot?#iefix");
	src:url("../assets/fonts/font-kit/58f13271-f69d-4144-b279-88dda0b677d2.eot?#iefix") format("eot"),url("../assets/fonts/font-kit/6608d125-6c27-4495-aaa6-ee1665d2b251.woff2") format("woff2"),url("../assets/fonts/font-kit/8bd246ef-a842-4f8f-b5e8-6e166196dfc1.woff") format("woff"),url("../assets/fonts/font-kit/17c29a76-1940-444f-87fe-c683681eb011.ttf") format("truetype"),url("../assets/fonts/font-kit/e779d8e4-2020-4280-a420-4810f6b3a72e.svg#e779d8e4-2020-4280-a420-4810f6b3a72e") format("svg");
}
@font-face{
	font-family:"Freight Big W01 Light Italic";
	src:url("../assets/fonts/font-kit/09f7efa4-31be-4177-a121-90f4adfaac47.eot?#iefix");
	src:url("../assets/fonts/font-kit/09f7efa4-31be-4177-a121-90f4adfaac47.eot?#iefix") format("eot"),url("../assets/fonts/font-kit/72cba138-d811-4c64-81e4-5b21e52fca41.woff2") format("woff2"),url("../assets/fonts/font-kit/6d267aa4-c43e-4f9b-b9db-09468fa42ec6.woff") format("woff"),url("../assets/fonts/font-kit/2e042a9e-22ae-45c8-aed3-b0159e55a634.ttf") format("truetype"),url("../assets/fonts/font-kit/ce9e77f7-f8af-4c90-aed9-1afd3c704641.svg#ce9e77f7-f8af-4c90-aed9-1afd3c704641") format("svg");
}
@font-face{
	font-family:"NeuzeitSLTW01-BookHeavy";
	src:	url("../assets/fonts/font-kit/74c31844-4217-4fe8-82f7-88a5075de2e8.eot?#iefix");
	src:	url("../assets/fonts/font-kit/74c31844-4217-4fe8-82f7-88a5075de2e8.eot?#iefix") format("eot"),url("../assets/fonts/font-kit/3da0a3e3-3dff-405c-a0b2-0b0050b21771.woff2") format("woff2"),url("../assets/fonts/font-kit/a1476bae-b118-4e9d-b711-c84091d9bc16.woff") format("woff"),url("../assets/fonts/font-kit/096111d9-3ba2-4828-ae9a-29fb4e03c0b1.ttf") format("truetype"),url("../assets/fonts/font-kit/74a8e701-bffc-464e-a60b-63169debd492.svg#74a8e701-bffc-464e-a60b-63169debd492") format("svg");
}
