.home-culture {
	& {
		@extend %cf;
		@include max-width();
		max-width: 1082px;
		padding: 0 10px 50px;
		@include breakpoint(medium) {
			padding: 0 20px;
			margin-bottom: 100px;
				padding: 0 0 100px;
		}
	}
	h1 {
		@extend %shoot-up-element;
		margin: 0 0 20px;
		@include font(sans);
		font-size: 44px;
		color: $c-dark;
		text-align: center;
		@include breakpoint(medium) {
			text-align: left;
		}
	}
	p {
		font-size: 20px;
		line-height: 1.7rem;
	}
	.HC-block {
		@extend %shoot-up-element;
		width: 100%;
		margin: 0 0 40px;

		@include breakpoint(small) {
			width: grid(6);
			float: left;
			padding: 20px 0;
			margin: 0;
			&:nth-child(1){
				padding-left: 0px;
			}
			&:nth-child(even){
				padding-right: 20px;
			}
			&:nth-child(odd){
				padding-left: 20px;
			}
		}
		@include breakpoint(medium) {
			&:nth-of-type(1) {
				transition-delay: 0.3s;
			}
			&:nth-of-type(2) {
				transition-delay: 0.6s;
			}
		}
	}
	.image-link {
		display: block;
		overflow: hidden;
		margin: 0 0 30px;

		&:hover .image {
			@include breakpoint(medium){
				opacity: .7;
			}
		}
	}
	.image {
		width: 100%;
		height: 0;
			height: 324px;
		background-size: cover;
		background-repeat: no-repeat;
		transition: 0.3s ease;
	}
	h5 {
		margin: 0 0 15px;
		@include font(sans);
		text-transform: uppercase;
		letter-spacing: 0.4em;
		font-size: 14px;
		color: $c-dark;
	}
	h2 {
		margin: 0 0 20px;
		@include font(sans);
		font-size: 36px;
		color: $c-dark;
	}
}
