.journal-full-image{
	&{
		@extend %image-lock-up-ctn;
		@extend %cf2;
		@include breakpoint(small){
			position: relative;
			padding: 0;
			margin-top: rem(90px);
			margin-bottom: rem(90px);
		}
	}
	img,
	video{
		width: 100%;
	}
}
