.birdwatchSubheader{
  &{
    padding: 30px;
    @extend %birdwatch-outer;
    font-size: 0;
  }
  .subheader-left-panel h1{
    @extend %bw-header;
    max-width: 400px;
  }
  .subheader-right-panel p{
    @extend %bw-text;
  }

  @include breakpoint(medium) {
    & {
      padding: 0 0 60px 0;
      position: relative;
      margin-bottom: 50px;
    }
    .subheader-right-panel{
      width: 50%;
      padding-left: 20px;
    }
    .subheader-right-panel p{
      margin: 0
    }
  }
  img{
	  width: 100%;
	@include mobilebp(){
		padding-top: 20px;
	}
  }
}

.birdwatchEntrySubheader{
  &{
    @extend %birdwatch-outer;
    padding: 30px;
    border: none;

    @include breakpoint(medium) {
      position: relative;
      padding: 0 0 20px 0;
    }
  }
  &.holidayParty {
    h1 {
      &:after {
        margin: 15px 0 0;
      }
    }
    h3 {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.12em;
      margin: -15px 0 0;
      line-height: 2.4em;
      p {
        margin: 0;
      }
    }
    .location {
      margin: 0;
      text-transform: none;
      letter-spacing: 0;
      font-size: 18px;
    }
    .subheader-right-panel p {
      margin: 20px 0;
    }
    .subheader-right-panel a {
      font-size: 18px;
    }
  }
  h1{
    @extend %birdwatch-title;
    max-width: 450px;
  }
  h3{
    font-family: 'NeuzeitSLTW01-BookHeavy';
    font-weight: bold;
    font-size: 20px;
    color: #282828;
    margin: 30px 0 20px;

    @include breakpoint(medium) {
      margin: 30px 0 0;
    }
  }
  .subheader-left-panel {
    @include breakpoint(medium) {
      vertical-align: top;
    }
  }
  .subheader-right-panel p{
    @extend %bw-text;
    letter-spacing: 0.04em;
    margin: 0;
  }
  .subheader-right-panel .location{
    margin: 20px 0 50px;
    color: $c-dark;
    font-family: 'NeuzeitSLTW01-BookHeavy';
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .24em;
    font-size: 12px;
  }
  .subheader-right-panel a {
    font-size: 23px;
  }
}
