// CAREERS HEADER
.careers-slider {
  position: relative;
}
.CH-slideshow {
  display: block;
  width: 100%;
  height: 100%;
}
.CH-slide {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.CH-slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  text-align: center;
  background-color: transparent;
  max-width: 710px;
  padding: 0 18px;
  @include breakpoint(small){
    padding: 0;
  }
}
.CH-main-title {
  @include font('sans',3);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5em;
  color: #fff;
}
.CH-slide-title {
  @include font('sans',3);
  margin: 0 0 10px;
  font-size: rem(40px);
  color: #fff;
  letter-spacing: 0;
  text-transform: none;

  @include breakpoint(small) {
    font-size: rem(68px);
  }
}
.CH-slide-copy {
  @include font(freight-text);
  font-size: rem(22px);
  line-height: rem(31px);
  letter-spacing: 0.04em;
  color: #fff;
  font-style: normal;
  margin: 0;
}
.CH-slide-controls-prev,
.CH-slide-controls-next {
  position: absolute;
  z-index: 102;
  top: 50%;
  transform: translateY(-50%);
  padding: rem(45px);
  color: #fff;
  border: rem(1px) solid #fff;
  cursor: pointer;

  span {
    position: absolute;
    transform: translate(-50%,-50%);
    display: block;
  }
}
.CH-btns {
  height: 100%;
}
.CH-btn {
  margin: 0;
}
.CH-slide-controls-prev {
  left: 0;
}
.CH-slide-controls-next {
  right: 0;
}
.CH-slide-dots {
  position: absolute;
  z-index: 102;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  height: auto;
  width: auto;
}

// CAREERS POSITIONS
.careers-positions {
  @extend %cf;
  padding: rem(64px) 18px 0;
  max-width: rem(950px);
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(small) {
    padding: rem(115px) 0 0;
  }

  @include breakpoint(medium) {
    &.track-visible .CP-position-ctn,
    &.track-visible .CP-title,
    &.track-visible .CP-body {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.CP-title {
  @include font('sans',3);
  padding: 0;
  margin: 0;
  font-size: rem(30px);
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  @extend %shoot-up-element;

  @include breakpoint(small) {
    font-size: rem(44px);
  }
}
.CP-body {
  @include font(freight,0,none);
  font-size: rem(20px);
  margin: rem(20px) 0 0;
  letter-spacing: 0.04em;
  text-align: center;
  @extend %shoot-up-element;
}
.CP-position-ctn {
  @extend %shoot-up-element;
  &:nth-of-type(2),
  &:nth-of-type(4){
    transition-delay: .3s;
  }
  position: relative;
  z-index: 1;
  float: left;
  margin: rem(48px) 0 0;
  padding: 0 rem(20px) rem(52px);
  border-top: rem(1px) solid rgb(237, 237, 237);
  @include col(12,12,false);
  &:before {
    content: "";
    position: absolute;
    top: 50px;
    left: rem(20px);
    transform: none;
    display: block;
    width: rem(60px);
    height: rem(2px);
    background-color: #ccc; //temp
  }
  &:after {
    display: none;
  }
  &:hover::after{
    display: none;
  }

  @include breakpoint(small) {
    @include col(5,12,false,0.5,0.5);
    max-width: 100%;
    margin-top: rem(80px);
    padding: 0 0 rem(100px);
    height: 220px;
    &:before {
      left: 0;
    }
    &:hover .underlined-button-new {
      opacity: 1;
      top: 0;
    }
  }

  &.CP_single-position {
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 100%;
    max-width: 475px;
  }
}
.CP-position-ctn .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.CP-position-title {
  display: table;
  height: 70px;
  margin: 0;
  padding: rem(80px) 0 0;
  text-align: left;

  @include font('sans',3);
  font-size: rem(26px);
  line-height: rem(30px);
  letter-spacing: 0em;
}
.CP-position-link {
  display: table-cell;
  height: 100%;
  padding: 0;
  vertical-align: middle;
  text-decoration: none;
  text-transform: none;
  color: #000;
}
.CP-position-copy {
  margin: 0 0 rem(16px);
  padding: 0;
  text-align: left;
  @include font(freight,0,none);
  @include font-smoothing(no);
  font-size: rem(20px);
  font-style: normal;
  line-height: rem(30px);
  letter-spacing: 0.04em;
}
.CP-position-ctn .underlined-button-new {
  position: relative;
  z-index: 2;

  @include breakpoint(medium) {
    opacity: 0;
    top: 5px;
    transition: all 0.3s ease;
  }
}

// CAREERS EMAIL
.careers-email {
  @extend %shoot-up-element;
  margin: rem(50px) 0 rem(95px);
  @include breakpoint(small) {
    margin-top: rem(60px);
  }
  @include breakpoint(medium) {
    margin: rem(100px) 0 rem(115px);
  }
}
.CE-title {
  margin: 0;
  text-align: center;
  svg {
    width: rem(50px);
    height: rem(50px);
    fill: #afafaf;  }
}
.CE-copy {
  width: 80%;
  max-width: rem(600px);
  margin: 0 auto;
  padding: rem(55px) 0;
  text-align: left;
  text-indent: 25px;

  @include font('serif',0);
  font-size: rem(20px);
  font-style: normal;
  line-height: rem(30px);
  letter-spacing: 0.04em;
}
.CE-copy a {
  position: relative;
  z-index: 1;
  display: inline;
  font-weight: bold;
  line-height: 1.25em;
  color: #282828;
  text-decoration: none;
  border-bottom: 1px solid #282828;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 100%;
    background-color: #ddd;
    transition: .3s ease-out;
  }
  &:hover:before {
    width: 100%;
    transition: .2s ease-out;
  }
}
.CE-email-ctn {
  margin: 0;
  text-align: center;

  @include breakpoint(medium) {
    .underlined-button-new {
      transform: translateX(20px);
      transition: color 0.3s ease, transform 0.3s ease 0.4s;
      &:hover {
        transform: translateX(0);
        transition: color 0.3s ease, transform 0.3s ease;
      }
    }

  }
}

// Career Home
.CAR-page{
  background: $c-light-grey;
  padding: 0 20px;

  @include breakpoint(small) {
    padding: 0;
  }
}
.CAR-content{
  padding: rem(30px) 0 rem(10px);
  text-align: center;
  @include breakpoint(small){
    @include col(6,12,both,3);
    padding: rem(160px) 0 rem(100px);
  }
}
.CAR-title{
  @include font('sans',3);
  font-size: 12px;
  letter-spacing: 0.8em;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: $c-med-grey;
}
.CAR-job{
  margin: rem(20px) 0 rem(50px);
  font-size: 44px;
  letter-spacing: 0.02em;
  line-height: 48px;
  text-align: center;
  @include font(sans, 3);
  color: $c-dark;
}
.CAR-content h2{
  margin: rem(20px) 0 rem(50px);
  font-size: 44px;
  letter-spacing: 0.02em;
  line-height: 48px;
  text-align: center;
  @include font(sans, 3);
  color: $c-dark;
}
.CAR-btn{
  margin: 50px auto;
}
.CAR-description{
  > p {
    @include font(sans, 3);
    font-size: rem(18px);
    line-height: rem(26px);
    text-align: center;
    color: $c-dark;
  }
}
.CAR-info{
  margin-bottom: rem(60px);
  text-align: left;
  >p{
      @extend .text-copy;
      u {
      	margin: 3.75rem 0 2.5rem;
	    font-size: 1.875rem;
	    letter-spacing: 0;
	    text-align: left;
	    text-transform: none;
	    color: #282828;
	    font-family: 'NeuzeitSLTW01-BookHeavy';
	    font-weight: 400;
	    -webkit-font-smoothing: antialiased;
	    text-decoration: none;
      }
  }
  h3{
	margin: 3.75rem 0 2.5rem;
	font-size: 1.875rem;
	letter-spacing: 0;
	text-align: left;
	text-transform: none;
	color: #282828;
	font-family: 'NeuzeitSLTW01-BookHeavy';
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	text-decoration: none;
  }

}
.CAR-hear {
  @include font(sans);
  position: relative;
  border: none;
  background: #eaebeb;
  outline: none;
  padding: 0 25px;
  margin-bottom: 20px;
  height: 80px;
  font-size: 18px;
  transition: all 0.3s ease;
  &:hover {
    background: #BEBEBE;
  }
}
.CAR-hear span {
  transition: opacity 0.3s ease;
  margin-left: 35px;
}
.CAR-hear .svg-container{
  position: absolute;
  left: 20px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  transform: none;
  transition: all 0.4s ease;
  margin-right: 10px;
}
.expanded .CAR-hear .svg-container {
  transform: rotate(-45deg) translateZ(0);
  left: 45%;
}
.CAR-hear svg {
  width: 25px;
  height: 25px;
}
.expanded .CAR-hear span {
  opacity: 0;
}
.CAR-video-ctn {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}
.CAR-list-header{
  margin: rem(60px) 0 rem(40px);
  font-size: rem(30px);
  letter-spacing: 0;
  text-align: left;
  text-transform: none;
  color: $c-dark;
}
.CAR-content ul{
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
}
.CAR-content ul > li{
  &{
  	@extend .text-copy;
	position: relative;
	z-index: 1;
	padding-left: 100px;
    font-size: rem(18px);
    margin-bottom: rem(40px);
  }
  &::before{
	position: absolute;
	left: 0;
    float: left;
    content: "";
    display: block;
    width: rem(80px);
    height: rem(4px);
    margin-top: rem(12px);
    background: $c-med-grey;
  }
}
.CAR-list-content{
  &{
    width: auto;
    margin: 0 0 0 rem(100px);
    font-size: rem(18px);
  }
}
.CAR-list-content h3{
	@extend .CAR-list-header;
	@extend .header-headline;
}
.CAR-footer{
  text-align: left;
  >p{
      @extend .text-copy;
  }
}
.CAR-content-bottom {
  @include col(11,12,none,0.5);
  padding-bottom: rem(115px);
}
.CAR-about-ctn {
  @extend %cf;
  border-top: 1px solid $c-med-grey;
  padding: rem(110px) 0;
}
.CAR-about {
  @include breakpoint(small) {
    float: left;
    @include col(4,12,both,0);
  }
}
.CAR-about > div {
  text-align: center;
}
.CAR-about svg {
  width: rem(80px);
  height: rem(80px);
}
html:not(.browser-ie) .CAR-about svg {
  & .path,
  & .six,
  & .five,
  & .four,
  & .three,
  & .two,
  & .one {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
}
html:not(.browser-ie) .CAR-about:hover svg {
  &,
  & polyline,
  & path,
  & line,
  & rect {
    stroke: $c-primary;
  }
  & .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s linear alternate infinite;
    animation: dash 10s linear alternate infinite;
  }
  & .six {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in alternate infinite;
    -webkit-animation-delay: 1.25s;
    animation: dash 10s ease-in alternate infinite;
    animation-delay: 1.25s;
  }
  & .five {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in alternate infinite;
    -webkit-animation-delay: 1s;
    animation: dash 10s ease-in alternate infinite;
    animation-delay: 1s;
  }
  & .four {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in alternate infinite;
    -webkit-animation-delay: 0.75s;
    animation: dash 10s ease-in alternate infinite;
    animation-delay: .75s;
  }
  & .three {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in infinite;
    -webkit-animation-delay: 0.5s;
    animation: dash 10s ease-in infinite;
    animation-delay: 0.5s;
  }
  & .two {;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in infinite;
    -webkit-animation-delay: 0.25s;
    animation: dash 10s ease-in infinite;
    animation-delay: 0.25s;
  }
  & .one {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash 10s ease-in infinite;
    animation: dash 10s ease-in infinite;
  }
}
.CAR-about-copy h2 {
  @include font(sans, 3);
  font-size: rem(20px);
  line-height: 30px;
  text-align: center;
  color: $c-dark;
}
.CAR-about-copy p {
  font-size: rem(18px);
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;

  @include breakpoint(medium) {
    padding: 0 rem(55px);
  }
}
.CAR-about-copy a {
  color: $c-dark;
  transition: color 0.3s ease;

  &:hover {
    color: $c-med-grey;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  10% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes lift {
  0% {
    margin-top: 165px;
  }
  10% {
    margin-top: 115px;
  }
  100% {
    margin-top: 115px;
  }
}
.CAR-apply {
  margin: 0 0 100px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: $c-dark;
  @include font(sans);
  font-size: 30px;
  line-height: 40px;
  border: 1px solid $c-dark;
  padding: 20px 110px;
  transition: all 0.3s ease;
  &:hover {
    color: $c-light;
    background-color: #000000;
    border-color: #000000;
  }
}
.CAR-content .CAR-apply {
  margin: 100px 0 0;
}
.CAR-social {
  text-align: center;
}
.CAR-social h1 {
  margin: 0;
  @include font(sans, 3);
  font-size: rem(30px);
  line-height: 1em;
  text-align: center;
}
.CAR-social-icons {
  text-align: center;
  padding-top: rem(40px);
}
.CAR-social-icons a {
  display: inline-block;
  border-top: 1px solid $c-dark;
  border-bottom: 1px solid $c-dark;
  border-left: 1px solid $c-dark;
  padding: rem(15px);
  color: $c-dark;
  &:last-child {
    border-right: 1px solid $c-dark;
  }
  &:nth-of-type(even) {
      margin: 0 rem(-3.5px) 0 rem(-3.5px);
  }
  &:nth-of-type(4) {
    padding: rem(15px) rem(10.3px);
  }
  &:last-child svg {
    width: rem(25px);
  }
  @include breakpoint(small) {
    padding: rem(20px);
    &:nth-of-type(4) {
      padding: rem(20px) rem(15.3px);
    }
  }
  @include breakpoint(medium) {
    transition: all 0.3s ease;
    &:hover {
      color: $c-light;
      background-color: #000000;
      border-color: #000000;
    }
    svg {
      transition: all 0.3s ease;
    }
    &:hover svg {
      fill: $c-light;
    }
  }
}
.CAR-social-icons svg {
  height: rem(25px);
  vertical-align: middle;
}
