.contact-contacts {
  background: #f2f3f3;
  padding: rem(130px) 0 0;
  @extend %cf;
}
.CC-list{
  @extend %cf;
  max-width: rem(950px);
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.CC-title {
  margin: 0;
  padding: 0 0 rem(30px);
  text-align: center;

  @include font('sans',3);
  font-size: rem(24px);
  letter-spacing: 0.8em;
}
.CC-contact-link {
  position: relative;
  float: left;
  text-decoration: none;
  @include col(12,12,false);
  @include breakpoint(small){
    @include col(5,12,false,0.5,0.5);
    margin-bottom: rem(70px);
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: rem(60px);
    height: rem(2px);
    background-color: rgb(218, 218, 218);
  }

  @include breakpoint(medium) {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: rem(1px);
      background-color: rgb(237, 237, 237);
    }
  }
  @extend %shoot-up-element;
  $delay: 0;
  @for $i from 0 to 5 {
    &:nth-child(#{$i}) {
      transition-delay: $delay;
    }
    $delay: $delay + .3s;
  }
  &:hover {
    .CC-contact-email-ctn {
      color: #282828;
    }
  }
  .track-visible &{
    transform: translateY(0);
    opacity: 1;
  }

}
.CC-contact-title {
  display: block;
  margin: 0 auto;
  padding: rem(50px) 0 rem(25px);
  text-align: center;
  color: #282828;

  @include font('sans',3);
  font-size: rem(20px);
  line-height: 1em;
  letter-spacing: 0;
  text-transform: none;
  transition: color 0.3s ease;
}
.CC-contact-email-ctn {
  display: block;
  margin: 0 0 rem(60px);
  padding: 0 rem(20px);
  text-align: center;
  text-decoration: none;
  color: #949494;
  @include font(freight, 0, none);
  font-size: rem(22px);
  line-height: 1em;
  letter-spacing: 0.04em;
  transition: color 0.3s ease;
}

// CAREERS EMAIL
.contact-telephone {
  background: #f2f3f3;
  padding: 0 0 rem(105px);

  @include breakpoint(medium) {
    padding-top: rem(25px);
  }
}
.CT-ctn {
  @extend %shoot-up-element;
  transition: 0.3s ease;
}
.CT-link {
  display: block;
  width: 210px;
  margin: 0 auto;
  text-decoration: none;
  &:hover {
    .CT-title svg {
      fill: #282828;
    }
    .CT-phone {
      color: #282828;
    }
  }
}
.CT-title {
    margin: 0;
    text-align: center;
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: #afafaf;
      transition: all 0.3s ease;
    }
}
.CT-copy {
  width: 80%;
  max-width: rem(450px);
  margin: 0 auto;
  padding: rem(20px) 0 rem(25px);
  text-align: center;
  color: #282828;
  @include font(sans,3);
  font-size: rem(20px);
  line-height: 1em;
  letter-spacing: 0;
  transition: color 0.3s ease;
}
.CT-phone-ctn {
  margin: 0;
  text-align: center;
}
.CT-phone {
  text-transform: uppercase;
  text-decoration: none;

  @include font(freight,0,none);
  font-size: rem(22px);
  letter-spacing: 0.04em;
  color: #949494;
  transition: color 0.3s ease;
}

.CT-work-arrow {
  text-align: center;
  margin-top: 15px;

  @include breakpoint(medium) {
    .underlined-button-new {
      transform: translateX(28px);
      transition: color 0.3s ease, transform 0.3s ease 0.4s;
      &:hover {
        transform: translateX(0);
        transition: color 0.3s ease, transform 0.3s ease;
      }
    }
  }
}

.CC-map {
  display: none;
  @include breakpoint(medium) {
    display: block;
    width: 100%;
    position: relative;
  }
  &-overlay {
    padding: 30px;
    pointer-events: none;
    display: none;
    background-color: #fff;
    h1 {
      @include font(sans);
      font-weight: 400;
      margin: 0;
      .smallText {
        text-transform: uppercase;
        letter-spacing: 0.4em;
        display: block;
        font-size: rem(15px);
        margin-bottom: rem(10px);
      }
      .largeText {
        display: block;
        font-size: rem(40px);
      }
    }
    @include breakpoint(medium) {
      position: absolute;
      bottom: 25px;
      left: 55px;
      display: block;
      z-index: 80;
      height: auto;
      width: auto;
    }
  }
}
#contactMap {
  height: 0;
  overflow: hidden;
  padding-bottom: 45.25%;
  position: relative;
  z-index: 60;
}

%CCO-blurb {
  @extend %shoot-up-about;
  position: relative;
  transition-delay: .3s;
  $delay: .3s;
  @for $i from 0 to 4 {
    &:nth-child(#{$i}) {
      transition-delay: $delay;
    }
    $delay: $delay + .3s;
  }
  padding: 30px 18px 0;
  @include breakpoint(small){
    padding: 0;
  }
}
%CCO-blurb .CCO-blurb-ctn {
  width: 100%;
}
%CCO-blurb h2{
  @extend .header-headline;
  font-size: 14px;
  letter-spacing: .5em;
  color: $c-dark;
  text-transform: uppercase;
}
%CCO-blurb h1{
  @include font(sans, 3);
  font-size: 40px;
  margin: rem(9px) 0 rem(32px);
  line-height: 1em;

  @media (min-width: 767px) and (orientation: portrait) {
    font-size: 30px;
  }
}
%CCO-blurb h1 span{
  display: block;
}
%CCO-blurb p{
  @extend .text-copy;
  font-size: 20px;
  margin: 0;
  line-height: 1.5em;

  @media (min-width: 767px) and (orientation: portrait) {
    font-size: 18px;
  }
}
// Slider styles
%CCO-slideshow{
  position: relative;
  @extend %shoot-up-about;
  float: none;
  margin: rem(1px) 0;
  overflow: hidden;
  &:before{
    content: "";
    display: block;
  }
  backface-visibility: hidden;
  transition-delay: .3s;
  $delay: .3s;
  @for $i from 0 to 12 {
    &:nth-child(#{$i}) {
      transition-delay: $delay;
    }
    $delay: $delay + .3s;
  }
  video {
    width: 100%;
    height: 100%;
  }
  @include breakpoint(small) {
    float: left;
  }
}
// About the Office Section
.CCO{
  @include max-width();
  margin: 30px auto 30px;
  @include breakpoint(max) {
    position: relative;
    margin: 40px auto 40px;
  }
  // Rows
  &-row {
    @extend %cf;

    @include breakpoint(small) {
      &:nth-of-type(even) {
        margin: 3% 0;
      }
    }
  }
  &-blurb-2{
    @extend %CCO-blurb;
    @include col(12,12,false);
    display: table;

    @include breakpoint(small) {
      @include col(5,12,false);
      float: left;
    }
  }
  &-blurb-2 &-blurb-ctn {
    display: table-cell;
    vertical-align: middle;
    padding: 0 30px;
    @include breakpoint(medium) {
      padding: 0 60px;
    }
  }
  //Slide shows - before element controls the height for slideshows
  &-slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    &.cycle-sentinel {
      display: none;
    }
    & video {
      pointer-events: none;
    }
  }
  &-slideshow-1{
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before {
      padding-bottom: 111.47%;
    }
    @include breakpoint(small) {
      @include col(3,12,false,0,.36);
    }
  }
  &-slideshow-2{
    display: none;
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before {
      padding-bottom: 86.07%;
    }
    @include breakpoint(small) {
      display: block;
      @include col(3.64,12,false,0,.36); //4
      &:before {
        padding-bottom: 0;
      }
    }
  }
  &-slideshow-3{
    display: none;
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before {
      padding-bottom: 69.38%;
    }
    @include breakpoint(small) {
      display: block;
      @include col(4.64,12,false); //5
      &:before {
        padding-bottom: 0;
      }
    }
  }
  &-slideshow-4{
    display: none;
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before{
      padding-bottom: 82.71%;
    }
    @include breakpoint(small) {
      display: block;
      @include col(4,12,false);
    }
  }
  &-slideshow-5{
    display: none;
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before {
      padding-bottom: 106.45%;
    }
    @include breakpoint(small) {
      display: block;
      @include col(3,12,false);
      &:before {
        padding-bottom: 0;
      }
    }
  }
  &-slideshow-6{
    display: none;
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before{
      padding-bottom: 49.44%;
    }
    @include breakpoint(small) {
      display: block;
      @include col(7,12,false,0,.36);
    }
  }
  &-slideshow-7{
    @extend %CCO-slideshow;
    @include col(12,12,false);
    &:before {
      padding-bottom: 70%;
    }
    @include breakpoint(small) {
      @include col(4.64,12,false); // 5
      &:before {
        padding-bottom: 0;
      }
    }
  }
}

.CC-subfooter {
  a {
    text-decoration: none;
    position: relative;
    display: block;
    height: 300px;
    @include breakpoint(small){
      height: 350px;
    }
    &:after {
      content: "";
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #282828;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:hover {
      &:after {
        opacity: 0.3;
      }
    }
  }
  &-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #282828;
    backface-visibility: hidden;
    height: 300px;
    @include breakpoint(small){
      height: 350px;
    }
  }
  &-copy {
    & {
      position: absolute;
      z-index: 3;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%,-50%,0);
    }
    &-title {
      text-decoration: none;
      color: #fff;
      padding: 34px 0 20px;
      @include font(sans);
      font-size: 40px;
      @include breakpoint(small){
        font-size: 55px;
      }
    }
    &-link {
      font-size: 18px !important;
    }
  }
}

// UPDATED CONTACT PAGE
.CP {
  &-hero {
    & {
      display: table !important;
      width: 100%;
      text-align: center;
      background-color: #1c1c1c;
      @include breakpoint(small) {
        display: block !important;
        height: auto !important;
        padding: rem(175px) rem(20px) rem(100px);
      }
    }
    &-content {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      @include breakpoint(small) {
        display: block;
        height: auto;
        vertical-align: initial;
      }
      @include breakpoint(medium) {}
    }
    &-title {
      margin: 0 0 rem(10px);
      @include font('sans',3);
      font-size: rem(37px);
      letter-spacing: 0;
      color: #fff;
      @include breakpoint(small) {
        font-size: rem(67px);
        margin: 0 0 rem(5px);
      }
      @include breakpoint(medium) {
        margin: 0 0 rem(15px);
      }
    }
    &-copy {
      max-width: rem(259px);
      margin: 0 auto;
      @include font(freight);
      font-size: rem(21px);
      letter-spacing: 0.07em;
      line-height: 1.25em;
      color: #fff;
      @include breakpoint(small) {
        max-width: rem(380px);
        font-size: rem(20px);
        letter-spacing: 0.05em;
        line-height: 1.4em;
      }
      @include breakpoint(medium) {
        max-width: rem(490px);
        font-size: rem(22px);
      }
      a {
        color: #fff;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.6;
        }
      }
      p {
        margin: 0 0 1em;
      }
    }
  }
  &-info {
    @extend %cf;
    position: relative;
    display: block;
    max-width: rem(1200px);
    margin: 0 auto;
    padding: rem(60px) 0 rem(50px);
    background: #ededed;
    @include breakpoint(small) {
      padding: rem(100px) 0 rem(50px);
    }
    @include breakpoint(medium) {
      padding: rem(135px) 0 rem(150px);
    }
  }
  &-intro,
  &-locations,
  &-emails {
    position: relative;
    float: left;
    display: block;
    padding: 0 rem(30px);
    @include breakpoint(small) {
      padding: 0 rem(80px);
    }
    @include breakpoint(medium) {
      padding: 0 rem(25px);
      transform: translate(0,rem(50px));
      opacity: 0;
      transition: opacity 0.6s ease-out, transform 0.6s ease-out;
      &.track-visible {
        transform: translate(0,0);
        opacity: 1;
      }
    }
    @include breakpoint(max) {
      padding: 0 rem(40px);
    }
    &:before {
      content: "";
      display: block;
      width: rem(50px);
      height: 2px;
      margin: 0 0 rem(40px);
      background: #dadada;
      @include breakpoint(small) {
        margin: 0 0 rem(50px);
      }
      @include breakpoint(medium) {}
    }
  }
  &-intro {
    & {
      width: 100%;
      margin: 0 0 rem(100px);
      @include breakpoint(small) {

      }
      @include breakpoint(medium) {
        width: 40%;
        margin: 0 0 rem(80px);
      }
    }
    &-title {
      margin: -0.18em 0 0;
      @include font('sans',3);
      font-size: rem(37px);
      line-height: 1.3em;
      letter-spacing: 0;
      color: $c-dark;
      @include breakpoint(small) {
        font-size: rem(67px);
      }
    }
    &-copy {
      margin: rem(30px) 0 rem(15px);
      @include font(freight);
      font-size: rem(21px);
      letter-spacing: 0.025em;
      line-height: 1.5em;
      color: $c-dark-grey;
      @include breakpoint(small) {
        margin: rem(40px) 0 rem(20px);
        font-size: rem(22px);
        letter-spacing: 0.05em;
        line-height: 1.45em;
      }
      @include breakpoint(medium) {
        max-width: rem(350px);
        margin: rem(40px) 0 rem(20px);
      }
      a {
        color: $c-dark-grey;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &-locations {
    & {
      width: 100%;
      margin-bottom: rem(50px);
      @include breakpoint(small) {
        width: 50%;
        padding-right: 0;
        margin-bottom: 0;
      }
      @include breakpoint(medium) {
        width: 25%;
        padding-right: rem(25px);
        transition-delay: 0.3s;
      }
      @include breakpoint(max) {
        padding-right: rem(40px);
      }
      a {
        display: block;
        color: $c-dark-grey;
        text-decoration: none;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &-emails {
    & {
      width: 100%;
      margin-bottom: rem(50px);
      @include breakpoint(small) {
        width: 50%;
        margin-bottom: 0;
        padding-left: 0;
      }
      @include breakpoint(medium) {
        width: 35%;
        padding-left: rem(25px);
        transition-delay: 0.6s;
      }
      @include breakpoint(max) {
        padding-left: rem(40px);
      }
    }
  }
}
.contact {
  &-item {
    & {
      margin: 0 0 rem(50px);
      @include breakpoint(small) {
        margin: 0 0 rem(80px);
      }
      @include breakpoint(medium) {
        &:last-of-type {
          margin: 0 0 0;
        }
      }
    }
    &-title {
      margin: 0 0 rem(10px);
      @include font('sans',3);
      font-size: rem(30px);
      letter-spacing: 0;
      color: $c-dark;
      @include breakpoint(small) {}
      @include breakpoint(medium) {}
    }
    &-copy {
      max-width: rem(250px);
      margin: 0;
      @include font(freight);
      font-size: rem(21px);
      letter-spacing: 0.025em;
      line-height: 1.5em;
      color: $c-dark-grey;
      @include breakpoint(small) {}
      @include breakpoint(medium) {}
    }
    &-email {
      margin: 0;
      @include font(freight);
      font-size: rem(21px);
      letter-spacing: 0.025em;
      line-height: 1.5em;
      color: $c-dark-grey;
      transition: 0.3s ease;
      @include breakpoint(small) {}
      @include breakpoint(medium) {}
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.CP-color-section{
  background: #ededed;
}
.CP-form-expand{
  &{}
  .start-project-ctn{
    &{
      @include max-width();
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0 20px;
      @include breakpoint(medium) {
        padding: 0 80px;
      }
    }
    button{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      opacity: 0;
      border: 0;
    }
    $size: 20px;
    .icon-ctn{
      display: inline-block;
      vertical-align: middle;
      height: $size;
      width: $size;
      margin-right: 10px;
      @include breakpoint(medium) {
        margin-right: 40px;
      }
    }
    .icon{
      &{
        position: relative;
        display: block;
        height: $size;
        width: $size;
        transition: .3s ease-out;
      }
      &::before,
      &::after{
        content:"";
        position: absolute;
        display: block;
        background: #282828;
      }
      &::before{
        top: 0;
        left: 50%;
        height: $size;
        width: 2px;
        transform: translateX(-50%);
      }
      &::after{
        top: 50%;
        left: 0;
        height: 2px;
        width: $size;
        transform: translateY(-50%);
      }
    }
    button.form-expanded ~ .icon-ctn .icon{
        transform: rotate(45deg);
      }
    h2{
      @include font('sans',3);
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      letter-spacing: .05em;
      text-transform: uppercase;
      color: #131516;
      @include breakpoint(medium) {
        font-size: 26px;
      }
    }
  }
  .form-contain{
    height: 0;
    overflow: hidden;
    transition: .6s ease-out;
  }
  #newBusinessCtn{
    height: auto;
  }
  #newBusinessFormCtn{
    padding: 80px 20px;
    @include breakpoint(medium) {
      padding: 80px 80px;
    }
  }
  .new-business-form-ctn{
    background: #fff;
  }
}
