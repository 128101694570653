.about-culture {
	@include breakpoint(medium) {
		max-width: 1350px;
		margin: 60px auto 0;
		min-height: 865px;
		position: relative;
	}
}
.AC-{
	&blurb {
		display: block;
		padding: 30px 10px;
	}
	&blurb span {
		display: block;
	}
	&blurb p {
		font-size: rem(20px);
		letter-spacing: 0.04em;
		margin: 0;
		line-height: 1.5em;
	}
	&title {
		margin: 0;
		@include font('sans',3);
		font-size: rem(12px);
		text-transform: uppercase;
		letter-spacing: 0.5em;
	}
	&subtitle {
		margin: rem(15px) 0;
		@include font(serif, 0);
		font-size: rem(30px);
		line-height: 1em;
	}
	&slide {
		width: 100%;
	}
	&slideshow-ctn {
		position: relative;
	}
	&slideshow-top img {
		float: left;
	}
	&slideshow-top &slide-1 {
		width: 55.5%;
	}
	&slideshow-top &slide-2 {
		width: 44.5%;
	}
	&slideshow-bottom img {
		width: 100%;
		margin-top: -4px;
	}

	@include breakpoint(medium) {
		&blurb {
			display: inline-block;
			max-width: 460px;
			padding: 50px 0 50px 20px;
  			width: 45%;
		}
		&blurb p {
			font-size: rem(16px);
		}
		&title {
			font-size: rem(14px);
		}
		&subtitle {
			font-size: rem(40px);
		}
		&slideshow-ctn {
			display: inline-block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}
		&slideshow-top {
			float: right;
			width: 55%;
			padding-bottom: 15px;
		}
		&slideshow-top &slide-1 {
			width: 54.5%;
			float: left;
		}
		&slideshow-top &slide-2 {
			width: 43.75%;
			float: right;
		}
		&slideshow-bottom &slide-1,
		&slideshow-bottom &slide-2 {
			width: 49.5%;
			float: left;
		}
		&slideshow-bottom &slide-1 {
			margin-right: 1%;
		}
	}
	@include breakpoint(max) {
		&blurb {
			padding: 80px 0 80px 70px;
		}
	}
}