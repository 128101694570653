// Font smoothing
// @include font-smoothing(yes)

@mixin font-smoothing($value) {
  @if $value == yes{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @elseif $value == inherit {
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Font  Mixin
// @include font(serif), @include font(sans), @include font(freight)

@mixin font($font:sans, $weight:0, $style:none){
  @if($font == sans){
    @if($weight == -2){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == -1){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == 0){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == 1){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == 2){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == 3){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else if($weight == 4){
      font-family:'NeuzeitSLTW01-BookHeavy';
    } @else{
      font-family:'NeuzeitSLTW01-BookHeavy';
    }
    font-weight: 400;

  }
  @if($font == serif){
    font-family: 'Freight Big W01 Light Regular', serif;
    font-style: italic;
    font-weight: 400;
    html.system-windows & {
      font-weight: 600 !important;
    }
  }
  @if($font == uncombed){
    font-family: 'Despeinada W00 Regular';
    font-style: normal;
    font-weight: 400;
  }
  @if($font == freight-text){
    font-family: 'Freight Display W01 Book', serif;
    font-style: italic;
    font-weight: 400;
    html.system-windows & {
      font-weight: 600 !important;
    }

  }
  @if($font == freight){
    @if($style == none){
      font-family: 'Freight Big W01 Light Regular', serif;
      font-style: normal;
      font-weight: 400;
      html.system-windows & {
        font-weight: 600 !important;
      }
    } @else{
      font-family: 'Freight Big W01 Light Italic', serif;
      font-style: italic;
      font-weight: 400;
      html.system-windows & {
        font-weight: 600 !important;
      }
    }
  }
}

// Breakpoint Mixin
// @include breakpoint(small) {}

@mixin breakpoint($size){
  @each $point in $breakpoints{ // each loop
    @if($size == map-get($point, name)){ // checks size
      @media (min-width: rem(map-get($point, size)*1px)){@content;} // runs breakpoint
    }
  }
}
@mixin mobilebp(){
	@media (max-width: 767px){@content;}
}@mixin elevenbp(){
	@media (max-width: 1100px){@content;}
}

// Grid Mixin
@mixin col($columns, $grid:12, $padding:both, $lm: false, $rm: false){
  width: ((100/$grid) * $columns) + 0%;
  @if($padding == both){
    padding-left: rem(10px);
    padding-right: rem(10px);
  } @else if($padding == left){
    padding-left: rem(10px);
  } @else if($padding == right){
    padding-right: rem(10px);
  }
  @if($lm != false){
    margin-left: ((100/$grid) * $lm) + 0%;
  }
  @if($rm != false){
    margin-right: ((100/$grid) * $rm) + 0%;
  }
}

// Centering mixin
// @include center(v);

@mixin center($type){
  @if($type == v){
    top: 50%;
    transform: translateY(-50%);
  }
  @if($type == h){
    left: 50%;
    transform: translateX(-50%);
  }
  @if($type == vh){
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
    @if($type == false){
      top: auto;
      transform: none;
    }
}

// Max-width - instill max width on certain elements
// @include max-width();

@mixin max-width(){
  @include breakpoint(medium){
    max-width: rem($max-width+0px);
    margin-left: auto;
    margin-right: auto;
  }
}

// Min width for Case Studies
// @include min-width();

@mixin min-width(){
    min-width: rem(1040px);
}
