.HW-project-ctn{
	&{
		@extend %cf;
		@include max-width();
		padding: 0;
		margin: 0;
		list-style: none;
		overflow: hidden;
		text-align: center;
		@include breakpoint(medium){
			padding: 0 100px;
		}
	}
	li{
		width: 100%;
		@include breakpoint(medium){
			width: 50%;
			padding: 0 10px 20px 10px;
		}
	}

	li {
		@include breakpoint(medium){
			float: left;
		}
		.HW-project{
			@include breakpoint(medium){
				height: 600px;
			}
		}
	}

	// every third box
	li:nth-child(3n){
		@include breakpoint(medium){
			width: 100%;
			float: left;
		}
		.HW-project{
			@include breakpoint(medium){
				height: 385px;
			}
		}
	}
}

.HW-project{
	&{
		position: relative;
		display: block;
		text-decoration: none;
	}
	h1{
		margin: 0;
	}
	h2{
		margin: 0;
	}
	.HW-text{
		padding: 20px 20px 30px;
		border-left: 30px solid $c-dark;
		@include breakpoint(medium){
			position: absolute;
			bottom: -2px;
			left: 0;
			max-width: 80%;
			padding: 30px 20px 12px 0;
			border-left: none;
			background: $c-light;
		}
	}
	.HW-background-zoom-wrap{
		height: 250px;
		width: 100%;
		position: relative;
		display: block;
		overflow: hidden;
		@include breakpoint(medium){
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
		}
	}
	.HW-background{
		height: 100%;
		width: 100%;
		background-color: #eee;
		background-position: center center;
		background-size: cover;
		transition-duration: 0.3s;
	}
	&:hover .HW-background:not(.has-video) {
		@include breakpoint(medium){
		transform: scale(1.05);
		}
	}
	.HW-background video{
		display: none;
		@include breakpoint(medium){
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
	.HW-text h1{
		@include font(sans);
		text-align: left;
		margin-bottom: 10px;
		font-size: 40px;
		line-height: 1em;
		color: $c-dark;
		@include breakpoint(medium){
			font-size: 26px;
		}
	}
	.HW-text h3{
		@include font(sans);
        font-size: 14px;
        text-align: left;
        color: $c-dark;
	}
	.HW-text h2{
		font-size: 18px;
		text-align: left;
	}
}

.home-work .load-more-container{
	&{
		@extend %load-more;
	}
	.load-more-content-container{
		display: block;
		transition: .6s ease-in;
	}
	.load-more-content{
		display: block;
		opacity: 1;
		transition: .3s ease-out;
		transition-delay: .6s;
	}
	.load-more-trigger{
	    display: block;
	    width: 100%;
	    max-width: 390px;
	    margin: 40px auto 0;
	    text-transform: none;
	    letter-spacing: .02em;
	    font-size: 18px;
	    transition: .3s ease-out;
	    opacity: 1;
	    outline: none;
	}
	.load-more-trigger svg{
		position: relative;
		display: block;
		width: 20px;
		height: 20px;
		margin: 0 auto;
		fill: #E3E3E3;
		transition: .3s ease-out;
	}
	.load-more-trigger:hover svg{
		transform: rotate(180deg);
	}
	&.expanded .load-more-trigger{
		opacity: 0;
		transition-delay: .6s;
	}
}
