.press-logos{
	@include col(1,1,false);
}
.press-track{
	@extend %cf;
	text-align: center;
	@include breakpoint(small){
		@include col(10,12,both);
		margin: 0 auto;
		font-size: 0;
	}
}
.press-track > div {
	@include col(1,2,both);
	margin-bottom: rem(20px);
	text-decoration: none;
	float: left;
	@include breakpoint(small){
		display: inline-block;
		float: none;
		@include col(1,6,both);
	}
}
.press-item {
	width: 100%;
	height: 100%;
	text-decoration: none;
	display: block;
	transition: opacity .4s;
}
.press-item.unhover{
	opacity: .4;
}
.press-logo{
	display: block;
	width: rem(80px);
	height: rem(80px);
	margin: 0 auto rem(15px);
	border-radius: 100%;
	background-size: cover;
}
.press-title{
	text-align: center;
	font-size: rem(16px);
	color: $c-med-grey;
}