.relatedInspiration {
    @extend %birdwatch-outer;
    max-width: 81.25rem;
    border: none;

    @include breakpoint(medium) {
      width: 78%;
      margin: 80px auto 0;
    }
    h3 {
      @extend %birdwatch-title;
      color: $c-dark;
      max-width: 400px;
    }

    .inspiration-container {
        margin: 0 auto;
        @include breakpoint(medium) {
          width: 100%;
        }
    }

    .inspiration-link {
      display: block;
      background-size: cover;
      background-position: center center;
      margin: 20px 0 0;
    }

    .inspiration-thumbnail {
        width: 100%;
        height: auto;
        margin-bottom: 7px;
        opacity: 0;
        @include breakpoint(small) {
            width: 286px;
            vertical-align: top;
        }
        @include breakpoint(medium) {
        }
    }
}
.bw-2x1{
  .spacer{

  }
}
.bw-1x2{

}
.bw-1x1{

}

// MIGHT RETURN TO THIS FOR CUSTOM ENTRIES
// .birdwatchLandingContainer {
// 	margin: rem(80px) 0;
// 	// border-bottom: 1px solid $c-med-grey;
// 	@include breakpoint(medium) {
// 		margin: rem(50px) 0;
// 	}
//     @include breakpoint(max) {
//         margin: 50px auto;
//         max-width: 1200px;
//     }
// }

// a.birdwatchLandingEntry {
//     color: $c-dark;
// 	display: block;
//     border: 2px solid $c-dark;
//     padding: 20px;
//     width: 100%;
//     margin-right: 1%;
//     margin-bottom: 1%;
//     text-decoration: none;
//     @include breakpoint(small) {
//         width: 48%;
//         display: inline-block;
//         min-height: 260px;
//         vertical-align: top;
//     }
//     @include breakpoint(medium) {
//     	width: 32%;
//     }
//     &:hover {
//         background-color: rgba(0,0,0,.5);
//     }
// }
