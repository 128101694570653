%article-mobile{
	position: relative;
	z-index: 1;
	display: block;
	.JAL-image{
		@include col(1,1,both);
		height: 200px;
	  background-position: center;
	  background-size: cover;
	}
	.JAL-content{
		@include col(1,1,both);
		padding: 30px 10px 50px 20px;
		border-left: rem(30px) solid $c-dark;
		overflow: hidden;
		@include breakpoint(small){
			padding: 10px 10px 10px 30px
		}
	}
	.JAL-feature{
		display: none;
	}
	.JAL-title{
		@include font(sans, 3);
		font-style: normal;
		line-height: 1em;
		margin: rem(10px) 0;
		color: $c-dark;
		@include breakpoint(small){
			line-height: 2.625rem;
			font-size: 36px;
		}
		@include breakpoint(medium){
			font-size: 44px;
		}
	}
	.JAL-btn{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		z-index: 2;
		overflow: hidden;
	}
}
%article-display-1{
	.JAL-description {
		font-size: 20px;
	}
	@include breakpoint(small){
		display: flex;
		align-items: center;
		min-height: rem(450px);
		margin: rem(75px) 0;
		.JAL-image{
			@include col(1,2,false,1);
			position: absolute;
			top: 0;
			bottom: 0;
			height: auto;
		}
		.JAL-content{
			@include col(6,12,right,0);
			padding: rem(60px);
			border-left: none;
		}
		.JAL-feature{
			@include font(sans, 3);
			display: inline-block;
			padding-top: rem(18px);
			border-top: rem(2px) solid $c-global;
			color: $c-global;
			margin-bottom: rem(12px);
			text-transform: uppercase;
			letter-spacing: 0.2em;
			text-transform: uppercase;
			font-size: rem(14px);
		}
		.JAL-title{
			margin-bottom: rem(28px);
		}
		.JAL-description{
			letter-spacing: 0.5px;
			margin-bottom: rem(28px);
		}
		.JAL-btn{
			position: relative;
			opacity: 1;
			margin-left: rem(60px);
			overflow: hidden;
		}
	}
	@include breakpoint(medium) {
		.JAL-content{
			@include col(5,12,right);
		}
	}
}
%article-display-2{
	.JAL-description {
		font-size: 20px;
	}
	@include breakpoint(small){
		@include col(1,1,false);
		padding: rem(30px);
		.JAL-image{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
		}
		.JAL-content{
			@include col(9,12,false);
			padding: rem(50px);
			margin-top: rem(160px);
			border-left: none;
			background: $c-light;
		}
		.JAL-title{
			margin: 0 0 rem(10px);
		}
		.JAL-description{
			margin: 0;
			letter-spacing: 0.5px;
		}
		.underlined-button-new {
			padding-top: 10px;
		}
		.JAL-hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			&:hover .JAL-image:before {
				opacity: 0.6;
			}
		}
	}
}
%article-display-3{
	.JAL-description {
		font-size: 20px;
	}
	@include breakpoint(small){
		display: flex;
		align-items: center;
		min-height: rem(450px);
		margin: rem(75px) 0;
		.JAL-image{
			@include col(1,2,false);
			position: absolute;
			top: 0;
			bottom: 0;
			height: auto;
		}
		.JAL-content{
			@include col(6,12,right,6);
			padding: rem(60px);
			border-left: none;
		}
		.JAL-feature{
			@include font(sans, 3);
			display: inline-block;
			padding-top: rem(18px);
			border-top: rem(2px) solid $c-dark;
			margin-bottom: rem(36px);
			text-transform: uppercase;
			letter-spacing: 0.2em;
		}
		.JAL-title{
			margin-bottom: rem(28px);
		}
		.JAL-description{
			letter-spacing: 0.5px;
			margin-bottom: rem(28px);
		}
		.JAL-btn{
			position: relative;
			opacity: 1;
			margin-left: rem(60px);
			overflow: hidden;
		}
	}
	@include breakpoint(medium) {
		.JAL-content {
			@include col(5,12,right,7);
		}
	}
}
%article-display-4{
	.JAL-description {
		font-size: 20px;
	}
	@include breakpoint(small){
		@include col(1,1,false);
		padding: rem(30px);
		.JAL-image{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
		}
		.JAL-content{
			@include col(9,12,false);
			padding: rem(50px);
			margin-top: rem(160px);
			border-left: none;
			background: $c-light;
		}
		.JAL-title{
			margin: 0 0 rem(10px);
		}
		.JAL-description{
			margin: 0;
			letter-spacing: 0.5px;
		}
		.underlined-button-new {
			padding-top: 10px;
		}
		.JAL-hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			&:hover .JAL-image:before {
				opacity: 0.6;
			}
		}
	}
}
.journal-article-list{
	max-width: rem(1015px);
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(small) {
		margin-bottom: rem(65px);
	}
}
.JAL-article{
	@extend %shoot-up-element;
	@extend %article-mobile;
	margin: 3.75rem 0;
	&:nth-of-type(4n-3) {
		@extend %article-display-2;
	}
	&:nth-of-type(4n-2) {
		@extend %article-display-2;
	}
	&:nth-of-type(4n-1) {
		@extend %article-display-2;
	}
	&:nth-of-type(4n) {
		@extend %article-display-2;
	}
}
.journal-article-button{
	@extend %cf2;
	border-top: rem(1px) solid darken($c-light-grey, 10);
}
.JLH-content {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}
.SLH-header {
	font-size: 40px;
	margin: 0 0 10px;

	@include breakpoint(small) {
		font-size: 70px;
	}
}
.SLH-subheader {
	display: block;
	@include font(freight-text);
	font-size: rem(22px);
	line-height: rem(31px);
	letter-spacing: 0.04em;
	color: #fff;
	font-style: normal;
	margin: 0;
}
.JAL-image {
	@include breakpoint(medium) {
		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: $c-dark;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		&:hover:before {
			opacity: 0.6;
		}
	}
}

.JAL-hover:hover + .JAL-content a.underlined-button-new {
	@include breakpoint(medium) {
		&:after {
			width: 100%;
	  		transition: width 0.3s ease 0s;
		}
		svg {
			right: 0;
			opacity: 1;
			transition: opacity 0.3s ease 0.5s, right 0.3s ease 0.5s;
		}
		.rectangle {
			width: 23px;
			transition: width 0.3s ease 0.3s;
		}
	}
}

.JAL-paginate {
	& {
		padding: 40px 0;
		text-align: center;

		@include breakpoint(small) {
			padding: 70px 0 0;
		}
	}
	a, span {
		& {
			position: relative;
			display: inline-block;
			@include font(sans);
			font-size: 16px;
			color: $c-med-grey;
			padding: 5px 10px;
			text-decoration: none;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background-color: $c-dark;
				opacity: 0;
				transition: all 0.3s ease;
			}
			&.current {
				color: $c-dark;
				&:after {
					width: 100%;
					opacity: 1;
				}
			}
			@include breakpoint(medium) {
				transition: all 0.3s ease;
				&:hover {
					color: $c-dark;
					&:after {
						width: 100%;
						opacity: 1;
					}
				}
			}
		}
		&.prev {
			margin-right: 5px;

			@include breakpoint(small) {
				margin-right: 15px;
			}
		}
		&.next {
			margin-left: 5px;

			@include breakpoint(small) {
				margin-left: 15px;
			}
		}
		&.prev,
		&.next {
			color: $c-dark;
			padding: 5px 15px;
			border: 2px solid $c-dark;

			@include breakpoint(medium) {
				transition: all 0.3s ease;
				&:hover {
					color: $c-light;
					background-color: $c-dark;
				}
			}
		}
	}
}
