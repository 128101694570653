.work-case-study-hero video{
	display: none;
	@include breakpoint(small) {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1.1);
	}
}
.work-case-study-hero.shrink .WCSH-content .HHT-large-content,
.work-case-study-hero.shrink .WCSH-content .HHT-small-content {
	@include breakpoint(small) {
		opacity: 0;
	}
}

.work-case-study-hero.shrink .WCSH-content {
	width: 100%;

	@include breakpoint(small) {
		bottom: -100%;
	}
}

.WCSH- {
	&content .HHT-small-content {
		border-left: none;
	}
	&content .HHT-large-content,
	&content .HHT-small-content {
		opacity: 1;
		transition: opacity 0.4s ease-out;
		text-align: left;

		@include breakpoint(small) {
			text-align: center;
		}
	}
	&content {
		position: absolute;
		width: 100%;
		margin: 0;
		top: auto;
		bottom: 2%;
		padding: 0 rem(55px) 0 rem(10px);
		transform: translate(-50%, 0);

		@include breakpoint(small) {
			width: 95%;
			bottom: 10%;
			background-color: $c-light;
			padding: rem(60px) 0;
			transition: 0.5s ease-in;
		}
		@include breakpoint(medium) {
			padding: rem(60px) rem(40px);
		}
	}
	&header {
		color: $c-light;
		padding: 0;
		font-size: rem(48px);
		line-height: 1em;
		text-align: left;
		margin: 0 0 rem(10px);

		@include breakpoint(small) {
			color: $c-dark;
			font-size: rem(38px);
			padding-left: 20px;
		}
		@include breakpoint(medium) {
			font-size: rem(60px);
			padding-left: 0;
		}
	}
	&copy {
		color: $c-light;
		font-size: rem(18px);
		line-height: rem(24px);
		letter-spacing: 0.04em;
		margin: rem(10px) 0 0;
		text-align: left;

		@include breakpoint(small) {
			font-size: rem(20px);
			line-height: rem(32px);
			color: $c-dark;
			margin: 0;
		}
	}
}

.work-case-study-info {
	@include max-width();
	position: relative;
	z-index: 1;
	.HHT-large-content {
		display: none;

		@include breakpoint(small) {
			display: block;
			padding-left: 0;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		@include breakpoint(medium) {
			@include col(3,5,false);
			padding-left: rem(40px);
		}
	}
	.HHT-small-content {
		padding: 0 rem(10px);

		@include breakpoint(small) {
			padding: 0 0 0 rem(40px);
		}
		@include breakpoint(medium) {
			padding: 0 0 0 rem(80px);
			@include col(2,5,false, 3);
		}
	}

	@include breakpoint(small) {
		margin: rem(112px) auto;
	}
}

.page-content.visible .HHT-large-content {
	opacity: 1;
}

.WCSI-{
	&header {
		@include breakpoint(small) {
			margin: 0;
			@include font(sans, 3);
			color: $c-dark;
			font-size: rem(44px);
			&:after {
				content: "";
				display: block;
				height: 3px;
				width: 50px;
				margin: rem(20px) 0;
				background: $c-med-grey;
			}
		}
	}
	&copy {
		@include breakpoint(medium) {
			margin: 0;
			line-height: rem(33px);
			color: $c-dark;
		}
	}
	&view {
		@include font(freight, 0, italics);
		border-bottom: 1px solid $c-dark;
		color: $c-dark;
		text-decoration: none;
		transition: all 0.3s ease;
		&:hover {
			color: #EBEBEB;
			border-color: #EBEBEB;
		}
	}
	&press {
		border-bottom: 1px solid $c-med-grey;
		padding-bottom: rem(20px);

		@include breakpoint(small) {
			border-bottom: none;
		}
	}
	&awards {
		padding-top: rem(20px);
	}
	&link {
		padding-top: 40px;

		.link-track {
			padding-left: 73px;
		}

		@include breakpoint(small) {
			.link-track {
				padding-left: 30px;
			}
		}
		@include breakpoint(medium) {
			.link-track {
				padding-left: 73px;
			}
		}
	}
}

.WCSP- {
	&header {
		margin: 0;
		@include font(sans, 3);
		color: $c-dark;
		text-align: left;
		text-transform: uppercase;
		font-size: rem(10px);
		letter-spacing: 0.2em;
		&:after {
			content: "";
			display: block;
			height: 3px;
			width: 50px;
			margin: rem(5px) 0 rem(20px);
			background: $c-med-grey;
		}

		@include breakpoint(small) {
			margin: 0 0 rem(10px);
			&:after {
				display: inline-block;
				margin: 0 0 0 rem(15px);
				vertical-align: middle;
			}
		}
	}
	&service-ctn {
		margin: 0;
		padding-left: rem(20px);

		@include breakpoint(small) {
			padding-left: rem(30px);
		}
		@include breakpoint(medium) {
			padding-left: rem(80px);
		}
	}
	&service-type {
		color: $c-med-grey;
		font-size: rem(15px);
		letter-spacing: 0.05em;
		line-height: rem(24px);

		@include breakpoint(small) {
			list-style-type: none;
			@include font(sans, 3);
			font-size: rem(10px);
			letter-spacing: 0.15em;
			line-height: rem(24px);
			text-transform: uppercase;
			color: $c-dark;
		}
	}
	&logos .press-track {
		@include breakpoint(small) {
			padding: 0;
			width: 100%;
		}
	}
	&logo {
		display: inline-block;
		width: rem(40px);
		height: rem(40px);
		margin: 0 rem(10px);

		@include breakpoint(small) {
			display: none;
		}
	}
	&title-ctn {
		@extend %cf;
		width: 75%;
		display: inline-block;

		@include breakpoint(small) {
			width: 100%;
			display: block;
			padding-left: rem(30px);
		}
		@include breakpoint(medium) {
			padding-left: rem(73px);
		}
	}
	&logo-site {
		margin: 0;
		@include font(sans, 3);
		font-size: rem(10px);
		text-transform: uppercase;
		color: $c-dark;
		text-align: left;
		letter-spacing: 0.15em;
		line-height: rem(24px);

		@include breakpoint(small) {
			position: relative;
			display: inline-block;
			float: left;
			&:after {
				content: '';
				left: 0;
				bottom: 0;
				position: absolute;
				width: 0;
				height: 1px;
				background: $c-dark;
				transition: width 0.3s ease;
			}
			&:hover:after {
				width: 100%;
			}
		}
	}
	&logo-title {
		display: block;
		margin: 0;
		text-align: left;
		letter-spacing: 0.05em;
		line-height: rem(24px);
		@include font(freight, 0, none);
		text-transform: none;

		@include breakpoint(small) {
			display: inline-block;
			@include font(sans, 3);
			font-size: rem(10px);
			text-transform: uppercase;
			letter-spacing: 0.15em;
		}
		@include breakpoint(medium) {
			padding-left: rem(10px);
		}
	}
}

.WCSL- {
	&link {
		@include font(sans);
		text-decoration: none;
		color: $c-dark;
		margin: 0;
		font-size: 10px;
		text-align: left;
		letter-spacing: 0.2em;
		line-height: 24px;
		padding-bottom: 5px;
		position: relative;
		text-transform: uppercase;
	}

	@include breakpoint(small) {
		&link {
			&:after {
				content: '';
				left: 0;
				bottom: 0;
				position: absolute;
				width: 0;
				height: 1px;
				background: $c-dark;
				transition: width 0.3s ease;
			}
			&:hover:after {
				width: 100%;
			}
		}
	}
}

// Case Study Modules

// mobile case study code
@include mobilebp{

	.content-contain{
		overflow: hidden;
	}

	.isc_slideshow-container,
	.full-site-img,
	.full-site-iframe,
	.social-module .content,
	.video-carousel-module .content,
	.phase-module .text,
	.phase-module .phase,
	.statistic-module .text,
	.image-descriptor-module .text,
	.quote-module .text,
	.mobile-carousel-module .text,
	.mobile-carousel-module .slider-contain,
	.one-up-carousel-module .carousel-wrap,
	.fullsite-module .text,
	.fullsite-module .image,
	.pull-quote-module .text{
		padding: 50px !important;
	}
	.isc_slideshow-container{
		&{
			padding-bottom: 80px;
		}
		.slider-wrapper{
			width: 100%;
			margin-left: 0;
		}
		.prev-button-container,
		.next-button-container{
			bottom: -80px;
		}
		.next-button-container .button{
			margin-left: -32px;
		}
	}
	.full-site-img{
		padding: 50px;
	}
	.social-module .caption-img{
		width: 100% !important;
		margin-bottom: 20px;
	}
	.video-carousel-module{
		&{
			padding-bottom: 80px;
		}
		.slider-contain-2{
			&{
				padding-bottom: 80px;
			}
			.slider{
				width: 100% !important;
			}
		}
		.prev-button-container{
			top: auto !important;
			bottom: 0;
			left: 0;
			transform: translateY(0) !important
		}
		.next-button-container{
			top: auto !important;
			bottom: 0;
			right: 0;
			transform: translateY(0) !important;
		}
		.img{
			margin: 0 !important;
		}
	}
	.phase-module{
		&{}
		.phase .phase-ctn{
			padding: 20px 0 !important;
			padding-top: 65px !important;
			margin-left: 0 !important;
			width: 100% !important;
			height: auto !important;
		}
		svg{
			display: block !important;
			left: 50% !important;
			transform: translateX(-50%) rotate(90deg) !important;
		}
	}
	.statistic-module{
		&{
			background-image: none !important;
		}
		.stats-ctn{
		}
	}
	.image-descriptor-module {
		.right .text{
			padding: 10px !important;
		}
	}
	.quote-module{
		&{}
		.logo{
			display: none !important;
		}
		.link{
			float: none !important;
			margin-bottom: 20px;
		}
		.left{
			float: none !important;
			height: auto !important;
			width: 100% !important;
			margin: 0 0 20px !important;
		}
		.right{
			float: none !important;
			height: auto !important;
			width: 100% !important;
			margin: 0 0 20px !important;
		}
		.quote{
			font-size: 18px !important;
			line-height: 1.6em !important;
		}
	}
	.mobile-carousel-module{
		&{}
		.text{
			width: 100% !important;
			margin-left: 0 !important;
		}
		.slider-contain{
			padding-top: 80px !important;
		}
		.prev-button-container{
			top: 0 !important;
			left: 50px !important;
			height: 60px !important;
			width: 60px !important;
			transform: translateY(0) !important;
			margin-left: 0 !important;
		}
		.next-button-container{
			top: 0 !important;
			right: 50px !important;
			height: 60px !important;
			width: 60px !important;
			transform: translateY(0) !important;
			margin-left: 0 !important;
		}
		.mobile-frame{
			height: 420px;
		}
		.mobile-slider .slick-slide{
			filter: blur(0) !important;
			transform: scale(1) !important;
		}
		.slide-pager{
			display: none !important;
		}
		.slide-info{
			margin-top: 20px !important;
		}
		.slide-text{
			padding: 0 !important;
			margin: 0 !important;
			width: 100% !important;
		}
	}
	.one-up-carousel-module{
		&{}
		.text p{
			width: 100% !important;
		}
		.slider-contain{
			padding-top: 80px;
		}
		.prev-button-container,
		.next-button-container{
			top: 0 !important;
			transform: translateY(0) !important;
			height: 60px !important;
		}
		.slider{
			width: 100% !important;
		}
		.slide-pager-container{
			display: none !important;
		}
	}
	.fullsite-module{
		&{
			padding-bottom: 50px !important
		}
		.text{
			width: 100% !important;
			margin: 0 !important;
		}
		.image{
			width: 100% !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin: 0 !important;
		}
		.annotation-track{
			left: auto;
			right: 17px;
		}
		.annotation-track header{
			display: none !important;
		}
		.annotation-track  .track{
			opacity: 1 !important;
			top: 0 !important;
			background: transparent !important;
		}
		.annotation-track  .track:after{
			display: none !important;
		}
		.icon-title{
			display: none !important;
		}
		.annotation-open.active{
			transform: translateY(-50%) scale(1) !important;
		}
	}
	.annotation-container{
		&{
			position: fixed;
			top: 130px !important;
			left: 0 !important;
			right: auto !important;
			width: 100%;
			padding: 0 50px !important;
		}
		.video-annotation{
			position: relative !important;
			width: 100% !important;
		}
		.video-annotation .annotation,
		.video-annotation .video-container{
			float: none !important;
		}
		img{
			display: block !important;
			width: 100% !important;
		}
	}
	.pull-quote-module {
		&{}
		.left{
			width: 100% !important;
			float: none !important;
			padding-right: 0 !important;
		}
		.right{
			width: 100% !important;
			float: none !important;
			padding-left: 0 !important;
			margin-left: 0 !important;
		}
	}
}

// Repeating styles
%text-box{
	padding: 50px 100px;
}
%body{
	@include font(freight, none);
	font-size: 20px;
	line-height: 33px;
	letter-spacing: .05em;
}
%body--large{
	@include font(freight, none);
	font-size: 40px;
	line-height: 48px;
	letter-spacing: .04em;
}
%header{
	&{
		@include font(sans);
		font-size: 34px;
		line-height: 42px;
		margin: 0;

		@include breakpoint(small) {
			font-size: 24px;
			line-height: 32px;
		}

		@include breakpoint(medium) {
			font-size: 44px;
			line-height: 52px;
		}
	}
	&::after{
		content: "";
		display: block;
		width: 54px;
		height: 3px;
		margin: 30px 0;
		opacity: 0.5;
	}
}
%subheader {
	&{
		@include font(sans);
		font-size: 20px;
		line-height: 45px;
		letter-spacing: 0.02em;
		margin: 0;
	}
	&::before{
		content: "";
		display: block;
		width: 60px;
		height: 2px;
		margin-bottom: 20px;
	}
}
%image{
	position: relative;
	z-index: 2;
	box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
}
%slide-button{
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
	border: 1px solid #929292;
	transition: all 0.3s ease;
	&:hover {
		background-color: #929292;
		svg {
			fill: $c-light;
		}
	}
}
%svg-arrow{
	@include center(vh);
	position: absolute;
	display: block;
	width: 24px;
	fill: #929292;
	transition: all 0.3s ease;
}
%button-button{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 0;
}

.case-study-module{
	position: relative;
	z-index: 1;
	section.black {
		h1,
		h2,
		h3,
		.annotation-track header span,
		p {
			color: $c-dark;
		}
		h1:after,
		h2:before,
		h3:after {
			background-color: $c-dark;
		}
	}
	section.white {
		h1,
		h2,
		h3,
		.annotation-track header span,
		p,
		.slide-pager .current-slide,
		.slide-pager .total-slide {
			color: $c-light !important;
		}
		.slide-pager .current-slide,
		.slide-pager .total-slide {
			border-color: #fff;
		}
		h1:after,
		h2:before,
		h3:after {
			background-color: $c-light !important;
		}
	}
}

// Copy & Pull Quote Module
.pull-quote-module {
	.text{
		@extend %text-box;
		@include max-width();
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
	}
	.content{
		position: relative;
		margin-top: 30px;
		overflow: hidden;
	}
	.content .left{
		@extend %shoot-up-element;
		@include col(6,12,false);
		float: left;
		padding-right: 30px;
	}
	.content .right{
		@include col(6,12,false,6);
		padding-left: 30px;
	}
	.content .right p{
		margin: 0;
		@extend %body--large;
		@extend %shoot-up-element;
	}
}

// Full Website Module
.fullsite-module {
	& {
		margin-bottom: 100px;
	}
	.max-width{
		position: relative;
	}
	.text{
		@extend %text-box;
		padding-left: 0;
		padding-bottom: 150px;
		@include col(8,12,false,1);
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
		margin: 30px 0 10px;
	}
	.annotation{
		position: relative;
	}
	.image{
		@extend %shoot-left;
		@include col(8,12,false,1);
		position: relative;
		margin-top: -100px;
	}
	.image img{
		@extend %image;
		display: block;
		width: 100%;
		border-radius: 5px;
	}
	.image video {
		display: block;
		width: 100%;
	}
}

// One Up Image Carousel Module
.one-up-carousel-module{
	.carousel-wrap {
		@include max-width();
		@extend %text-box;
	}
	// Text stylings
	.text{
		margin-bottom: 50px;
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
		@include col(6,12,false);
	}
	// Slider contain
	.slider-contain{
		@extend %shoot-up-element;
		position: relative;
		width: 100%;
	}
	// Slider control buttons
	.slider-arrows:nth-of-type(2) {
		display: none;
	}
	.prev-button-container{
		@include col(1,12,false, 0);
		@include center(v);
		top: 30%;
		position: absolute;
	}
	.next-button-container{
		@include col(1,12,false,11);
		@include center(v);
		top: 30%;
		position: absolute;
	}
	.prev-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
	}
	.next-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
		right: 0;
	}
	.prev-button-container svg{
		@extend %svg-arrow;
	}
	.next-button-container svg{
		@extend %svg-arrow;
		transform: translate(-50%, -50%) rotate(180deg);
	}
	.prev-button-container button{
		@extend %button-button;
	}
	.next-button-container button{
		@extend %button-button;
	}
	// Slider
	.slider{
		@include col(9,12,false);
		margin: 0 auto;
	}
	.slider img {
		display: block;
		width: 100%;
	}
	.slider video {
		display: block;
		width: 100%;
		height: 100%;
	}
	.slide-pager-container{
		@include col(1,12,false,11);
		position: absolute;
		top: 70%;

		&:nth-of-type(2) {
			display: none;
		}
	}
	.slide-pager{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 80px;
		opacity: 0;
		transition: .3s;
	}
	.cycle-pager-active{
		opacity: 1;
	}
	.slide-pager .current-slide{
		font-size: 28px;
		color: $c-dark;;
		border: solid $c-dark;;
		border-width: 0 0 1px 1px;
		padding: 0 16px 16px;
	}
	.slide-pager .total-slide{
		font-size: 28px;
		color: $c-dark;;
		border: solid $c-dark;;
		border-width: 0 0 0 1px;
		padding: 16px 16px 0;
	}
	.captions{
		@extend %cf;
		overflow: hidden;
		@include max-width();
		padding-top: 45px;
	}
	%captions{
		@include col(5.5,12,false);
		float: left;
		>h2{
			@include font(sans);
			position: relative;
			font-size: 20px;
			line-height: 1em;
			letter-spacing: .02em;
			margin: 0;
			padding: 20px 0 0;
		}
		>h2::before{
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			display: block;
			width: 26px;
			height: 2px;
		}
	}
	.captions .left,
	.captions .right {
		@extend %captions;
	}
	.captions .left.full-width {
		width: 75%;
	}
	.captions .right {
		@include col(5,12,false, 1);
	}
	.captions p{
		@extend %body;
	}
}

// One Up Image Module
.one-up-image-module{
	&{
		position: relative;
	}
	.module-ctn {
		@include max-width();
		@extend %text-box;
	}
	.text{
		@include col(9,12,false);
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
	}
	.image-ctn{
		position: relative;
		z-index: 4;
		@include col(10,12,false);
		margin: 0 auto;
		@extend %shoot-up-element;
	}
	.image-ctn img{
		@extend %image;
		display: block;
		width: 100%;
	}
}

// Mobile Video Carousel Module
.mobile-carousel-module{
	&{
		position: relative;
	}
	.text{
		@include col(7,12,false, 1.5);
		padding: 50px 0 50px 50px;
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
		&:after {
			background-color: $c-dark;;
		}
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
	}
	// Slider contain
	.slider-contain{
		@extend %shoot-up-element;
		position: relative;
		width: 100%;
		overflow:hidden;
		padding-bottom: 100px;
		&.black {
			h3,
			p,
			.current-slide,
			.total-slide {
				color: $c-dark;
			}
			.current-slide {
				border: solid $c-dark;
				border-width: 0 1px 1px 0;
			}
			.total-slide {
				border: solid $c-dark;
				border-width: 0 1px 0 0;
			}
		}
		&.white {
			h3,
			p,
			.current-slide,
			.total-slide {
				color: $c-light;
			}
			.current-slide {
				border: solid $c-light;
				border-width: 0 1px 1px 0;
			}
			.total-slide {
				border: solid $c-light;
				border-width: 0 1px 0 0;
			}
		}
		&:after{
			content: "";
			position: absolute;
			top: 37%;
			bottom: 0;
			left: -40px;
			right: -40px;
			z-index: -1;
		}
	}
	// Slider control buttons
	.prev-button-container{
		@include col(1,12,false, 1);
		@include center(v);
		top: 30%;
		position: absolute;
	}
	.next-button-container{
		@include col(1,12,false,10);
		@include center(v);
		top: 30%;
		position: absolute;
	}
	.prev-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
	}
	.next-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
		right: 0;
	}
	.prev-button-container svg{
		@extend %svg-arrow;
	}
	.next-button-container svg{
		@extend %svg-arrow;
		  transform: translate(-50%, -50%) rotate(180deg);
	}
	.prev-button-container button{
		@extend %button-button;
	}
	.next-button-container button{
		@extend %button-button;
	}
	// Slider
	.mobile-slider{
		@include col(9,12,false, 1.5);
	}
	.mobile-frame{
		display: block;
		width: 100%;
		max-width: 320px;
		background: #ededed;
		padding: 40px 5px;
		border-radius: 30px;
	}
	.slick-center .mobile-frame {
		background: #b7b7b7;
	}
	.image {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.mobile-frame video {
		display: block;
		width: 100% !important;
		height: 100%;
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	.slick-center .mobile-frame video {
		opacity: 1;
	}
	.slider {
		@extend %shoot-up-element;
	}
	.slide-text{}
	.slide-text h3{
		@extend %header;
		font-size: 20px;
		line-height: 45px;
		letter-spacing: .04em;
	}
	.slide-pager-container{
		@include col(1,12,false,2);
		position: absolute;
		height: 100%;
	}
	.slide-pager{
		position: absolute;
		right: 0;
		width: 80px;
	}
	.slide-pager .current-slide{
		font-size: 28px;
		padding: 0 16px 16px;
	}
	.slide-pager .total-slide{
		font-size: 28px;
		padding: 16px 16px 0;
	}
	.slide-info{
		position: relative;
		width: 100%;
		margin-top: 70px;
	}
	.slide-text{
		@include col(7,12,false, 3);
		padding: 0 30px;
	}
	.slide-text h3{
		@extend %header;
		font-size: 20px;
		line-height: 45px;
		letter-spacing: .04em;
	}
	.slide-text p{
		@extend %body-copy !optional;
		font-size: 20px;
		line-height: 34px;
		letter-spacing: .04em;
	}
	.slide-text h3::after{
		display: none;
	}
	// Slick Styles
	.mobile-slider .slick-slide{
		transform: scale(.75);
		filter: blur(8px);
		opacity: 0;
		transition: .3s;
		outline: none;
	}
	.caption-slideshow .slick-slide {
		outline: none;
	}
	.mobile-slider .slick-active{
		opacity: 1;
	}
	.mobile-slider .slick-center{
		transform: scale(1);
		filter: blur(0);
	}
	// Slider styles
	.slick-track{
		z-index: 1;
	}
	.slide-pager-container{
		position: relative;
	}
	.slide-pager-container .slide-pager{
		top: 10px;
		left: 0;
		text-align: right;
	}
}

// Press Quote Module
.quote-module{
	&{ }
	.text{
		@extend %text-box;
		overflow: hidden;
		@include max-width();
	}
	h1{
		@extend %header;
		@extend %shoot-up-element;
		margin-bottom: 70px;
	}
	.left,
	.right{
		@extend %shoot-up-element;
		@include col(4,12,false);
		float: left;
	}
	.right{
		transition-delay: 0.3s;
		margin-left: 25%;
	}
	.logo{
		display: block;
		width: 150px;
		height: 40px;
		margin-bottom: 40px;
	}
	.quote{
		@include font(frieght);
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 30px;
	}
	.author{
		@include font(frieght);
		font-size: 20px;
		line-heigh: 40px;
	}
	.site{
		@include font(serif);
		font-size: 16px;
		line-heigh: 40px;
	}
	.link{
		font-size: 14px;
		margin-top: 30px;
		text-decoration: none;
		float: right;
	}
}

// Image Carousel w/ Descriptor Module
.image-descriptor-module {
	& {}
	.text {
		@extend %text-box;
		@include max-width();
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
		@include col(12, 12, false);
	}
	.bottom-ctn {
		@extend %cf;
		position: relative;
		margin-top: rem(50px);
	}
	.bottom-ctn p {
		font-size: 18px;
		line-height: 1.5em;
	}
	.content .left {
		@extend %shoot-up-element;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(8,12,false);
			float: left;
		}
	}
	.content .left img {
		width: 100%;
	}
	.content .right{
		@extend %cf;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(4,12,false,8);
			padding-left: rem(30px);
		}
	}
	.content .right .text {
		padding: 0;
		@extend %shoot-up-element;
		&:last-child {
			transition-delay: 0.3s;
		}

		@include breakpoint(medium) {
			&:first-child {
				margin-bottom: 50px;
			}
		}
	}
	.content .right .text h2 {
		@extend %subheader;
		line-height: 1em;
	}
}

// Statistics Module
.statistic-module {
	& {
		background-image: url('http://s3.amazonaws.com/thecharles2.0/uploads/work/et-stats.png');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 335px;
	}
	.text {
		@include max-width();
		@extend %text-box;
		@extend %cf;
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
	}
	.text > .left {
		@extend %shoot-up-element;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(1,2,false);
			float: left;
		}
	}
	.text > .left p {
		font-size: 18px;
	}
	.text > .right {
		@extend %shoot-up-element;
		transition-delay: 0.3s;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(1,2,false,1);
			padding-left: rem(66px);
		}
	}
	.text > .right p {
		margin: 0;
		font-size: 40px;
		line-height: 48px;
		letter-spacing: 0;
	}
	.stats {
		@extend %cf;
	}
	.stats .left {

		@include breakpoint(medium) {
			@include col(1,2,false);
			float: left;
		}
	}
	.stats .right {

		@include breakpoint(medium) {
			@include col(1,2,false,1);
			padding-left: rem(20px);
		}
	}
	.stats .stats-ctn {
		@extend %shoot-up-element;
		background-color: $c-light;
		padding: rem(20px) rem(32px);
		margin: rem(20px) 0;
	}
	.stats .right .stats-ctn {
		transition-delay: 0.3s;
		&:last-child {
			min-height: 121px;
		}
	}
	.stats h2 {
		@extend %subheader;
		@extend %shoot-up-element;
		text-align: center;
		&::before {
			display: none;
		}

		@include breakpoint(medium) {
			padding-left: rem(20px);
			text-align: left;
		}
	}
	.stats .right h2 {
		transition-delay: 0.3s;
	}
	.stats h3 {
		margin: 0;
		@include font(sans);
		text-transform: uppercase;
		font-size: 14px;
		line-height: 27px;
		letter-spacing: 0.2em;
		color: $c-dark;
	}
	.stats p {
		margin: 0;
		@extend %et-body !optional;
		font-size: 18px;
		line-height: 27px;
	}
}

// Phases Module
.phase-module {
	&{}
	.text {
		@include max-width();
		@extend %text-box;
		@extend %cf;
		padding-bottom: 100px;
	}
	.text h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.text p{
		@extend %body;
		@extend %shoot-up-element;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(8,12,false);
		}
	}
	.phase {
		@extend %cf;
		@include max-width();
	}
	.phase .phase-ctn {
		@extend %shoot-up-element;
		$delay: 0s;
		@for $i from 1 to 5 {
			&:nth-child(#{$i}) {
				transition-delay: $delay;
			}
			$delay: $delay + .3s;
		}
		position: relative;
		width: 48%;
		float: left;
		padding: rem(20px);
		text-align: center;
		min-height: 400px;
		margin-bottom: 2%;
		&:nth-of-type(2) {
			margin: 0 0 2% 2%;
			svg {
				display: none;
			}
		}
		&:nth-of-type(4) {
			margin-left: 2%;
		}
		&.black {
			h3,
			p {
				color: $c-dark;
			}
		}
		&.white {
			h3,
			p {
				color: $c-light;
			}
		}

		@include breakpoint(medium) {
			width: 24%;
			margin-bottom: 0;
			&:nth-of-type(2) {
				margin: 0 1.35%;
				svg {
					display: block;
				}
			}
			&:nth-of-type(4) {
				margin-left: 1.3%;
			}
		}
	}
	.phase-ctn h3 {
		text-align: left;
		@include font(sans);
	}
	.phase-ctn p {
		text-align: left;
	}
	.phase-ctn img {
		padding-top: 18px;
	}
	.phase-blocks {
		margin: 10px 0;
		background: $c-light;
		padding: 30px 0;
	}
	.phase-ctn:nth-of-type(2) .phase-blocks-ctn {

		@include breakpoint(max) {
			padding-top: 9px;
		}
	}
	svg {
		@extend %svg-arrow;
		position: absolute;
		top: 10px;
		left: -18px;
		width: 25px;
		z-index: 1;
		transform: none;
	}
	.phase-blocks p {
		margin: 0;
		@include font(sans);
		font-size: 14px;
		letter-spacing: 0.05em;
		text-align: center;
	}
}

// Infographic Module
.infographic-module {
	& {
		position: relative;
	}
	.content {
		@include max-width();
		@extend %text-box;
	}
	.text {
		@extend %cf;
	}
	.image {
		@extend %shoot-up-element;
		position: relative;
		z-index: 2;
		box-shadow: 0 0 40px rgba(0, 0,0, 0.1);
	}
	h3 {
		@extend %subheader;
	}
	p {
		@extend %body;
	}
	.left {
		@extend %shoot-up-element;

		@include breakpoint(medium) {
			@include col(1,2,false);
			float: left;
		}
	}
	.right {
		@extend %shoot-up-element;

		@include breakpoint(medium) {
			@include col(1,2,false, 1);
			padding: 0 20px;
		}
	}
	svg text,
	svg tspan {
		@include font(sans);
	}
	svg {
		position: relative;
		margin-top: -90px;
	}
	svg > g {
		opacity: 0;
		transition: opacity 0.2s ease;
	}
	svg > g {
		transform: translateY(30px);
		transition: opacity 0.2s ease, transform 0.2s ease;
		$delay: .2s;
		@for $i from 2 to 15 {
			&:nth-child(#{$i}) {
				transition-delay: $delay;
			}
			$delay: $delay + .2s;
		}
	}
	svg > g#CenteredCircle {
		position: absolute;
		circle {
			transform: scale(0);
			transform-origin: center;
			transition: transform 0.3s ease;
		}
	}
	.left.track-visible {
		svg > g {
			opacity: 1;
			transform: translateY(0);
		}
		svg > g#CenteredCircle circle {
			transform: scale(1);
		}
	}
	.image {
		@extend %shoot-up-element;
	}
	.image img{
		@extend %image;
		display: block;
		width: 100%;
	}
}

// Video & Image Carousel Module
.video-carousel-module {
	& {
		position: relative;
	}
	.content {
		@include max-width();
		@extend %text-box;
		position: relative;
	}
	.image {
		@extend %shoot-up-element;
	}
	.image video {
		width: 100%;
	}
	.slider-contain-2{
		position: relative;
		padding-top: 50px;
		overflow-x: hidden;
	}
	.slider-contain-2 .slider{
		position: relative;
		width: grid(10);
		z-index: 2;
		font-size: 0;
		white-space: nowrap;
		overflow: hidden;
	}
	.slider-contain-2 .button {
		background: transparent;
		width: 32px;
		height: 8px;
	}
	.img{
		@extend %shoot-up-element;
		$delay: 0s;
		@for $i from 0 to 7 {
			&:nth-child(#{$i}) {
				transition-delay: $delay;
			}
			$delay: $delay + .3s;
		}
		display: inline-block;
		height: 400px;
		margin: 0 10px;
		position: relative;
  		z-index: 2;
  		background-size: cover;
  		background-repeat: no-repeat;
	}
	.slider-contain{
		position: relative;
		width: 100%;
		padding-bottom: 50px;
	}
	.slider-contain::before{
		content: "";
		position: absolute;
		top: 50%;
		z-index: -1;
		display: block;
		height: 100%;
		width: 100%;
		background: #D5D4D4;
	}
	.prev-button-container.alt {
		@include center(v);
		top: 60%;
		position: absolute;
		z-index: 100;
		right: 59px;
	}
	.next-button-container.alt {
		@include center(v);
		top: 60%;
		position: absolute;
		z-index: 100;
		right: 0;
	}
	.slick-prev {
		display: none !important;
	}
	.prev-button-container.alt svg {
		@extend %svg-arrow;
		transform: translate(-50%, -50%);
		transition: none;
	}
	.next-button-container.alt svg {
		@extend %svg-arrow;
		transform: translate(-50%, -50%) rotate(180deg);
		transition: none;
	}
	.prev-button-container.alt .button {
		@extend %slide-button;
		@extend %shoot-up-element;
		transition-delay: 1.5s;
		cursor: pointer;
		&.track-visible {
			transition-delay: 0s;
			transform: translateY(-46%);
			@include mobilebp(){
				transform: translateY(0);
			}
		}
	}
	.next-button-container.alt .button {
		@extend %slide-button;
		@extend %shoot-up-element;
		transition-delay: 1.5s;
		cursor: pointer;
		&.track-visible {
			transition-delay: 0s;
			transform: translateY(-46%);
		}
	}
	.slider img{
		@extend %image;
		display: block;
		margin: 0 auto;
		width: 100%;
	}
	.slide-pager-container{
		@include col(1,12,false,10.5);
		position: absolute;
		bottom: 0;
	}
	.slide {
		width: 100%;
	}
}

// Social Module
.social-module {
	.content {
		@include max-width();
		@extend %text-box;
	}
	h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	p{
		@extend %body;
		@extend %shoot-up-element;
		@include col(12,12,false);

		@include breakpoint(medium) {
			@include col(8,12,false);
		}
	}
	.caption-content {
		@extend %cf;
	}
	.caption-img {
		@extend %shoot-up-element;
		$delay: .3s;
		@for $i from 0 to 4 {
			&:nth-child(#{$i}) {
				transition-delay: $delay;
			}
			$delay: $delay + .3s;
		}
		float: left;
		width: 30%;
		margin-right: 5%;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
	}
	.caption-img img,
	.caption-img video {
		width: 100%;
		height: 100%;
	}
}

// Material Slideshow Module
.material-slideshow-module{
	&{
		padding: 50px 0;
	}
	h1{
		@extend %shoot-up-element;
		@extend %header;
		width: grid(5);
	}
	p{
		@extend %body;
	}
	.content{
		@include max-width();
		padding: 0 100px;
	}
	.content p{
		@extend %shoot-up-element;
		width: grid(6);
	}
	.images{
		@extend %cf;
		@include max-width();
		padding-top: 45px;
	}
	.img-contain{
		display: inline-block;
		width: grid(5);
		&:nth-of-type(1) {
			@extend %shoot-up-element;
			float: right;
			margin-right: grid(.5);
		}
		&:nth-of-type(2) {
			float: left;
			margin-left: grid(1);
			margin-right: 10px;
		}
	}
	.img-contain p {
		@include font(serif);
		color: #616161;
	}
	.img{
		width:100%;
		display: block;
		height: 360px;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.slide-pager {
		@extend %shoot-up-element;
		height: 52px;
		margin: 10px 0;
		cursor: pointer;
		&:nth-of-type(1) {
			margin-top: 0;
		}
	}
	.current-slide {
		position: relative;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: #282828;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		&:hover:before {
			opacity: 0.6;
		}
	}
}

// Instagram Content Grid Module
.instagram-content-grid-module {
	&{
		@extend %text-box;
	}
	h1{
		@extend %shoot-up-element;
		@extend %header;
		width: grid(5);
		margin-left: grid(1);
	}
	h2{
		@extend %subheader;
	}
	p{
		@extend %body;
		width: grid(8);
		margin-left: grid(1);
	}
	.text {
		@include max-width();
	}
	.text p {
		@extend %shoot-up-element;
	}
	.gallery{
		position: relative;
		z-index: 2;
		width: 100%;
		margin-top: 60px;
	}
	.gallery-images{
		@extend %cf;
		@include max-width();
		position: relative;
		width: 100%;
		padding: 0 60px;
		margin: 0;
		list-style: none;
	}
	.gallery-images li{
		display: block;
		width: (100/3) * 1%;
		height: 360px;
		float: left;
		$delay: 0s;
		@for $i from 1 to 8 {
			&:nth-child(#{$i}) .img {
				transition-delay: $delay;
			}
			$delay: $delay + .3s;
		}
	}
	$pda: 30;
	.gallery li:nth-child(3n+1){
		padding-right: (($pda/3)*2) + 0px;
	}
	.gallery li:nth-child(3n+2){
		padding: 0 (($pda/3)*1) + 0px;
	}
	.gallery li:nth-child(3n+3){
		padding-left: (($pda/3)*2) + 0px;
	}
	.gallery li:nth-child(1n+4){
		margin-top: 30px;
	}
	.img{
		@extend %shoot-up-element;
		display: block;
		height: 100%;
		width: 100%;
	}
	.call-out{
		padding: 30px 60px 0;
		margin: 0;
	}
	.call-out-wrap {
		@include max-width();
		@extend %cf;
		padding: 0 60px;
	}
	.call-out .left{
		@extend %shoot-up-element;
		transition-delay: 0.2s;
		width: grid(6);
		float: left;
	}
	.call-out h2{
		width: 100%;
		margin-top: 0;
	}
	.call-out p{
		width: 100%;
		margin-left: 0;
	}
}

// Key Issues Module
.key-issues-module{
	&{
		@extend %text-box;
		@include max-width();
	}
	h1{
		@extend %header;
		@extend %shoot-up-element;
	}
	.content{
		@include col(6,12,false);
		@extend %shoot-up-element;
	}
	p{
		@extend %body;
	}
	.issues{
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: 50px 0 0;
		&.track-visible li {
			transform: translateY(0);
			opacity: 1;
		}
	}
	.issues li{
		opacity: 1;
		display: block;
		width: 33%;
		float: left;
		@include mobilebp(){
			width: 100%;
			padding: 0 !important;
			margin: 0 0 20px !important;
		}
		@include breakpoint(medium) {
			opacity: 0;
			transform: translateY(rem(50px));
			transition: transform .5s, opacity .5s;
			$delay: 0;
			@for $i from 0 to 7 {
				&:nth-child(#{$i}) {
					transition-delay: $delay;
				}
				$delay: $delay + .2s;
			}
		}
	}
	$box-padding: 24;
	.issues li:nth-child(3n+1){
		padding-right: ($box-padding/4)*3 + 0px;
	}
	.issues li:nth-child(3n+2){
		padding-left: ($box-padding/4)*1 + 0px;
		padding-right: ($box-padding/4)*1 + 0px;
	}
	.issues li:nth-child(3n+3){
		padding-left: ($box-padding/4)*3 + 0px;
	}
	.issues li:nth-child(1n+4){
		margin-top: $box-padding + 0px;
	}
	.issues li span{
		@include font(sans);
		display: block;
		padding: 30px 0;
		font-size: 12px;
		letter-spacing: .3em;
		text-align: center;
		text-transform: uppercase;
	}

}

// Case Study Footer
.work-case-study-footer {
	padding: 100px 0;
}

.work-case-study-new {
	text-align: center;
}
.WCSN- {
	&header {
		display: inline-block;
		margin: 0;
		@include font(sans);
		font-size: 36px;
		text-align: center;
		color: $c-dark;
	}
	&info {
		padding-top: 30px;
	}
	&info p {
		margin: 0;
		text-align: center;
		@include font(serif);
		font-style: normal;
		font-size: 22px;
		letter-spacing: 0.04em;
		line-height: 27px;
		color: $c-dark;
	}
	&info a {
		text-align: center;
		text-decoration: none;
		@include font(sans);
		color: $c-dark;
		font-size: 18px;
	}
}

.work-case-study-explore {
	padding-top: 120px;
}

.WCSE- {
	&header {
		margin: 0 0 20px;
		@include font(sans);
		font-size: 36px;
		text-align: center;
		color: $c-dark;
	}
	&wrapper {
		@extend %cf;
		@include max-width();
		padding: 0 40px;

		@include breakpoint(small) {
			padding: 0;
		}
	}
	&content {
		position: relative;
		width: 100%;
		float: left;
		padding-bottom: calc(100% - 80px);
		background-repeat: no-repeat;
		background-color: $c-dark;
		background-position: center;
		background-size: cover;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: $c-dark;
			opacity: 0.4;
			transition: opacity 0.3s ease;
		}
		&:hover:before {
			opacity: 0.8;
		}
		&:hover {
			.WCSE-strategy {
				opacity: 1;
				top: 45%;
				@include mobilebp{
					display: none;
				}
			}
			.WCSE-text h1 {
				top: 35%;
			}
			.WCSE-text svg {
				opacity: 1;
				@include mobilebp{
					display: none;
				}
			}
		}
		&:nth-of-type(2) {
			float: right;
		}

		@include breakpoint(small) {
			@include col(6,12,false);
			padding-bottom: 50%;
		}
		@include breakpoint(medium) {
			@include col(4,12,false);
			padding-bottom: 25%;
		}
	}
	&content .cover {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	&content.view-all {
		width: 100%;
		&:before {
			display: none;
		}
		&:hover {
			background-color: #555;
		}

		@include breakpoint(small) {
			padding-bottom: 25%;
			transition: all 0.3s ease;
		}
		@include breakpoint(medium) {
			width: 30%;
			margin-left: 1.666667%;
		}
	}
	&content.view-all &text {
		text-align: center;
	}
	&content.view-all h1 {
		left: 0;
	}
	&content.view-all:hover h1 {
		top: 50%;
		left: -25px;
	}
	&text {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0 0 0 40px;
	}
	&text h1 {
		@include font(sans);
		font-size: 34px;
		margin: 0;
		color: $c-light;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
  		transition: all 0.3s ease;
	}
	&text svg {
		  fill: white;
		  transform: rotate(180deg) translateZ(0);
		  width: 28px;
		  vertical-align: middle;
		  margin-left: 20px;
		  opacity: 0;
		  transition: opacity 0.3s ease;
	}
	&strategy {
		opacity: 0;
		position: absolute;
		top: 65%;
		transition: all 0.3s ease;
	}
	&strategy p {
		margin: 0;
		font-size: 18px;
		line-height: 30px;
		letter-spacing: 0.04em;
		color: $c-light;
	}
}
