.locked-content{
	position: fixed;
	overflow: hidden;
	z-index: 2;
	height: 100%;
	width: 100%;
	background: #fff;
}
.locked-form{
	&{
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 100;
	}
	.background{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: #fff;
		opacity: .8;
		z-index: 1;
	}
	.form-container{
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		width: 100%;
		max-width: 450px;
		padding: 30px 70px;
		transform: translate(-50%,-50%);
		background: white;
	}
	.text{
		margin: 30px 0;
		font-family: 'NeuzeitSLTW01-BookHeavy';
		font-size: 22px;
		font-weight: 400;
		letter-spacing: .025em;
		color: #333;
		text-align: center;
		@media only screen and (max-width: 900px){
			font-size: 14px;
		}
	}
	.locked-form-element{
		display: block;
		transition: .3s ease-out;
	}
	.locked-form-element.sent-state-changing{
		opacity: 0
	}
	.state-sent,
	.sent-successful .state-static{
		display: none;
	}
	.sent-successful .state-sent{
		display: block;
	}
	input{
		font-size: 14px;
		font-family: NeuzeitSLTW01-BookHeavy;
		font-weight: 400;
		letter-spacing: .025em;
		border-width: 0px 0px 1px;
		padding: 6px 0;
		margin: 0px 0px 10px;
		border-bottom-style: solid;
		border-bottom-color: #c9c9c9;
		width: 100%;
	}
	.button-contain .submit-button{
		&{
			display: block;
			width: 100%;
			padding: 22px;
			border: 0;
			border-radius: 0;
			margin: 30px 0 0;
			font-size: 14px;
			font-family: NeuzeitSLTW01-BookHeavy;
			font-weight: 400;
			letter-spacing: .025em;
			text-align: center;
			text-decoration: none;
			background: #000000;
			color: #fff;
			transition: .3s ease-out;
		}
		&:hover{
			background: #444;
		}
	}
}
