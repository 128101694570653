// structure - this should be the same for all footers
br.mobile-only {
	@media (min-width: 820px) {
		display: none;
	}
}
.unified-footer{
	&{
		display: block;
		width: 100%;
		padding: 60px 30px 10px;
		background: #282828;
		@media only screen and (min-width: 820px) {
			padding: 60px 60px 10px;
		}
		@media (min-width: 1100px){
			font-size: 0;
		}
	}
	.footer-wrapper{
		max-width: 1300px;
		margin: 0 auto;
	}
	.contact-information{
		&{
			@media (min-width: 1100px){
				display: inline-block;
				width: 60%;
				vertical-align: top;
			}
			@media (min-width: 1340px){
				width: 55%;
			}
		}
		.title h1{
			margin-top: 4px;
			@media (min-width: 820px){
				margin-bottom: 40px;
			}
			@media (min-width: 1100px){
				margin-bottom: 33px;
			}
		}
		.addresses{
			&{
				padding: 0;
				margin: 0;
				list-style: none;
				@media only screen and (min-width: 820px) {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-wrap: nowrap;
					    flex-wrap: nowrap;
					-webkit-box-pack: justify;
					    -ms-flex-pack: justify;
					        justify-content: space-between;
				}
			}
			li{
				margin: 0 0 30px;
				@media only screen and (min-width: 820px) {
					display: block;
				}
			}
			h3{
				&{
					position: relative;
					margin: 0;
				}
				.text{}
			}
			p{
				margin: 0;
			}
			a{
				&{
					display: inline-block;
					text-decoration: none;
				}
				&.underlined::after{
					content: "";
					display: block;
					width: 100%;
					margin-top: -3px;
				}
			}
		}
	}
	.social-information{
		&{
			position: relative;
			@media only screen and (min-width: 820px) {
				font-size: 0;
				margin-bottom: 80px;
			}
			@media (min-width: 1100px){
				display: inline-block;
				width: 40%;
				vertical-align: top;
				padding-left: 70px;
				margin-bottom: 60px;
			}
			@media (min-width: 1340px){
				width: 45%;
				padding-left: 120px;
			}
		}
		.social-icons{
			&{
				margin-bottom: 52px;
				@media only screen and (min-width: 820px) {
					display: inline-block;
					width: 55%;
					margin-bottom: 0;
					vertical-align: top;
				}
				@media (min-width: 1100px){
					width: 100%;
				}
			}
			h2{
					position: relative;
					margin: 0 0 14px;
					@media only screen and (min-width: 820px) {
						margin: 0 0 17px;
					}
			}
			.social-icons-list{
				&{
					display: block;
					list-style: none;
					padding: 0;
					margin: 0;
					font-size: 0;
				}
				li{
					display: inline-block;
					margin: 0 10px 0 0;
				}
				a{
					-webkit-transition: 0.3s ease-out;
					-o-transition: 0.3s ease-out;
					transition: 0.3s ease-out;
					position: relative;
					display: block;
					width: 30px;
					height: 30px;
					border-radius: 100%;
				}
				svg{
					-webkit-transition: 0.3s ease-out;
					-o-transition: 0.3s ease-out;
					transition: 0.3s ease-out;
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					    -ms-transform: translate(-50%, -50%);
					        transform: translate(-50%, -50%);
					display: block;
					width: 16px;
					height: 16px;
					fill: #eaeaea;
				}
			}
		}
		.mailing-list{
			&{
				display: block;
				margin-bottom: 130px;
				@media only screen and (min-width: 820px) {
					display: inline-block;
					width: 45%;
					vertical-align: top;
					margin-bottom: 0;
				}
				@media only screen and (min-width: 1100px) {
					width: 100%;
					margin-top: 40px;
				}
			}
			h2{
				margin: 0 0 7px;
				@media only screen and (min-width: 1100px) {
					margin: 0 0 7px;
				}
			}
			p {
				font-size: 12px;
			}
			.email-form{
				position: relative;
			}
		}
		.epm-sign-up-form {
			position: relative;
			label {
				display: none;
			}
			input {
				border-radius: 0;
				display: block;
				width: 100%;
				max-width: 100%;
				border: 0;
				margin: 0;
				border-bottom: 2px solid #FFF;
				background: transparent;
				color: #FFF;
				height: 40px;
				padding: 0;
				font-size: 16px;
				letter-spacing: 0.025em;
			}
			::-webkit-input-placeholder,
			::-moz-placeholder,
			:-ms-input-placeholder,
			:-moz-placeholder {
			  color: #b6b6b6;
			}
			.epm-sign-up-button {
				position: absolute;
				top: 0;
				right: 0;
				height: 40px;
				padding: 0;
				margin: 0;
				width: 20px;
				border: 0;
				outline: none;
				cursor: pointer;
				background: transparent;
			}
			svg{
				fill: #fff;
			}
		}
	}
	.brand-information{
		&{
			margin-bottom: 35px;
			@media (min-width: 1100px){
				margin-bottom: 10px;
			}
		}
		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			font-size: 0;
			text-align: center;
			@media (min-width: 820px) {
				max-width: 690px;
				margin: 0 auto;
			}
		}
		li{
			width: 50%;
			display: inline-block;
			@media (min-width: 820px){
				width: 25%;
			}
		}
		a{
			display: block;
			-webkit-transition: 0.3s ease-out;
			-o-transition: 0.3s ease-out;
			transition: 0.3s ease-out;
			height: 58px;
			@media (min-width: 820px) {
				height: 40px;
			}
			&:hover {
				svg {
					fill: #121212;
				}
			}
		}
		svg{
			-webkit-transition: 0.3s ease-out;
			-o-transition: 0.3s ease-out;
			transition: 0.3s ease-out;
			display: block;
			height: 100%;
			fill: #b1b1b1;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	.legal-information{
		&{
			text-align: center;
		}
		a{
			-webkit-transition: 0.3s ease-out;
			-o-transition: 0.3s ease-out;
			transition: 0.3s ease-out;
			color: #b1b1b1;
			text-decoration: underline;
			&:hover {
				color: #121212;
			}
		}
		section{
			display: block;
			font-size: 10px;
			@media only screen and (min-width: 820px) {
				display: inline-block;
				vertical-align: middle;
			}
		}
		span{
			display: inline-block;
			vertical-align: middle;
		}
		.meta{
			display: none;
			height: 9px;
			width: 1px;
			margin: 0 10px;
			background: #545454;
			@media only screen and (min-width: 820px) {
				display: inline-block;
				vertical-align: middle;
			}
		}
		.legal-links .meta{
			display: inline-block;
			vertical-align: middle;
		}
	}
	.content-collapse{
		.icon{
			position: absolute;
			top: 50%;
			right: 0;
			-webkit-transform: translateY(-50%);
			    -ms-transform: translateY(-50%);
			        transform: translateY(-50%);
			height: 16px;
			width: 16px;
			@media only screen and (min-width: 820px) {
				display: none;
			}
		}
		.target{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			border: 0;
			z-index: 2;
		}
		.icon::before{
			content: "";
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%) scaleY(1);
			    -ms-transform: translateX(-50%) scaleY(1);
			        transform: translateX(-50%) scaleY(1);
			display: block;
			height: 16px;
			width: 3px;
			background: #FFF;
			-webkit-transition: .6s ease-out;
			-o-transition: .6s ease-out;
			transition: .6s ease-out;
		}
		.icon::after{
			content: "";
			position: absolute;
			top: 50%;
			-webkit-transform: translateY(-50%);
			    -ms-transform: translateY(-50%);
			        transform: translateY(-50%);
			display: block;
			height: 3px;
			width: 16px;
			background: #FFF;
		}
		.content{
			height: 0;
			overflow: hidden;
			-webkit-transition: .6s ease-out;
			-o-transition: .6s ease-out;
			transition: .6s ease-out;
			-webkit-transition-delay: .3s;
			     -o-transition-delay: .3s;
			        transition-delay: .3s;
			@media only screen and (min-width: 820px) {
				height: auto !important;
			}
		}
		.content-inside{
			@media only screen and (max-width: 700px) {
				opacity: 0;
			}
			-webkit-transition: .3s ease-out;
			-o-transition: .3s ease-out;
			transition: .3s ease-out;
		}
		&.expanded .icon::before{
			-webkit-transform: translateX(-50%) scaleY(0);
			    -ms-transform: translateX(-50%) scaleY(0);
			        transform: translateX(-50%) scaleY(0);
		}
		&.expanded .content{
			-webkit-transition-delay: .0s;
			     -o-transition-delay: .0s;
			        transition-delay: .0s;
		}
		&.expanded .content-inside{
			opacity: 1;
			-webkit-transition: .3s ease-out;
			-o-transition: .3s ease-out;
			transition: .3s ease-out;
			-webkit-transition-delay: .6s;
			     -o-transition-delay: .6s;
			        transition-delay: .6s;
		}
	}
}

.unified-footer{
	.contact-information{
		.title h1{
			font-family: 'circular book', sans-serif;
			font-size: 30px;
			letter-spacing: .025em;
			line-height: 33px;
			color: #fff;
			@media only screen and (min-width: 1100px) {
				padding-bottom: 20px;
			}
		}
		.addresses{
			h3{
				font-family: 'circular book', sans-serif;
				font-size: 15px;
				letter-spacing: .070em;
				line-height: 30px;
				color: #fff;
			}
			p{
				font-family: 'circular book', sans-serif;
				font-size: 12px;
				letter-spacing: .070em;
				line-height: 21px;
				color: #fff;
			}
			a{
				&{
					color: #fff;
					transition: .3s ease-in;
				}
				&:after{
					background: #fff;
				}
				&:hover{
					opacity: .4;
				}
			}
		}
	}
	.social-information{
		h2{
			font-family: 'circular book', sans-serif;
			font-size: 20px;
			letter-spacing: .025em;
			line-height: 33px;
			color: #fff;
		}
		.email-signup{
			font-family: 'circular book', sans-serif;
			font-size: 16px;
			letter-spacing: .025em;
			line-height: 30px;
			text-transform: uppercase;
			color: #545454;
		}
		.email-signup{
			outline: none;
			color: #fff;
		}
		.social-icons .social-icons-list{
			a{
				background: #ffffff;
				transition: .3s ease-in;
			}
			a:hover{
				background: #282828 !important;
			}
			a svg{
				fill: #282828;
				transition: .3s ease-in;
			}
			a:hover svg{
				fill: #fff;
			}
		}
	}
	.brand-information{
		svg{
			transition: .3s ease-in;
			fill: #515151;
		}
		a:hover svg{
			fill: #FFF;
		}
	}
	.legal-information{
		&{
			font-family: 'circular book', sans-serif;
			font-size: 0;
			letter-spacing: .1em;
			line-height: 23px;
			color: #545454;
		}
		a{
			transition: .3s ease-in;
			color: #545454;
		}
		a:hover{
			color: #FFF;
		}
	}
}
