.image-lock-up-5{
	@extend %image-lock-up-ctn;
	@extend %cf2;
	@include breakpoint(small){
		position: relative;
		padding: 0;
		margin: rem(90px) 0;
	}
}
.ILU5{
	&-col{
		@include breakpoint(small){
			@include center(v);
			position: absolute;
			width: 100%;
		}
	}
	&-image{
		@extend %image-lock-up-mobile;
		@include breakpoint(small){
			@include col(7,12,true,1);
			height: auto;
			margin-bottom: 0;
			float: left;
			background-position: center;
			background-size: cover;
			&:before{
				padding-bottom: 66%;
			}
		}
	}
	&-text{
		margin: rem(60px) 0 0;
		@include breakpoint(small){
			@include col(3,12,false,8);
			height: auto;
			padding: 0 0 0 rem(40px);
			margin: 0;
			float: left;
		}
	}
}