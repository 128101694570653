.journal-click-slideshow{
	&{
		@extend %image-lock-up-ctn;
		@extend %cf2;
		@include col(12,12,false);
		@include max-width();
		position: relative;
		z-index: 1;
		overflow: visible;
		@include breakpoint(small){
			position: relative;
			padding: 0;
			margin-top: rem(90px);
			margin-bottom: rem(90px);
		}
	}
	button{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		opacity: 0;
	}
	.slide-container{
		position: relative;
		z-index: 1;
	}
	.slide{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
	img{
		display: block;
		width: 100%;
	}
}
