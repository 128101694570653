.nav-links {
	overflow-y: hidden;
	&::-webkit-scrollbar {
		width: 0;
	}
}
.nav-opening{
	body{
		overflow: hidden;
		@include breakpoint(medium){
			overflow: auto;
		}
	}
	.site-mobile-navigation{
		display: block;
		@include breakpoint(medium){
			display: none;
		}
	}
}
@keyframes hideOverflow {
	0% {overflow: hidden;}
	99% {overflow: hidden;}
	100% {overflow-y: auto;}
}
.nav-open{
	&{
		overflow: hidden;
		@include breakpoint(small){
			overflow: auto;
		}
	}
	.content-contain{
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease-out;
	}
	.site-mobile-navigation{
		opacity: 1;
	}
}
.page-loading{
	&{
		overflow: hidden;
	}
	.site-mobile-navigation{
		opacity: 0;
	}
	.left-nav{
		@include breakpoint(small){
			transform: translateX(0%) translateZ(0);
		}
	}
	.nav-links > a{
		@include breakpoint(small){
			transform: translateY(0) translateZ(0);
			opacity: 1;
			transition-delay: .3s;
			$delay: .3s;
			@for $i from 0 to 9 {
				&:nth-child(#{$i}) {
					transition-delay: $delay;
				}
				$delay: $delay + .1s;
			}
		}
	}
	.right-nav{
		transform: translateY(0) translateZ(0);
		opacity: 1;
		transition-delay: .3s;
		background-color: $c-light;
	}
	.social-links {
		@include breakpoint(small){
			transform: translateY(0) translateZ(0);
			opacity: 1;
			transition-delay: 1.1s;
		}
	}
}
.nav-close {
	.site-mobile-navigation {
		display: block;
	}
	.left-nav {
		transition-delay: 0.5s;
	}
	.social-links {
		@include breakpoint(small){
			transition-delay: .3s;
		}
	}
	.nav-links > a {
		@include breakpoint(small){
			transition-delay: .3s;
			$delay: .3s;
			@for $i from 0 to 8 {
				&:nth-child(#{$i + 8 - $i}) {
					transition-delay: $delay;
				}
				$delay: $delay + .1s;
			}
		}
	}
}
