// Modules - settings
@import "./modules/_functions.scss";
@import "./modules/_variables.scss";
@import "./modules/_mixins.scss";
@import "./modules/_placeholders.scss";

// Vendor
@import "./vendor/_fonts.scss";
@import "./vendor/_normalize.scss";
@import "./vendor/_animation.scss";
@import "./vendor/_prism.scss";
@import "./vendor/_slick.scss";
@import "./vendor/_lightbox.scss";
@import "./vendor/_unified-footer.scss";



// Patterns - default code and repeatable styles
@import "./patterns/_font-family.scss";
@import "./patterns/_grid.scss";
@import "./patterns/_global.scss";
@import "./patterns/_typography.scss";
@import "./patterns/_objects.scss";
@import "./patterns/_buttons.scss";
@import "./patterns/_structure.scss";

// Components - Small sections that either span sections or are not full pages
@import "./components/_loading-animation.scss";
@import "./components/_header.scss";
@import "./components/_mobile-navigation.scss";
@import "./components/_desktop-navigation.scss";
@import "./components/_footer.scss";
@import "./components/_press-logos.scss";
@import "./components/_slider.scss";
@import "./components/_half-half-text.scss";
@import "./components/_media-block-layout-1.scss";
@import "./components/_home-culture.scss";
@import "./components/_home-footer.scss";
@import "./components/_home-work.scss";
@import "./components/_article-share.scss";
@import "./components/_image-lock-up-1.scss";
@import "./components/_image-lock-up-2.scss";
@import "./components/_image-lock-up-3.scss";
@import "./components/_image-lock-up-4.scss";
@import "./components/_image-lock-up-5.scss";
@import "./components/_image-lock-up-slideshow.scss";
@import "./components/_image-lock-up-image.scss";
@import "./components/_image-lock-up-click-slideshow.scss";
@import "./components/_about-founders-team.scss";
@import "./components/_about-culture.scss";
@import "./components/_about-initiatives.scss";
@import "./components/_annotations.scss";
@import "./components/_privacy.scss";
@import "./components/_new-business-form.scss";
@import "./components/_thank-you.scss";
@import "./components/_work-shortform.scss";
@import "./components/_birdwatch-page-landing.scss";
@import "./components/_birdwatch-header.scss";
@import "./components/_birdwatch-subheader.scss";
@import "./components/_birdwatch-spotlight.scss";
@import "./components/_birdwatch-inspirations.scss";
@import "./components/_birdwatch-previous.scss";
@import "./components/_birdwatch-speaker-footer.scss";
@import "./components/_birdwatch-page-entry.scss";
@import "./components/_birdwatch-entry-slideshare.scss";
@import "./components/_birdwatch-entry-carousel.scss";
@import "./components/_birdwatch-entry-audio.scss";
@import "./components/_birdwatch-entry-video.scss";
@import "./components/_birdwatch-entry-gallery.scss";
@import "./components/_birdwatch-lightbox.scss";
@import "./components/_load-more.scss";
@import "./components/_work-filters.scss";
@import "./components/_locked-form.scss";

// Pages
@import "./layouts/_home-page.scss";
@import "./layouts/_about-page.scss";
@import "./layouts/_work-page.scss";
@import "./layouts/_work-case-study.scss";
@import "./layouts/_careers-page.scss";
@import "./layouts/_journal-list.scss";
@import "./layouts/_journal-single.scss";
@import "./layouts/_unordered-list.scss";
@import "./layouts/_contact-page.scss";
@import "./layouts/_process-page.scss";
@import "./layouts/_error-page.scss";
@import "./layouts/_new-case-studies.scss";
@import "./layouts/_mobile-case-studies.scss";

// States - state changes(ie open nav)
@import "./states/_open-navigation.scss";
@import "./states/_page-transition.scss";
@import "./states/_no-fixed-elements.scss";
@import "./states/_admin-bar-shown.scss";

//overrides
@import "./overrides/_xoxo_page.scss";


// Honey pot

#formerly-hp-sec{
	display: none;
}
