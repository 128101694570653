// Page border
%page-border{
	display: none;
	position: fixed;
	z-index: 9;
	width: rem(40px);
	height: rem(40px);
	background: $c-light;
	transition: $sideDrawLength ease-out;
	@include breakpoint(small){
		display: block;
	}
}
.PB-top{
	@extend %page-border;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(50px);
	z-index: 11;
}
.PB-right{
	@extend %page-border;
	top: 0;
	right: 0;
	height: 100%;
}
.PB-bottom{
	@extend %page-border;
	bottom: 0;
	left: 0;
	width: 100%;
}
.PB-left{
	@extend %page-border;
	top: 0;
	left: 0;
	height: 100%;
}
