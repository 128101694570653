.birdwatchSpotlight{
  &{
    @extend %birdwatch-outer;
    position: relative;

    @include breakpoint(medium) {
      margin-top: 50px;
    }
  }
  .spotlight-left-panel{
    margin-bottom: 20px;
    @include breakpoint(medium){
      width: grid(4.5);
      margin-bottom: 0;
    }
    @include breakpoint(max){
      margin: 0;
    }
  }
  .spotlight-right-panel{
    display: none;
    @include breakpoint(medium){
      display: block;
      position: absolute;
      padding-top: 60px;
      right: 0;
      top: 0;
      width: grid(7);
      height: 100%;
    }
  }
  .spotlight-right-panel .image-ctn{
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 100%;
  }
  .spotlight-right-panel .image{
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  .spotlight-left-panel h5 {
    @extend %birdwatch-subtitle;
    color: $c-dark;
  }
  .spotlight-left-panel h3{
    @extend %birdwatch-title;
    color: $c-dark;
    max-width: 400px;
  }
  .spotlight-left-panel p{
    margin: 20px 0 50px;
    font-family: 'Freight Big W01 Light Regular', serif;
    font-size: 16px;
    line-height: 22px;
    color: #848484;
    letter-spacing: 0.04em;

    @include breakpoint(medium) {
      margin-right: 28%;
    }
  }
  .spotlight-left-panel a{
    color: #282828;
    font-family: 'NeuzeitSLTW01-BookHeavy';
    font-weight: 600;
    text-decoration: none;
  }
  .spotlight-left-panel a .arrow-wrap{
    margin-top: -2px;
  }
  .spotlight-image {
      width: 100%;
  }
}
