%btn-base{
	@include font(sans, 3);
	@include font-smoothing(yes);
	display: inline-block;
	font-size: rem(12px);
	letter-spacing: .8em;
	text-transform: uppercase;
	text-decoration: none;
	background: transparent;
	border: 0;
	padding: rem(25px) rem(37px) rem(25px) rem(45px);
}
.black-lines-btn{
	@extend %btn-base;
	position: relative;
	z-index: 1;
	border: rem(1px) solid $c-dark;
	color: $c-dark;
	transition: border-color 0.3s, color 0.3s;
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	overflow: hidden;

	&::before{
		content: "";
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 120%;
	  background: $c-dark;
	  z-index: -1;
	  transform: rotate3d(0, 0, 1, 34deg) translate3d(-40px, 88px, 0);
	  transform-origin: 0% 100%;
		transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
	}

	&:hover{
		color: $c-light;
		&::before{
			transform: rotate3d(0, 0, 0, 0deg) translate3d(0, 0px, 0);
		}
	}
}
.white-lines-btn{
	@extend %btn-base;
	border: rem(1px) solid $c-light;
	color: $c-light;
}
.block-white-btn{
	display: inline-block;
	padding: 20px;
	width: 300px;
	margin: 70px auto 0;
	text-align: center;
	text-decoration: none;
	color: $c-dark;
	background: $c-light;
	border: 1px solid $c-dark;
	transition: all 0.3s ease;
	font-size: 30px;
	@include font(sans);

	@include breakpoint(medium) {
		width: 400px;
		&:hover {
			color: $c-light;
			background-color: $c-dark;
		}
	}
}

// Slider arrow btns
%btn-arrow{
	@extend %btn-base;
	position: absolute;
	width: rem(60px);
	height: rem(60px);
	padding: 0;
	outline: none;
	overflow: hidden;
	border: rem(1px) solid;
	.svg-ctn,
	.offset-svg-ctn{
		position: absolute;
		display: block;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}
	&:hover{
		.svg-ctn,
		.offset-svg-ctn{
			transition: .3s ease-out;
		}
	}
	svg{
		@include center(vh);
		position: absolute;
		width: rem(20px);
		stroke-width: .5px;
	}
	@include breakpoint(small){
		width: rem(80px);
		height: rem(80px);
	}
	@include breakpoint(max){
		width: rem(100px);
		height: rem(100px);
	}
}
%left-arrow{
	.svg-ctn{
		transform: translateX(0%);
	}
	.offset-svg-ctn{
		transform: translateX(100%);
	}
	&:hover{
		.svg-ctn{
			transform: translateX(-100%);
		}
		.offset-svg-ctn{
			transform: translateX(0%);
		}
	}
}
%right-arrow{
	right: 0;
	.svg-ctn{
		transform: rotate(180deg) translateX(0%);
	}
	.offset-svg-ctn{
		transform: rotate(180deg) translateX(100%);
	}
	&:hover{
		.svg-ctn{
			transform: rotate(180deg) translateX(-100%);
		}
		.offset-svg-ctn{
			transform: rotate(180deg) translateX(0%);
		}
	}
	svg{
			@include center(vh);
	}
}
%white-arrow-btn{
	@extend %btn-arrow;
	border-color: $c-light;
	color: $c-light;
	svg{
		fill: $c-light;
		stroke: $c-light;
	}
}
%grey-arrow-btn{
	@extend %btn-arrow;
	border-color: $c-med-grey;
	color: $c-med-grey;
	svg{
		fill: $c-med-grey;
		stroke: $c-med-grey;
	}
}
%black-arrow-btn{
	@extend %btn-arrow;
	border-color: $c-dark;
	color: $c-dark;
	svg{
		fill: $c-dark;
		stroke: $c-dark;
	}
}
.white-left-arrow{
	@extend %white-arrow-btn;
	@extend %left-arrow;
}
.white-right-arrow{
	@extend %white-arrow-btn;
	@extend %right-arrow;
}
.grey-left-arrow{
	@extend %grey-arrow-btn;
	@extend %left-arrow;
}
.grey-left-arrow{
	@extend %grey-arrow-btn;
	@extend %right-arrow;
}
.black-left-arrow{
	@extend %black-arrow-btn;
	@extend %left-arrow;
}
.black-right-arrow{
	@extend %black-arrow-btn;
	@extend %right-arrow;
}

// Scroll Button
.btn-scroll{
	@include center(h);
	position: absolute;
	display: block;
	height: 50px;
	z-index: 100;
	background-color: #fff;
	animation: scroll-up 0.5s ease both;
	animation-delay: 3s;
	transform: translateX(-50%);
}
.btn-scroll-w-text{
	@include font(sans, 3);
	@include font-smoothing(no);
	height: 50px;
	.BS-ctn{
		height: auto;
		width: auto;
    padding: 17px 20px;
		font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .2em;
		color: $c-dark;
	}
}

@keyframes scroll-up {
	0% {
		bottom: -10%;
	}
	100% {
		bottom: 0;
	}
}
.BS-text{
	@include font(sans, 3);
	@include font-smoothing(no);
	display: block;
	margin-bottom: rem(20px);
	color: $c-light;
	font-style: normal;
	font-size: rem(18px);
	letter-spacing: .04em;
	line-height: rem(18px);
}
.BS-ctn {
	position: relative;
	display: block;
	width: 70px;
	height: 50px;
	overflow: hidden;
	opacity: 1;

}
.arrow-leg {
    position:absolute;
    left: 50%;
    width: 1px;
    height: 0;
    background-color: #282828;
    transform: translateX(-50%) translateY(25px);
    transition: height 0.3s ease;
}
.arrow-down {
    position: absolute;
    left: 50%;
    top: calc(50% - 3px);
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #282828;
	animation: in 0.3s linear both;
 	animation-delay: 3.5s;
    transition: all 0.3s ease;
}
@keyframes in {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-50%) translateZ(0);
	}
	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(50%) translateZ(0);
	}
}
.BS-btn{
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 10px;
	border: 0;
	margin: 0;
	background: none;
	outline: none;
	&:before {
		position: absolute;
		content: '';
		height: 0;
		bottom: 100%;
		left: 0;
		width: 100%;
		background-color: #fff;
		transition: all 0.3s ease;
	}
	@include breakpoint(medium) {
		&:hover {
			&:before {
				height: 5px;
			}
			+ span > {
				.arrow-down {
	                top: calc(50% + 17px);
	            }
	            .arrow-leg {
	                height: 21px;
	            }
			}
		}
	}
}

// back and next article buttons
%article-btns{
		@include col(1,2,both);
		@include font(sans, 3);
		position: relative;
		display: block;
		float: left;
		padding: rem(70px) rem(10px);
		font-size: rem(13px);
		letter-spacing: .4em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		background: $c-light;
		color: $c-dark;
		transition: all .3s;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($c-dark, 0.5);
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		&:hover:before{
			@include breakpoint(medium) {
			opacity: 1;
			}
		}
		> svg{
			width: 25px;
			height: 19px;
			display: block;
			fill: $c-dark;
			stroke: $c-dark;
			stroke-width: .5px;
			vertical-align: bottom;
			transition: all 0.3s ease;
		}
		img {
			display: none;
			@include breakpoint(medium){
				display: block;
				position: absolute;
				width: 100%;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
		span {
			text-transform: none;
			font-size: 18px;
			letter-spacing: 0;
			position: relative;
			z-index: 2;
			transition: color 0.3s ease;
			position: absolute;
	    top: 55%;
	    left:50%;
	    display: block;
	    width: 100%;
	    transform: translate(-50%,-50%);
			@include breakpoint(medium){
				position: relative;;
		    top: 0;
		    left: 0;
		    display: inline-block;
		    width: auto;
		    transform: none;
			}
		}

		@include breakpoint(medium) {
			font-size: rem(14px);
			> svg {
		    display: inline-block;
			}
		}
		@include breakpoint(medium) {
			img {
				opacity: 0;
				transition: opacity 0.3s ease;
			}
			&:hover {
				border: 0;
				img {
					opacity: 1;
				}
				> svg {
					fill: #fff;
				}
				span {
					color: #fff;
				}
			}
		}
}
.button-prev{
	@extend %article-btns;
	border-left: solid rem(1px) darken($c-light-grey, 10);
	border-right: solid rem(1px) darken($c-light-grey, 10);
	> svg{
		position: absolute;
	    top: 25px;
	    left: 50%;
	    transform: rotate(180deg) translate(50%,0);

		@include breakpoint(small) {
			margin-right: rem(40px);
		}
		@include breakpoint(medium){
			transform: rotate(180deg);
			position: relative;
			top: 0;
			left: 0;
		}
	}
}
.button-next{
	@extend %article-btns;
	border-right: solid rem(1px) darken($c-light-grey, 10);
	> svg{
		position: absolute;
    	top: 25px;
		left: 50%;
		transform: rotate(0deg) translate(-50%,0);

		@include breakpoint(small) {
			margin-left: rem(36px);
		}
 		@include breakpoint(medium){
			transform: rotate(0deg);
			position: relative;
			top: 0;
			left: 0;
		}
	}
}
.underlined-button{
	@include font(sans);
	display: inline-block;
	position: relative;
	font-size: 16px;
	letter-spacing: 0;
	text-decoration: none;
	color: $c-dark;
	background: none;
	border: none;
	svg{
		display: inline-block;
		width: 25px;
		height: 20px;
		vertical-align: middle;
		margin-left: 20px;
		fill: $c-dark;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: #8B8B8B;
		transition: width 0.3s ease;
	}

	@include breakpoint(medium) {
		transition: color 0.3s ease;
		svg {
			fill: #8B8B8B;
			opacity: 0;
			transition: opacity 0.3s ease 0s;
		}
		&:after {
			width: calc(100% - 45px);
		}
		&:hover {
			color: #8B8B8B;
			svg {
				opacity: 1;
				transition: opacity 0.3s ease 0.3s;
			}
			&:after {
				width: 100%;
			}
		}
	}
}

.underlined-button-new {
	@include font(sans);
	display: inline-block;
	font-size: 14px;
	letter-spacing: 0;
	text-decoration: none;
	color: $c-dark;
  position: relative;
  text-decoration: none;
  color: black;
  &:after {
  	content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: $c-dark;

    @include breakpoint(medium) {
    	width: calc(100% - 50px);
    	transition: width 0.3s ease 0.3s;
    }
  }
  .arrow-wrap {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
	}
	svg {
	    position: absolute;
	    right: 2px;
	    width: 4.5px;
	    height: 8px;
	    top: 10px;
	    fill: $c-dark;

	    @include breakpoint(medium) {
	    	opacity: 0;
	    	transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	    }
	}

	.rectangle {
	    height: 2px;
	    background-color: black;
	    position: absolute;
	    top: 12.5px;
	    left: 23px;
	    width: 23px;

	    @include breakpoint(medium) {
	    	width: 0;
	    	transition: width 0.3s ease 0.3s;
	    }
	}
	&.white {
		color: $c-light;
		&:after {
			background-color: $c-light;
		}
		svg {
			fill: $c-light;
		}
		.rectangle {
			background-color: $c-light;
		}
	}
	&.center-btn-text {
		margin-left: 50px;
	}
	@include breakpoint(medium) {
		font-size: 16px;
	}
	@include breakpoint(medium) {
		&:hover {
			&:after {
		    	width: 100%;
		    	transition: width 0.3s ease 0s;
		    }
			svg {
			    right: 0;
			    opacity: 1;
			    transition: opacity 0.3s ease 0.5s, right 0.3s ease 0.5s;
			}
			.rectangle {
				width: 23px;
				transition: width 0.3s ease 0.3s;
			}
		}
	}
}

.white-underlined-button{
	&{
		@extend .underlined-button-new;
		color: $c-light;
	}
	&::after{
		background-color: $c-light;
	}
	svg{
		fill: $c-light;
	}
	.rectangle{
		background-color: $c-light;
	}
}
