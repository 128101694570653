.birdwatchGallery{
  @extend %birdwatch-entry-outer;
	@extend .relatedInspiration;
	margin: 80px auto;

  &.holidayParty .intro h2 {
  	margin-bottom: -5px;
  	&:after {
  		margin: 17px 0 0;
  	}
  }
	a{
		position: relative;
	}
	.hover-changed{
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 100%;
		width: 100%;
		opacity: 0;
		background-size: cover;
		background-position: center;
		transition: .3s ease-out;
	}
	a:hover .hover-changed{
		opacity: 1;
	}
}
