.journal-slideshow{
	&{
		@extend %image-lock-up-ctn;
		@extend %cf2;
		margin-bottom: 30px;
		@include breakpoint(small){
			position: relative;
			padding: 0;
			margin-top: rem(90px);
			margin-bottom: rem(90px);
		}
	}
	@media only screen and (max-width: 900px) {
		.one-up-carousel-module .carousel-wrap{
			padding-left: 0 ;
			padding-right: 0;
		}
		.one-up-carousel-module .slider{
			width: 100%;
		}
		.slide-pager-container{
				&{
					top: -30px !important;
					left: 50%;
					transform: translateX(-50%);
					width: 200px !important;
					margin-left: 0 !important;
					text-align: center;
				}
				.slide-pager{
					position: relative;
					display: inline-block;
					margin: 0 10px;
					height: 12px;
					width: 12px;
					background: #121212;
					overflow: hidden;
					border-radius: 100%;
					opacity: .5;
				}
				.cycle-pager-active{
					opacity: 1;
				}
				.current-slide,
				.total-slide{
					display: none;
				}
		}
		.slide{
			width: 100%;
		}
		.slider-arrows{
			display: none;
		}
	}
}
