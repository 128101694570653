.xoxo-content{
	&{}
	.error-ctn{
		background: transparent;
	}
	.hero-image{
		background-position: center;
		background-size: cover;
	}
	.underlined-button:hover{
		color: #282828 !important;
	}
	.underlined-button:hover svg{
		fill: #282828 !important;
	}
	.underlined-button:after{
		background: #282828 !important;
	}
}
