.previousEvents {
	@extend %birdwatch-outer;
    max-width: 81.25rem;
    border: none;

	.intro {
		display: block;
		width: 100%;
	}
	.intro h2 {
      @extend %birdwatch-title;
      color: $c-dark;
    }

    .previous-events-container {
    	@extend %cf;
    	display: block;
    	width: 100%;
    }

    .pec-block {
		@include mobilebp(){
			height: auto !important;
			margin-bottom: 120px;
		}
			margin-bottom: 20px;
    	@include breakpoint(medium) {
    		width: grid(1,3);
			padding: 0 (((70/4) * 1) + 0px);
			margin-bottom: 60px;
    		float: left;
    	}
			&:nth-child(3n+3){
		    	@include breakpoint(medium) {
					padding-left: ((70/4) * 2) + 0px;
					padding-right: 0;
				}
			}
			&:nth-child(3n+1){
		    	@include breakpoint(medium) {
					padding-left: 0;
					padding-right: ((70/4) * 2) + 0px;
				}
			}
    }

    .postro{
    	display: block;
    	width: 100%;
    }
	.postro .image{
		display: block;
		height: 250px;
		width: 100%;
		background-size: cover;
		background-position: center;
	}
	.postro h2{
		color: black;
		font-family: 'NeuzeitSLTW01-BookHeavy';
		font-size: 20px;
		margin: 36px 0 16px;
	}
	.postro p{
		color: #848484;
		font-family: 'Freight Big W01 Light Regular', serif;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.04em;
	}
}
