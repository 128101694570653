// hero image
.hero{
	position: relative;
	display: block;
	width: 100%;
	z-index: 1;
}
.header-change{
	@include breakpoint(small) {
		will-change: height;
		transition: .3s ease-out;
	}
}
.shrink{
	@include breakpoint(small) {
		height: 510px !important;
		transition: .3s ease-out;
		overflow: hidden;
	}
}

.hero-image{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
}
.hero-image iframe,
.hero-image video{
	display: none;

	@include breakpoint(medium) {
		display: block;
		position: absolute;
		@include center(vh);
		pointer-events: none;
	}
}

#hero-image-canvid{
	width: 100%;
	height: 100%;
	background-color: #282828;
	img {
		display: none;
	}
	@include breakpoint(medium) {
		display: none;
	}
}

#hero-image-canvid .canvid{
	height: 100%;
}

.mobile-hero-image{
	@extend .hero-image;
	z-index: 0;
	@include breakpoint(small){
		display: none;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .1;
	}
}
.hero-content{
	@extend %center-vh;
	position: absolute;
	z-index: 2;
	text-align: center;
}
.hero-video-ctn{
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.hero-video-ctn > iframe,
.hero-video-ctn > video{
	display: none;

	@include breakpoint(medium) {
		@include center(vh);
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}
