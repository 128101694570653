.bw-lightbox-ctn{
	@extend %bw-carousel;
	&{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 8;
		height: 100%;
		width: 100%;
		padding: 100px 20px;
		background: rgba(#fff, .9);
		opacity: 0;
		transition: .3s;

		@include breakpoint (medium) {
			padding: 40px;
		}
	}
	.bw-lightbox{
		position: relative;
		max-width: 800px;
		height: 100%;
		z-index: 2;
		transition: .3s;
		transform: translateY(50px);

		@include breakpoint(medium) {
			margin: 100px auto;
		}
	}

	// exit button
	.exit-lightbox{
		position: absolute;
		right: 0;
		top: -46px;
		display: block;
		width: 30px;
		height: 30px;
		background: transparent;
		border: none;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		padding: 0;
		z-index: 300;

		@include breakpoint(small) {
			right: 37px;
		}

		@include breakpoint(medium) {
			right: 97px;
		}
	}
	%bars{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		height: 2px;
		width: 100%;
		background: $c-dark-grey;
	}
	.exit-lightbox::before{
		@extend %bars;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	.exit-lightbox::after{
		@extend %bars;
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	// Large Exit Button
	.large-exit-lightbox{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0;
	}
}
.lightbox-ready{
	.bw-lightbox-ctn{
		display: block;
	}
}
.lightbox-active{
	&{
		overflow: hidden;
	}
	.bw-lightbox-ctn{
		opacity: 1;
	}
	.bw-lightbox{
		transform: translateY(0);
	}
}
.lightbox-remove{
	.bw-lightbox{
		transform: translateY(0);
	}
}
