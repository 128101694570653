.home-hero.shrink .link-holder {
	display: none;
}

// Hero section
.home-hero {
	&{
		height: calc(100vh - 5rem);
		overflow: hidden;
	}
	.featured{
		@include font(sans);
		font-size: 20px;
		margin: 10px 0;
		letter-spacing: .8px;
		color: $c-light;
	}
	.HH-header{
		margin-top: 0;
		margin-bottom: 0;
		@include font-smoothing(yes);
		@include breakpoint(medium) {
			width: 650px;
		}
	}
}
.hero-content{
	@include max-width();
	@include font-smoothing(yes);
	width: 90%;
	@include breakpoint(medium) {
		width: auto;
	}
}
.home-slider{
	&{
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: .3s ease-out;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -2;
		background: #fff;
	}
	.slide{
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 1;
	}
	.slide:nth-child(1){
		z-index: 2;
	}
	.caption{
		@include center(vh);
		top: 50%;
		top: calc(50% - 25px);
		position: absolute;
		display: block;
		width: 100%;
		padding: 0 20px;
	}
	.caption h1{
		@include font(sans);
		font-size: 44px;
		text-align: center;
		margin: 0 0 10px;
		color: #fff;
		@include breakpoint(small){
			font-size: 68px;
		}
	}
	.caption p{
		font-size: 22px;
		line-height: 28px;
		letter-spacing: .02em;
		text-align: center;
		margin: 10px auto;
		max-width: 500px;
		color: #fff;
		@include breakpoint(small){
			font-size: 28px;
			line-height: 34px;
		}
	}
	.HH-slide-copy{
		@include font(freight-text);
		font-weight: 600;
		font-size: rem(22px);
		letter-spacing: 0.04em;
		line-height: 28px;
		color: #fff;
		font-style: normal;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}
	.caption .link-holder{
		display: none;
	  @include breakpoint(small){
	  	  display: block;
		  @include font(sans);
			text-align: center;
			margin: 30px 0 0;
		}
	}
	.caption .underlined-button{
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid #fff;
		svg {
			opacity: 1;
		}
		&:after {
			display: none;
		}
	}
	video{
		display: block !important;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 4;
		transform: translate(-50%,-50%) scale(1.4);
		background: transparent;
		@include breakpoint(large) {
			transform: translate(-50%,-50%);
		}
	}
	.canvas{
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 5;
		transform: translate(-50%,-50%) scale(1.4);
		@include breakpoint(large) {
			transform: translate(-50%,-50%);
		}
	}
	.caption .underlined-button svg{
		fill: #fff;
	}
	.home-hero-bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-position: center center;
		background-size: cover;
		background-color: $c-dark;
		opacity: 0;
		transition: .3s ease-out;
		&.image-loaded {
			opacity: 1;
		}
	}
	.video{
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-position: center center;
		background-size: cover;
		opacity: 0;
		transition: .3s ease-in;

		.anchor-ready &{
			opacity: 1;
		}
		iframe {
			display: none;
			@include center(vh);
			position: absolute;
		}

		@include breakpoint(medium) {
			background-color: #fff;
			iframe {
				display: block;
			}
		}
	}
}
.home-slider.module-ready{
	opacity: 1;
}
.no-videos .home-slider{
	video{
		display: none !important;
	}
}
.hero-pager{
	&{
		display: none;
		position: absolute;
		bottom: 96px;
		left: 50%;
		z-index: 102;
		transform: translate(-50%, 0);
		transition: .3s ease-out;
		@include breakpoint(medium) {
			display: block;
		}
	}
	.shrink &{
		transform: translate(-50%, 60px);
	}
	>button{
		display: inline-block;
		height: 12px;
		width: 12px;
		margin: 0 15px;
		padding: 0;
		border: 0;
		background: $c-light;
		border-radius: 100%;
		transition: .3s ease-out;
		outline: none;
	}
	>button.cycle-pager-active{
		background: $c-dark;
	}
}

// Slider Pager
#home-slider-pager {
	display: none;
	@include breakpoint(medium) {
		display: block;
		position: absolute;
		bottom: 15%;
		z-index: 100;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
	}
}
.home-pager-bar {
	width: 40px;
	height: 6px;
	background-color: $c-light;
	display: inline-block;
	margin: 0 6px;
	transition: all 0.3s ease;
	&.cycle-pager-active {
		background-color: #585858;
	}
}

.progress-bar-ctn {
	display: none;
  @include breakpoint(medium){
	  display: block;
	  height: 5px;
	  width: 100%;
	  position: absolute;
	  z-index: 1000;
	  bottom: 0;
	}
}
.PBC-progress {
	position: relative;
	height: 10px;
	background: #585858;
	width: 100%;
	transform-origin: left;
	z-index: 1;
	transform: scaleX(0);
	transition: .6s;
	transition-timing-function: linear;
}

.PBC-progress.animate{
	transform: scaleX(1);
}

// Featured Section
.home-featured{
	&{
		margin: 30px 0;
	}
	.content{
		@extend %cf;
		@include max-width();
		max-width: 1082px;
		@extend %shoot-up-about;
		margin: 0 auto 0;
		padding: 20px 0 74px;
		text-align: center;
		@include breakpoint(small) {
			margin: 0 auto 0;
			padding: 50px 0 20px;
			text-align: left;
		}
		@include breakpoint(medium) {
			padding: 50px 0 20px;
		}
	}
	.intro-section h2{
		&{
			@include font(sans);
			padding: 0 20px;
			margin: 0 auto -15px;
			font-size: 40px;
			line-height: 1.5em;
			letter-spacing: 0;
			text-align: center;
			white-space: normal;
			color: $c-dark;
		}
		&.js-typing {
			display: none;
		}
		@include breakpoint(small) {
			font-size: 72px;
			line-height: 92px;
			padding-left:0;
			white-space: pre-wrap;
		}
	}
	.intro-section p{
		display: block;
		max-width: 820px;
		padding: 0 20px;
		margin: 30px auto 0;
		font-size: 20px;
		letter-spacing: .025em;
		color: #969696;
		@include breakpoint(small) {
			padding: 0;
			margin: 50px auto 0;
			font-size: 22px;
			line-height: 32px;
		}
	}
	.box-one,
	.box-two,
	.box-three{
		display: block;
		width: 100%;
	}
	.project{
		&{
			position: relative;
			display: block;
			width: 100%;
			margin-top: 40px;
			overflow: hidden;
			@include breakpoint(medium){
				height: 480px;
				margin-top: 24px;
			}
		}
		.contain{
			@include breakpoint(medium){
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
			}
		}
		.link{
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			opacity: 0;
			z-index: 3;
		}
		.image{
			display: block;
			height: 250px;
			width: 100%;
			background-position: center;
			background-size: cover;
			@include breakpoint(medium){
				position: absolute;
				top: 0;
				height: 100%;
				left: 0;
				right: 0;
				width: auto;
				transition: .3s ease-out;
			}
		}
		.link:hover ~ .image{
			@include breakpoint(medium){
				opacity: .4;
			}
		}
		.image video{
			display: none;
			@include breakpoint(medium){
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				display: block;
			}
		}
		.project-content{
			@include breakpoint(medium){
				position: absolute;
				max-width: 440px;
				bottom: 20px;
				left: 20px;
				padding: 20px;
				z-index: 2;
				background: $c-light;
			}
		}
		h3{
			@include font(sans);
			padding: 20px 10px 0;
			margin: 0;
			font-size: rem(24px);
			line-height: rem(18px);
			letter-spacing: .02em;
			text-align: left;
			color: $c-dark;
			@include breakpoint(medium){
				padding: 20px 20px 0;
			}
		}
		p{
			padding: 0 10px 10px;
			margin: rem(8px) 0 0;
			font-size: rem(22px);
			letter-spacing: .02em;
			line-height: rem(32px);
			text-align: left;
			@include breakpoint(small){
				br{
					display: none;
				}
			}
			@include breakpoint(medium){
				padding: 0 20px 20px;
			}
		}
	}
	.box-two,
	.box-three{
		@include breakpoint(medium){
			&{
				overflow: hidden;
			}
			.project{
				&{
					float: left;
					width: 50%;
				}
			}
		}
	}
	.box-three{
		@include breakpoint(medium){
			.project:nth-child(1){
				&{
					height: 480 * 2 + 24px;
				}
				.contain{
					right: 12px;
				}
			}
			.project:nth-child(2),
			.project:nth-child(3){
				.contain{
					left: 12px;
				}
			}
		}
	}
	.box-two{
		@include breakpoint(medium){
			.project:nth-child(1){
				.contain{
					right: 12px;
				}
			}
			.project:nth-child(2){
				.contain{
					left: 12px;
				}
			}
		}
	}
	.jane-box{
		&{
			text-align: left;
		}
		.image{
			background: #ccc;
			background-position: right;
			background-size: cover;
		}
		.project-content{
			background: transparent;
			margin: 20px 0 0;
			padding: 0 10px;
			@include breakpoint(medium){
				position: absolute;
				top: 50%;
				bottom: auto;
				transform: translateY(-50%);
				margin: 20px 20px 0;
				padding: 0;
				padding-left: 50px;
				margin-top: 0;
				z-index: 4;
			}
		}
		.logo{
			display: block;
			width: 108px;
			fill: $c-dark;
			@include breakpoint(medium){
				width: 188px;
			}
		}
		p{
			padding: 5px 0 0;
			margin: 10px 0;
			font-family: 'circular book';
			font-weight: 400;
			font-size: rem(24px);
			line-height: rem(37px);
			letter-spacing: .01em;
			text-align: left;
			color: $c-dark;
			@include breakpoint(medium){
				padding: 0 0 20px 0;
			}
		}
		.learn-button{
			@include font(sans);
		  margin: 10px 0 0;
		  display: inline-block;
		  text-align: center;
		  text-decoration: none;
		  color: $c-dark;
		  font-size: 20px;
			letter-spacing: .02em;
		  border: 2px solid $c-dark;
		  padding: 20px 50px;
		  transition: all 0.3s ease;
		  &:hover {
		    color: $c-light;
		    background-color: #000000;
		    border-color: #000000;
		  }
		}
	}
}

// Client Section
.home-clients{
	&{
		@extend %shoot-up-about;
		@include max-width;
		max-width: 1082px;
		@include col(1,1,none);
		margin-top: 60px;
		margin-bottom: 60px;
		margin-left: auto;
		margin-right: auto;
		// Mobile Styles
		@include breakpoint(small){
			margin-top: 100px;
			margin-bottom: 100px;
			margin-left: auto;
			margin-right: auto;
			@include col(12,12,none);
		}
	}
	.header{
		padding: 0 10px 10px;
		font-size: 40px;
		line-height: normal;
		color: $c-dark;
		text-align: center;

		@include breakpoint(small) {
			padding: 0;
			font-size: 44px;
		}
		@include breakpoint(medium) {
			padding: 0 0 15px;
			text-align: left;
		}
	}
	.description{
		color: #969696;
		font-size: 20px;
		padding: 0 20px;

		@include breakpoint(small) {
			font-size: 22px;
			padding: 0;
		}
	}
	.clients-ctn{
		@extend %cf;
		font-size: 0;
		text-align: left;
			padding: 0 10px;

		@include breakpoint(small) {
			text-align: center;
		}

		@include breakpoint(medium) {
			padding: 0;
			text-align: left;
			&.track-visible .client {
				opacity: 1;
				transform: translateY(0);
				$delay: 0s;
				@for $i from 1 to 10 {
					&:nth-child(#{$i}) {
						transition-delay: $delay;
					}
					$delay: $delay + .15s;
				}
			}
		}
	}
	.client{
		@include col(1,1,both);
		position: relative;
		display: inline-block;
		height: 65px;
		@include col(1,2,both);
		transition: .3s ease-out;
		// Mobile Styles
		@include breakpoint(small){
			@include col(1,3,both);
			margin: 5px 0;
			transform: translateY(rem(50px));
			&:nth-child(-n+5){
				@include col(1,5,both);
			}
			&:nth-child(n+6){
				@include col(1,4,both);
			}
		}
		@include breakpoint(medium){
			height: 80px;
		}
	}
	.client div{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		opacity: 0.7;
		transition: opacity 0.3s ease;
		&:hover {
			opacity: 1;
		}

		@include breakpoint(medium) {
			background-position: left;
		}
	}
}
