// Global Module Code
.case-study-modules{
	position: relative;
	z-index: 1;
	background-color: #800808;
}

// $darkgrey: #2a2322;
$black: $c-dark;
$white: #fff;

// Functions
@function grid($columns:12, $grid:12){
  @return ((100/$grid) * $columns) + 0%;
}

// Repeating styles
%shortform-text-box{
	padding: 50px;

	@include breakpoint(medium) {
		padding: 100px;
		@include max-width();
	}
}
%shortform-body{
	@include font(freight, none);
	font-size: 20px;
	line-height: 33px;
	letter-spacing: .04em;
	color: #6d6d6d;
}
%shortform-body--large{
	@include font(freight, none);
	font-size: 40px;
	line-height: 48px;
	letter-spacing: .04em;
	color: $black;
}
%shortform-header{
	&{
		@include font(sans);
		font-size: 44px;
		line-height: 52px;
		color: $black;
		margin: 0;
	}
	&::after{
		content: "";
		display: block;
		width: 100px;
		height: 2px;
		margin-top: 30px;
		background: $black;
	}
}
%shortform-header--caption{
	&{
		@include font(sans);
		font-size: 20px;
		line-height: 45px;
		letter-spacing: .02em;
		color: $black;
		margin: 0;
	}
	&::before{
		content: "";
		display: block;
		width: 26px;
		height: 2px;
		margin-top: 30px;
		background: $black;
	}
}
%shortform-subheader {
	&{
		@include font(sans);
		font-size: 20px;
		line-height: 45px;
		letter-spacing: 0.02em;
		color: $black;
		margin: 0;
	}
	&::before{
		content: "";
		display: block;
		width: 60px;
		height: 2px;
		margin-bottom: 20px;
		background: $black;
	}
}
%image{
	position: relative;
	z-index: 2;
	box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
}
%slide-button{
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
	border: 1px solid #929292;
	transition: all 0.3s ease;
	&:hover {
		background-color: #929292;
		svg {
			fill: $c-light;
		}
	}
}
%svg-arrow{
	@include center(vh);
	position: absolute;
	display: block;
	width: 24px;
	fill: #929292;
	transition: all 0.3s ease;
}
%button-button{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 0;
}
%slider-pager{
	.slide-pager{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 60px;
		opacity: 0;
		transition: .3s;
	}
	.cycle-pager-active{
		opacity: 1;
	}
	.current-slide{
		font-size: 28px;
		color: $c-light;
		border: solid $black;
		border-width: 0 0 2px 2px;
		padding: 6px 16px 16px;
	}
	.total-slide{
		font-size: 28px;
		color: $black;
		border: solid $black;
		border-width: 0 0 0 2px;
		padding: 16px 16px 6px;
	}
}

.short-form {
	.HHT-large-content {
		@include breakpoint(small) {
			padding: 0;
			// position: static;
			// top: auto;
			// transform: none;
		}
	}
	// .HHT-small-content {
	// 	@include breakpoint(small) {
	// 		@include center(v);
	// 		position: absolute;
	// 		margin-left: grid(5.5);
	// 		padding: 0;
	// 	}
	// }
}

.dark p {
	color: $c-dark;
}

.shortform-m-entry {
	& {
		// background-color: $darkgrey;
	}
	.text {
		@extend %shortform-text-box;
	}
	.text h1{
		@extend %shortform-header;
		@extend %shoot-up-element;
		color: $white;
		font-size: 30px;
		&:after {
			background: $white;
		}
		&.invert-dark {
			color: $c-dark;
			&:after {
				background: $c-dark;
			}
		}

		@include breakpoint(small) {
			font-size: 44px;
		}
	}
	.text p{
		@extend %shortform-body;
		color: $white;
		font-size: 16px;
		line-height: 1.5em;

		@include breakpoint(small) {
			font-size: 20px;
			line-height: 33px;
		}
	}
	.content{
		position: relative;
		@extend %cf;

		@include breakpoint(small) {
			margin-top: 30px;
		}
	}
	.content .left{
		@extend %shoot-up-element;
		width: grid(12);
		&.dark p {
			color: $c-dark;
		}

		@include breakpoint(medium) {
			width: grid(6);
			float: left;
			padding-right: 30px;
		}
	}
	.content .right{
		width: grid(12);

		@include breakpoint(medium) {
			@include center(v);
			width: grid(6);
			margin-left: grid(7);
			position: absolute;
			padding-left: 30px;
		}
	}
	.content .right img {
		width: grid(12);
	}
	.full-site-img {
		width: grid(6);
	    margin-left: grid(3);
	    text-align: center;
	}
	.full-site-img img {
		max-width: 100%;
	}
	// Slider contain
	.isc_slideshow-container{
		@extend %shoot-up-element;
		position: relative;
		&:after {
			position: absolute;
			content: '';
			background-color: $white;
			width: 100%;
			height: 70%;
			top: 30%;
		}
	}
	.slider-wrapper{
		position: relative;
		z-index: 1;
		width: grid(10);
		margin-left: grid(1);

		@include breakpoint(small) {
			margin-top: 100px;
			width: grid(12);
			margin-left: 0;
		}
		@include breakpoint(medium) {
			width: grid(10);
			margin-left: grid(1);
		}
	}
	// Slider control buttons
	.prev-button-container{
		width: grid(1);
		position: absolute;
		bottom: 0;

		@include breakpoint(small) {
			@include center(v);
			top: 35%;
			bottom: auto;
		}
	}
	.next-button-container{
		width: grid(1);
		position: absolute;
		bottom: 0;
		left: grid(11);

		@include breakpoint(small) {
			@include center(v);
			top: 35%;
			bottom: auto;
		}
	}
	.prev-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
	}
	.next-button-container .button{
		@extend %slide-button;
		@include center(v);
		position: absolute;
		right: 0;
	}
	.prev-button-container svg{
		@extend %svg-arrow;
	}
	.next-button-container svg{
		@extend %svg-arrow;
		transform: translate(-50%, -50%) rotate(180deg) !important;
	}
	.prev-button-container button{
		@extend %button-button;
	}
	.next-button-container button{
		@extend %button-button;
	}
	.slide {
		width: 100%;
	}
	// Slider
	.slider{
		width: grid(12);
		margin: 0 auto;

		@include breakpoint(small) {
			width: grid(9);
		}
	}
	.slider img{
		@extend %image;
		display: block;
		width: grid(12);
	}
	.slide-pager-container{
		display: none;

		@include breakpoint(small) {
			display: block;
			width: grid(1);
			height: 103px;
			position: absolute;
			top: 400px;
			right: 0;
		}
		@include breakpoint(medium) {
			top: 60%;
			right: -100px;
		}
	}
	.slide-pager{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 80px;
		transition: .3s;
	}
	.slide-pager .current-slide{
		font-size: 28px;
		color: $black;
		border: solid $black;
		border-width: 0 0 1px 1px;
		padding: 0 16px 16px;
	}
	.slide-pager .total-slide{
		font-size: 28px;
		color: $black;
		border: solid $black;
		border-width: 0 0 0 1px;
		padding: 16px 16px 0;
	}
	.captions{
		overflow: hidden;
		@include max-width();
		padding-bottom: 30px;

		@include breakpoint(small) {
			padding-top: 45px;
			padding-bottom: 0;
		}
	}
	%captions{
		>h2{
			@include font(sans);
			font-size: 16px;
			line-height: 1em;
			letter-spacing: .02em;
			color: $black;
		}
		>h2::before{
			content: "";
			display: block;
			width: 26px;
			height: 2px;
			margin: 30px 0 10px;
			background: $black;
		}

		@include breakpoint(small) {
			>h2 {
				font-size: 20px;
				line-height: 45px;
			}
		}
	}
	.captions .left{
		@extend %captions;
		width: grid(12);

		@include breakpoint(small) {
			width: grid(9);
		}
	}
	.captions p{
		@extend %shortform-body;
		color: $black;
		font-size: 16px;
	    line-height: 1em;
	    margin: 0 0 16px;

	    @include breakpoint(small) {
	    	font-size: 20px;
	    	line-height: 33px;
	    	margin: 16px 0;
	    }
	}
}
.shortform-m-bottom {
	@extend %cf;
	.text {
		border-top: 1px solid #dadada;
		@extend %shortform-text-box;

		@include breakpoint(medium) {
			padding-bottom: 100px;
		}
	}
	.text h1{
		@extend %shortform-header;
		@extend %shoot-up-element;
		color: $black;
		font-size: 30px;
		&:after {
			background: $black;
		}

		@include breakpoint(small) {
			font-size: 44px;
		}
	}
	.text p{
		@extend %shoot-up-element;
		@extend %shortform-body;
		color: $black;
		font-size: 16px;
		line-height: 1.5em;

		@include breakpoint(small) {
			font-size: 20px;
			line-height: 33px;
		}
	}
	.content{
		position: relative;
		@extend %cf;

		@include breakpoint(small) {
			margin-top: 30px;
		}
	}
	.content .left{
		@extend %shoot-up-element;
		width: grid(12);

		@include breakpoint(medium) {
			width: grid(6);
			float: left;
			padding-right: 30px;
			&.offset {
				@include center(v);
				padding-top: 0;
				position: absolute;
			}
		}
	}
	.content p{
		opacity: 1;
		transform: translateY(0);
	}
	.content .right{
		width: grid(12);
		padding-top: 40px;

		@include breakpoint(medium) {
			@include center(v);
			width: grid(6);
			margin-left: grid(6);
			padding-top: 0;
			position: absolute;
			padding-left: 30px;
			&.offset {
				position: static;
				top: auto;
				transform: none;
				padding-right: 30px;
				padding-left: 0;
			}
		}
	}
	.content img {
		@extend %shoot-up-element;
		width: grid(12);
	}

	@include breakpoint(small) {
		margin-top: 100px;
	}
}

.image-descriptor-module{
	&{}
	.slider{
		width: 100%;
	}
	.content .left{
		padding-right: 0;
	}
	.content .right{
		margin-top: 15px;
	}
}

.full-site-img img{
	display: block;
	width: 100%;
}
// iframe fix
.full-site-iframe{
	iframe {
		width: 100%;
		border: none;
		height: 885px;
		margin: 0 auto;
	}
}
.annotation .content{
	margin-top: 0;
}


.video-carousel-module{
	.content{
		@include elevenbp(){
			padding-left: 60px;
			padding-right: 60px;
		}
	}
	.slider-contain-2{
		&{
			overflow: visible !important;
		}
		.slider{
			margin: 0;
			@include elevenbp(){
				width: 70% !important;
				margin: 0 !important;
			}
		}
	}
	.prev-button-container .button,
	.next-button-container .button{
		@include mobilebp(){
			top: 0 !important;
			transform: translateY(0) !important;
		}
	}
	.next-button-container .button{
		@include mobilebp(){
			position: absolute;
			bottom: 0;
			left: auto;
			right: 0;
		}
	}
}

.one-up-carousel-module .carousel-wrap{
	@media (max-width: 1100px){
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

.one-up-carousel-module .prev-button-container,
.one-up-carousel-module .next-button-container{
	top: 50%;
}
.one-up-carousel-module .next-button-container,
.one-up-carousel-module .slide-pager{
	left: 0;
}
.one-up-carousel-module .next-button-container{
	@media (min-width: 1024px){
		left: auto;
		right: 0;
	}
}
.work-modules .one-up-carousel-module .slide-pager-container{
	@media (min-width: 1024px){
		right: 0;
	}
	@media (min-width: 1200px){
		margin-top: 80px;
	}
}
.shortform-m-entry .content{
	@media (min-width: 767px){
		margin-top: 0;
	}
}
.no-captions .slider-contain .slide-pager-container {
	right: 0;
}
.one-up-carousel-module .text{
	@media (max-width: 767px){
		padding: 0;
	}
}
