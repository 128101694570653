.mobile-page-header{
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	height: rem(52px);
	width: 100%;
	background: $c-light;
	z-index: 50;
	opacity: 1;
	.page-loading &,
	.page-transition & {
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-out;
	}
	@include breakpoint(medium) {
		display: none;
	}
	.header-logo{
		margin: 0;
	}
}
.page-logo-ctn{
	display: block;
	position: absolute;
	z-index: 12;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: rem(62px);
	width: rem(170px);
	@include breakpoint(medium) {
		text-align: center;
		&:hover .logo-background {
			transform: scale(1.05);
		}
	}
}
.page-logo{
	display: inline-block;
	width: rem(170px);
	height: rem(62px);
	padding: rem(15px) rem(30px);
	> svg{
		height: 100%;
		width: 100%;
		fill: $c-dark;
	}
}
.logo-background {
	& {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: white;
		z-index: -1;
		transition: all 0.3s ease;
		transform: scale(1);
	}
}
.navigation-button{
	position: absolute;
	top: 50%;
	right: rem(18px);
	transform: translateY(-50%);
	display: block;
	height: rem(24px);
	width: rem(28px);
	padding: 0;
	border: none;
	background: none;
	outline: none;
	transition: .3s;
	button{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
	}
}
.hamburger-container{
  position: relative;
  height: 100%;
  width: 100%;
	pointer-events: none;
}
.hamburger-container > div{
	position: absolute;
	display: block;
	height: rem(2px);
	width: 100%;
	background: $c-dark;
	transition: .3s ease-out;
	&:nth-child(1){
		top: 0;
	}
	&:nth-child(2){
		top: 50%;
		transform: translateY(-50%) scaleX(1);
		transform-origin: left top;
	}
	&:nth-child(3){
		bottom: 0;
		transform: scaleX(1);
		transform-origin: left top;
	}
}
.navigation-button:hover .hamburger-container{
	@include breakpoint(medium) {
		> div:nth-child(2){
			transform: translateY(-50%) scaleX(.75);
			transform-origin: left top;
		}
		> div:nth-child(3){
			transform: scaleX(.75);
			transform-origin: left top;
		}
	}
}
.nav-open  .hamburger-container > div{
	&:nth-child(1){
		transform: rotate(45deg) translate(8px,7px) scaleX(1) !important;
	}
	&:nth-child(2){
		transform: translateY(0) scaleX(1) !important;
		transform-origin: center !important;
		opacity: 0;
	}
	&:nth-child(3){
		width: 100%;
		transform: rotate(-45deg) translate(8px,-7px) scaleX(1) !important;
		transform-origin: center !important;
	}
}
.site-mobile-navigation{
	position: fixed;
	top: rem(52px);
	bottom: 0;
	left: 0;
	display: none;
	width: 100%;
	z-index: 13;
	padding: 0;
	opacity: 0;
	transform:  translateZ(0);
	transition: all .3s;
	background: #fff;
	overflow: auto;
	@include breakpoint(medium){
		display: none;
	}
}
.mobile-nav{
	&{}
	.group{
		display: block;
		margin: 30px 0;
	}
	h1 a{
		@include font(sans,3);
		@include font-smoothing(true);
		margin: 10px 0 15px;
		font-size: 14px;
		letter-spacing: .06em;
		text-transform: uppercase;
		text-align: center;
		color: $c-dark;
	}
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	a{
		@include font(sans,3);
		display: block;
		width: 100%;
		font-size: 23px;
		line-height:23px;
		letter-spacing: .05em;
		text-align: center;
		text-decoration: none;
		color: #bdbdbd;
		margin: 10px 0 27px;
	}
	.social-media-group{
		&{
			display: block;
			margin: 34px 0 20px;
			font-size: 0;
			text-align: center;
		}
		li{
			display: inline-block;
			margin: 0 16px;
		}
		a{
			display: block;
			height: 20px;
			width: 20px;
			fill: #bdbdbd;
			margin: 0;
		}
	}
}
