.terms-container {
	background: #f2f3f3;
	padding: 0 20px;

	@include breakpoint(small) {
		padding: 0;
	}
	h1 {
		@include font(sans);
		color: $c-dark;
		font-size: 54px;
	}
	p {
		margin: 20px 0;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0.04em;
		color: $c-dark;
	}
	a {
		@extend %body-copy-links;
	}
	h2 {
		@include font(sans);
		font-size: 20px;
		color: $c-dark;
	}
	ul {
		padding: 0;
		margin: 0;
	}
	li {
		list-style-type: none;
		margin-bottom: 40px;
	}
	li:before {
		float: left;
		content: "";
		display: block;
		width: 80px;
		height: 4px;
		margin-top: 012px;
		background: #BEBEBE;
	}
	.TC-list-content {
		margin: 0 0 0 100px;
	}
	.TC-block {
		margin: 40px 0;
	}
}

.wrap {
	width: 100%;
	max-width: 580px;
	margin: 0 auto;
	padding: 100px 0;
}