.desktop-site-navigation{
	&{
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: none;
		width: 100%;
		height: 40px;
		z-index: 12;
		@include breakpoint(medium){
			display: block;
		}
	}
	.content{
		width: 100%;
		max-width: 63rem;
		margin: 0 auto;
	}
	nav{
		backface-visibility: hidden;
		filter: blur(0.00000001px);
	}
	ul{
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		text-align: center;
	}
	.nav-link-contain{
		display: block;
		vertical-align: top;
		margin: 10px 20px 0;
	}
	.header-logo{
		margin: 0;
	}
	.page-logo{
		position: relative;
		display: block;
		height: rem(60px);
		width: rem(170px);
		&:hover .logo-background {
			transform: scale(1.05);
		}
	}
	.ghost-nav{
		display: none;
	}
	.logo-background {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: white;
		z-index: -1;
		transition: all 0.3s ease;
		transform: scale(1);
	}
	.link-option{
		&{
			@include font(sans,3);
			@include font-smoothing(true);
			display: block;
			width: 100px;
			height: 0;
			margin-top: 0;
			font-size: 14px;
			color: $c-dark;
			transition: .6s ease-out;
		}
		&.left{
			text-align: left;
		}
		&.right{
			text-align: right;
		}
		.link-content{
			position: relative;
			padding: 5px 10px;
		}
		button,
		a{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			height: 100%;
			width: 100%;
			padding: 0;
			border: 0;
			margin: 0;
			opacity: 0;
			backface-visibility: hidden;
			filter: blur(0.00000001px);
		}
		span{
			@include font-smoothing(true);
			display: inline-block;
			backface-visibility: hidden;
			filter: blur(0.00000001px);
		}
		span:after{
			content: "";
			display: block;
			height: 2px;
			width: 100%;
			background: $c-dark;
			transform: translateY(4px);
			opacity: 0;
			transition: .3s ease-out;
			backface-visibility: hidden;
			filter: blur(0.00000001px);
		}
		a.page-active,
		button.page-active,
		a:hover,
		button:hover,
		a.active-pane,
		button.active-pane{
			& ~ span::after{
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
	&.show-nav .link-option{
		transform: translateY(12px);
	}
}
.nav-relation-panel-ctn{
	&{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 5;
		width: 100%;
		padding: 70px 40px 40px;
		background: #fff;
		transform: translateY(-100%);
		transition: .6s ease-out;
	}
	.break-bar{
		display: block;
		width: 100%;
		height: 1px;
		margin-bottom: 20px;
		background: #f1f1f1;
	}
	&.show-nav{
		transform: translateY(0);
	}
}
.nav-relation-panel{
	&{
		width: 100%;
	}
	&.active-pane{
		display: block;
	}
	.content{
		@include max-width();
		max-width: 63em !important;
		width: 100%;
		margin: 0 auto;
		padding: 0 25px;
	}
	ul{
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.left-side{
		&{
			width: 180px;
			float: left;
		}
		li{
			display: block;
		}
		a{
			@include font(sans,3);
			@include font-smoothing(true);
			display: block;
			width: 100%;
			margin: 12px 0 20px;
			font-size: 14px;
			text-decoration: none;
			color: $c-dark;
			opacity: .4;
			transition: .3s ease-out;
		}
		a.jane-logo{
			height: 12px;
			width: 40px;
		}
		a svg{
			fill: $c-dark;
		}
		a.page-active,
		a:hover,
		a.hovering{
			opacity: 1;
		}
	}
	.right-side{
		&{
			width: auto;
			margin-left: 180px;
			font-size: 0;
		}
		li{
			display: inline-block;
			vertical-align: top;
			width: 33.3%;
			padding: 10px;
		}
		li:nth-child(1){
			padding: 10px 20px 10px 0;
		}
		li:nth-child(2){
			padding: 10px 10px 10px 10px;
		}
		li:nth-child(3){
			padding: 10px 0 10px 20px;
		}
		a{
			display: block;
			text-decoration: none;
			color: $c-dark;
			opacity: .4;
			transition: .3s ease-out;
		}
		a.page-active,
		a:hover,
		a.hovering{
			opacity: 1;
		}
		.img{
			display: block;
			height: 100px;
			width: 100%;
			background-position: center center;
			background-size: cover;
			@include breakpoint(max){
				height: 150px;
			}
		}
		p{
			font-family: 'circular book';
			font-weight: 400;
			font-size: 14px;
			letter-spacing: .01em;
		}
	}
	.social-media-group{
		&{
			display: block;
			margin: 34px 0 20px;
			font-size: 0;
			text-align: left;
		}
		li{
			display: inline-block;
			margin: 0 18px 0 0;
		}
		a{
			display: block;
			height: 14px;
			width: 14px;
			fill: $c-dark;
			margin: 0;
		}
	}
}
