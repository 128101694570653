// Progress bar at top of page (html is located in global because of z-index issues)
.JPB-article{
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 13;
  background: $c-light-grey;
  color: $c-dark;
}
.JPB-article-takeover{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none;
  overflow: hidden;
  background: $c-dark;
  color: $c-light;
}
.JPB-takeover-filler{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 150px;
}

// Hero
.JEH-content{
	@include col(1,1,both);
	text-align: left;
	color: $c-light;
	@include breakpoint(small){
		@include col(6,12,false);
		padding-left: rem(40px);
		max-width: rem(500px);
		margin-left: auto;
		margin-right: auto;
	}
}
.JEH-header{
	margin: 0 0 rem(35px);
	font-size: rem(54px);
	letter-spacing: .02em;
	line-height: 44px;
	font-style: normal;
	@include font(sans, 3);
}
.JEH-text{
	color: $c-light;
	letter-spacing: 0.1em;
}

.journal-entry{
}

.article-content{
	max-width: 1016px;
	margin: 0 auto;
	padding-bottom: 60px;
}

// Sets a max width, makes sure things scale correctly
%JE-compontent{
		@include col(10,12,both, 1);
		@include breakpoint(small){
			@include col(6,12,both, 3);
		}
		@include max-width();
}

// Intro
.JE-intro{
	@extend %JE-compontent;
	margin-top: rem(40px);
	@include breakpoint(small){
		margin-top: rem(90px);
	}
}
.JEI-header{
	margin: 0 0 rem(20px);
	font-size: rem(36px);
	letter-spacing: 0;
	line-height: 1.1em;
	@include font(sans, 3);
	font-style: normal;
	color: $c-dark;
	@include breakpoint(small){
		margin-bottom: 0;
		font-size: rem(54px);
	}
}
.JEI-info{
	list-style: none;
	padding: 0;
	margin: rem(20px) 0 0;
	@include breakpoint(small){
		margin-top: rem(7px);
	}
}
.JEI-info li{
	@include font(sans, 3);
	display: inline-block;
	letter-spacing: 0.16em;
	font-size: rem(12px);
	text-transform: uppercase;
	&::after{
		content: " |";
		@include font(sans, 0);
		margin: 0 rem(10px);
	}
	&:last-of-type::after{
		display: none;
	}
	svg {
		width: rem(15px);
		height: rem(15px);
		vertical-align: inherit;
		margin-right: 5px;
	}
}
.JEI-time{
	@include font(freight, 0, none);
	font-size: rem(25px);
}
.JEI-min{
	@include font(freight, 1, none);
	font-size: rem(12px);
}

.JE-body{
	a {
		color: #000;
	}
}

// Article Text
.JE-copy{
	@extend %JE-compontent;
	padding: 0;
	@include breakpoint(small) {
		@include col(7.2,12,both, 3);
	}
}
.JE-copy li{
	margin: 10px 0;
}
.JE-copy > p{
	@extend .text-copy;
	margin-bottom: rem(40px);
	font-size: rem(20px);
	letter-spacing: 0.04em;
	line-height: 1.5em;
	&:last-child{
		margin-bottom: 0;
	}
	img {
		width: 100% !important;
		max-width: 100% !important;
		height: auto !important;
	}
	strong {
		@include font(sans);
		font-weight: normal;
		letter-spacing: 0.01em;
	}
}
.JE-copy a {
	@extend %body-copy-links;
}

// Lock up captions
.ILU-caption-header{
	@extend %block-w-underline;
	margin: rem(20px) 0;
	font-size: rem(12px);
	letter-spacing: 0.02em;
	text-transform: uppercase;
	&::after{
		bottom: rem(-6px);
		height: rem(2px);
		width: 100%;
		background: $c-dark;
	}
	@include breakpoint(medium) {
		margin: rem(30px) 0 rem(20px);
	}
}
.ILU-caption-text{
	margin: rem(10px) 0;
	font-size: rem(18px);
	line-height: rem(28px);
	letter-spacing: 0.04em;
}
.journal-header{
	font-size: rem(30px);
	margin: 0 0 rem(40px);
	line-height: 1.3em;
	text-transform: none;
	letter-spacing: 0;
	color: $c-dark;
	&::before{
		content: "";
		display: block;
		height: rem(3px);
		width: rem(60px);
		margin-bottom: rem(18px);
		//background: $c-med-grey;
	}
}
.JE-list{
	list-style: none;
	padding: 0;
	margin: 0;
  counter-reset: article-list;
}
.JE-list-item{}
.JE-count{
	position: absolute;
	margin-left: -(6/1)+0%;
	&::before{
		@include font(serif,0);
	  counter-increment: article-list;
		content: counter(article-list)".";
		font-size: rem(60px);
		font-style: italic;
		letter-spacing: .01em;
		line-height: .6em;
	}
}

// Article footer
.article-footer{
	&{
		width: 100%;
		margin-top: 170px;
		border-top: 1px solid #DDDDDD;
		overflow: hidden;
	}
	svg{
		fill: $c-dark;
		width: 25px;
		margin: 0 40px;
		vertical-align: bottom;
		transition: all 0.3s ease;
	}
	%button{
		position: relative;
		height: 150px;
		width: 50%;
		padding: 60px 30px;
		border: 1px solid #DDDDDD;

		@include breakpoint(medium) {
			padding: 60px;
		}
	}
	.left{
		@extend %button;
		float: left;
		border-width: 0 1px 0 1px;
		text-align: right;
		svg {
			margin-left: 0;
		}
	}
	.right{
		@extend %button;
		float: right;
		border-width: 0 1px 0 0;
		text-align: left;

		svg {
			margin-right: 0;
		}
	}
	span {
		text-transform: none;
		font-size: 18px;
		letter-spacing: 0;
		position: relative;
		z-index: 2;
		transition: color 0.3s ease;
	}
	.left svg{
		transform: rotate(180deg);
	}
	a{
		@include font(sans);
		color: $c-dark;
		letter-spacing: .2em;
		text-decoration: none;
		text-transform: uppercase;
	}
	a::after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: 100%;
		width: 100%;
		background: $c-light-grey;
		opacity: 0;
		transition: .3s ease-out;
	}
	a:hover::after{
		opacity: 1;
	}

	@include breakpoint(medium) {
		img {
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		.left:hover,
		.right:hover {
			img{
				opacity: 0.6;
			}
			svg {
				fill: #fff;
			}
			span {
				color: #fff;
			}
		}
	}
}
