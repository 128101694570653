// initiatives
.about-initiatives-projects{
	@extend %shoot-up-about;
	@include max-width();
	margin-top: 60px;
	padding: 0 18px;
	&.track-visible .AIP-all-ctn,
	&.track-visible .AIP-list > div {
		transform: translateY(0);
		opacity: 1;
	}
	@include breakpoint(small){
		margin-top: 0;
		margin-bottom: 100px;
		padding: 60px 0 0;
	}
	@include breakpoint(max) {
		padding: 0;
	}
}
.AIP-{
	&list {
		padding: 0;  margin: 0.625rem 0 0.3125rem;

		@include breakpoint(small) {
			padding: 0;
		}
		@include breakpoint(medium) {
			padding: 0 100px;
		}
	}
	&list > div {

		@include breakpoint(medium) {
			transform: translateY(rem(50px));
			opacity: 0;
			transition: transform .5s, opacity .5s;
			$delay: .3s;
			@for $i from 0 to 4 {
				&:nth-child(#{$i}) {
					transition-delay: $delay;
				}
				$delay: $delay + .15s;
			}
		}
	}
	&list a{
		opacity: 1;
		transition: .3s;
	}
	&list a.unhover{
		opacity: .4;
	}
	&header{
		margin: 0;
		text-align: left;
		font-size: rem(14px);
		color: $c-dark;
		letter-spacing: 0.5em;
		text-transform: uppercase;

		@include breakpoint(small) {
			text-align: center;
		}
	}
	&subheader {
		@include font(sans, 3);
		margin: rem(16px) 0;
		font-size: rem(40px);
		line-height: 1em;
		text-align: left;

		@include breakpoint(small) {
			text-align: center;
		}
	}
	&subtext{
		text-align: left;
		@extend .text-copy;
		font-size: 20px;
		@include breakpoint(small) {
			text-align: center;
			width: rem(600px);
			margin: rem(22px) auto rem(60px);
			line-height: 1.4em;
		}
	}
	&all-ctn {
		position: relative;
		display: block;
		padding: 0 0 20px;
		text-align: center;
		@include breakpoint(small) {
			padding: 70px 0;
		}
		@include breakpoint(medium) {
			transform: translateY(rem(50px));
			opacity: 0;
			transition: transform .5s, opacity .5s;
			transition-delay: 1.5s;
		}
	}
	&all {
		@include font(sans, 3);
		display: inline-block;
		height: 35px;
		padding-bottom: 10px;
		border: none;
		color: $c-dark;
		@include font(sans, 3);
		font-weight: 400;
		font-size: rem(16px);
		letter-spacing: 0;
		text-decoration: none;
		text-align: center;
		background: none;
		outline: none;
	}
	&list-ctn {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease;
		transition-delay: .7s;
	}
	.expanded &list-ctn{
		transition-delay: 0s;
	}
	&list-bottom {
		padding: 0;

		@include breakpoint(medium) {
			padding: 60px 100px 0 100px;
		}
	}
	&list-bottom .MB1 {
		width: 100%;
		padding: 0 rem(32px);

		@include breakpoint(small) {
			width: 50%;
			padding: 0 rem(10px);
		}
	}

	@include breakpoint(small) {
		&all .text{
			display: block;
			transform: translateY(0);
			transition: .3s ease-out;
			transition-delay: .4s;
		}
		.expanded &all .text{
			color: $c-dark-grey;
			transform: translateY(75px);
			transition-delay: 0s;
		}
		&all .btm-line::before{
			content: "";
			display: block;
			height: 1px;
			width: 100%;
			background: $c-med-grey;
			position: absolute;
			left: 0;
			width: 100%;
			transform: scaleX(0);
			transition: .3s ease-out;
			transition-delay: .4s;
		}
		.expanded &all .btm-line::before{
			transform: scaleX(1);
			transition-delay: .0s;
		}
		&all .btm-line::after{
			content: "";
			display: block;
			height: 1px;
			width: 100%;
			background: $c-med-grey;
		}
		&all .x-out{
			position: relative;
			left: -4px;
			display: block;
			height: 24px;
			width: 24px;
			margin: 20px auto;
			transform: rotate(45deg);
			backface-visibility: hidden;
			pointer-events: none;
			opacity: 0;
			transition: .3s;
		}
		.expanded &all .x-out{
			opacity: 1;
			pointer-events: auto;
			transition-delay: .4s;
		}
		&all .x-out div{
			@include center(vh);
			position: absolute;
			display: block;
			height: 1px;
			width: 24px;
			background: $c-med-grey;
			backface-visibility: hidden;
		}
		&all .x-out div:nth-child(2){
			height: 24px;
			width: 1px;
			backface-visibility: hidden;
		}
	}
}
%btm-line{
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	background: $c-med-grey;
}
.AIP-list .MB1 {
	width: 100%;
	margin: 30px 0;
	text-align: center;
	backface-visibility: hidden;

	@include breakpoint(small) {
		width: 33.33333%;
		margin: 10px 0;
		padding: 0 20px;
		margin: 0;
		text-align: left;
	}
}
.AIP-list .MB1-title {
	margin: rem(10px) 0 rem(5px);
	text-transform: none;
	letter-spacing: 0;
	font-size: rem(20px);
	@include breakpoint(small){
		margin-top: 0;
	}
}
.AIP-list .MB1-photo {
	height: 40px;
	background-color: transparent;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	@include breakpoint(small) {
		background-position: left;
	}
}
.AIP-list .MB1-text {
	text-align: center;

	@include breakpoint(small) {
		text-align: left;
	}
}
