.birdwatchSlideshare {
  @extend %birdwatch-entry-outer;

  @include breakpoint(medium) {
    margin: 80px auto 50px;
  }
  .slideshare-embed {
    padding: 0 0 20px;

    @include breakpoint(medium) {
      padding: 0;
      margin: 80px auto 50px;
    }
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
