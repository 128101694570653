.MB1-ctn{
	@extend %cf;
	@include max-width();
}
.MB1{
	@include col(1,2,both);
	float: left;
	text-decoration: none;
	@include mobilebp(){
		height: auto !important
	}
	@include breakpoint(small){
		@include col(1,3,false);
		padding: 0;
		margin: 0 0 rem(80px);
	}
}
.MB1-photo{
	display: block;
	height: rem(140px);
	width: 100%;
	background-color: #ccc;
	background-position: center;
	background-size: cover;
	@include breakpoint(small){
		height: rem(300px);
	}
}
.MB1-title{
	font-size: rem(16px);
	line-height: 1.1em;
	margin: rem(10px) 0 rem(5px);
	color: $c-dark;
	@include breakpoint(small){
		padding-top: rem(30px);
		padding-right: rem(20px);
		margin-bottom: rem(10px);
		&:before{
			content: "";
			display: block;
			height: rem(2px);
			width: rem(60px);
			margin-bottom: rem(30px);
			background: $c-med-grey;
		}
	}
}
.MB1-subtitle{
	@include font(sans);
    font-size: 14px;
    text-align: left;
    color: $c-dark;
}
.MB1-text{
	font-size: rem(16px);
	line-height: 1.3em;
	margin: 0 0 rem(20px);
}
