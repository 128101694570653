$grids: (4,12);

.cf{
	@extend %cf;
}
.row{
	@extend %cf;
	display: block;
	max-width: rem(1500px);
	padding: 0;
	margin:{
		right: auto;
		left: auto;
	};
}
%column{
	display: block;
	float: left;
}

.show-grid{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 2;
	overflow: hidden;
	pointer-events: none;
	opacity: .2;
}
.show-grid > div{
	position: relative;
	@include col(1,4,both);
	height: 100%;
	float: left;
	border: rem(1px) solid #3DA6E2;
	border-width: 0 rem(1px);
	@include breakpoint(small){
		@include col(1,12,both);
	}
	&::before,
	&::after{
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: rem(1px);
		background: #3DA6E2;
	}
	&::before{
		left: rem(10px);
	}
	&::after{
		right: rem(10px);
	}
}