.image-lock-up-2{
	@extend %image-lock-up-ctn;
	@extend %cf2;
	@include breakpoint(small){
		position: relative;
		padding: 0;
		margin: rem(90px) 0;
	}
}
.ILU2{
	&-col{
		@include breakpoint(small){
			@include center(v);
			position: absolute;
			width: 100%;
		}
	}
	&-1{
		@extend %image-lock-up-mobile;
		@include breakpoint(small){
			@include col(5,12,false);
			height: auto;
			margin-bottom: 0;
			&:before{
				padding-bottom: 110%;
			}
		}
	}
	&-2{
		@include breakpoint(small){
			@include col(5,12,false);
			padding: 0;
			height: auto;
		}
	}
	&-3{
		@extend %image-lock-up-mobile;
		@include breakpoint(small){
			@include col(6.5,12,false,5.5);
			height: auto;
			margin-bottom: rem(60px);
			&:before{
				padding-bottom: 72%;
			}
		}
	}
	&-4{
		@extend %image-lock-up-mobile;
		@include breakpoint(small){
			@include col(5.5,12,false,5.5);
			height: auto;
			margin-bottom: 0;
			&:before{
				padding-bottom: 65%;
			}
		}
	}
}