// Header section
.process-hero {
	&{
		background: black;
	}
	.content{
		width: 100%;
		position: absolute;
		top: 50%;
		// left: 50%;
		transform: translateY(calc(-50%));
		transition: .3s ease-out;
	}
	&.shrink .content{ transform: translateY(-50%); }
	.content .small-header{
		@include font(sans);
		margin: 0 0 33px;
		font-size: 14px;
		line-height: 26px;
		letter-spacing: .5em;
		text-align: center;
		text-transform: uppercase;
		color: $c-light;
	}
	.content .large-header{
		@include font(sans);
		max-width: 1200px;
		margin: 0 auto;
		font-size: 40px;
		letter-spacing: 0;
		text-align: center;
		color: $c-light;

		@include breakpoint(small) {
			font-size: 60px;
		}
		@include breakpoint(medium) {
			line-height: 1.1em;
		}
	}
	.content .copy{
		@include font(freight,0,none);
		margin: 10px 0 0;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: .04em;
		text-align: center;
		color: $c-light;
	}
}

// Slider section
.process-slider {
	@include col(10,12,false,1);
	@include font(sans);
	//display: none;
	padding: 0;
	letter-spacing: -.02em;
	text-align: center;
	background: #fff;
	color: $c-dark;
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		margin-top: 50px;
		background: #ccc;
	}
	@include breakpoint(medium){
		position: relative;
		z-index: 2;
		
		display: block;
		//margin-top: -94px;
		padding: 100px 50px 0;
	}
	.process-slider-heading {
		margin: 50px 0;
		font-size: 14px;
		letter-spacing: 0.5em;
		text-transform: uppercase;
		@include breakpoint(medium){
			margin: 0 0 80px;
		}
	}
	.track{
		position: relative;
		z-index: 2;
		width: 100%;
		background: #fff;
	}
	#process-pagers {
		display: none;
		@include breakpoint(medium){
			display: block;
			position: relative;
			z-index: 102;
			width: 500px;
			height: 20px;
			margin: 0 auto 100px;
			padding: 0;
			&:after {
				content: "";
				position: absolute;
				z-index: 1;
				top: 2px;
				left: 5%;
				display: block;
				width: 80%;
				height: 1px;
				background: #d0d0d0;
				opacity: 1;
			}
		}
	}
	.process-pager {
		position: relative;
		z-index: 2;
		left: 5%;
		display: block;
		float: left;
		width: 40%;
		text-align: left;
		color: #929292;
		&:last-of-type {
			width: 10%;
		}
		span {
			display: inline-block;
			width: 20px;
			cursor: pointer;
			transition: color 0.25s ease-in-out;
			&:hover {
				color: #282828;
			}
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 5px;
				margin: 0 0 20px;
				background: #d0d0d0;
				transition: .2s ease-in-out;
			}
		}
	}
	.cycle-pager-active {
		span {
			width: 50px;
			color: #282828;
			&:before {
				background: #929292;
			}
		}
	}
	.process-slide {
		width: 100%;
		text-align: center;
		h1 {
			width: auto;
			max-width: 540px;
			margin: 50px auto 20px;
			text-align: left;
			@include breakpoint(medium){
				max-width: 100%;
				margin: 0 auto 80px;
				text-align: center;
			}
			pre {
				margin: 0;
				@include font(sans);
				font-size: 50px;
				line-height: 1em;
				letter-spacing: -.02em;
				white-space: normal;
				color: $c-dark;
				@include breakpoint(small){
					font-size: 80px;
					line-height: 1em;
				}
				@include breakpoint(medium){
					font-size: 130px;
					line-height: 0.85em;
					white-space: pre;
				}
				@include breakpoint(max){
					font-size: 200px;
				}

			}
		}
	}
	.process-description {
		p {
			max-width: 540px;
			overflow-y: auto;
			margin: 0 auto;
			text-align: left;
			@include font(serif);
			font-style: normal;
			font-size: rem(18px);
			letter-spacing: .04em;
			line-height: rem(27px);
			color: #858585;
			@include breakpoint(medium){
				height: 300px;
				max-height: 300px;
			}
			&:before {
				content: "";
				display: block;
				width: 50px;
				height: 5px;
				margin: 0 0 20px;
				background: #929292;
				transition: width .3s ease-out;
			}
		}
	}

}

// Tab Header
.process-tab-header{
	&{
		margin: 80px 0;

		@include breakpoint(small) {
			margin: 120px 0 180px;
		}
		@include breakpoint(medium) {
			margin: 120px 0;
		}
	}
	.title{
		@include font(sans);
		margin: 0;
		font-size: 40px;
		letter-spacing: -.02em;
		text-align: center;
		color: $c-dark;
		@extend %shoot-up-element;
	}
	.description{
		margin: 18px 0 26px;
		font-size: 20px;
		letter-spacing: .04em;
		line-height: 29px;
		text-align: center;
		color: $c-dark-grey;
		@extend %shoot-up-element;
	}
	.process-tabs{
		font-size: 0;
		padding: 0 50px;
		text-align: center;
		@include breakpoint(small){
			@include col(10,12,false,1);
		}
	}
	.tab{
		position: relative;
		display: block;
		width: 100%;
		margin: 10px 0;
		padding: 25px 0;
		text-align: center;
		background: #EFEFEF;
		//border-left: 6px solid $c-dark-grey;
		&.scrolled {
			pointer-events: auto;
		}

		@include breakpoint(small) {
			display: inline-block;
			width: 30%;
			margin: 0;
		}
		@extend %shoot-up-element;
		$delay: 0s;
		@for $i from 0 to 4 {
			&:nth-child(#{$i}) {
			transition-delay: $delay;
			}
			$delay: $delay + .3s;
		}
		&:before {
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: $c-dark-grey;
			transition: .3s ease-out;
		}
		&:hover {
			span {
				color: #fff;
			}
			&:before {
				width: 100%;
			}
		}
	}
	.tab + .tab{

		@include breakpoint(small) {
			margin-left: 10px;	
		}
	}
	.tab span{
		@include font(sans);
		position: relative;
		font-size: 12px;
		line-height: 1em;
		letter-spacing: .5em;
		text-transform: uppercase;
		color: $c-dark;
		transition: .3s ease-out;
	}
	.tab button{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
	}
}

// Tab Section
.process-tab-container{
	&{
		@include col(10,12,false,1);
		max-width: 1080px;
		@extend %cf;
		display: block;
		padding-top: 50px;
		padding-bottom: 80px;
		border-bottom: 1px solid #585858;
		@extend %shoot-up-element;

		@include breakpoint(small) {
			padding-top: 20px;
		}
		@include breakpoint(medium) {
			padding-bottom: 150px;
			margin-bottom: 130px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&:last-of-type {
		border-bottom: 0;
	}
	.tab-title{
		@include font(sans);
		font-size: 45px;
		margin: 0 0 40px;
		line-height: 1em;
		letter-spacing: 0;
		text-align: left;
		color: $c-dark;

		@include breakpoint(small) {
			font-size: 60px;
			line-height: 0.6em;
		}
		@include breakpoint(medium) {
			line-height: 1em;
		}
	}
	// .tab-copy {
	// 	margin: 20px 0 80px;
	// 	font-size: 21px;
	// 	line-height: 30px;
	// 	letter-spacing: 0.05em;

	// 	@include breakpoint(small) {
	// 		@include col(8, 12, none);
	// 	}
	// }
	.button-ctn{
		display: none;
		width: 100%;
		float: left;
		text-align: center;
	}
	.button{
		display: inline-block;
		@include font(sans);
		margin: 50px 0 0;
		padding: 30px 62px 30px 70px; // to compensate for letter spacing.
		border: 1px solid $c-dark;
		font-size: 12px;
		line-height: 1em;
		letter-spacing: .9em;
		text-align: left;
		text-decoration: none;
		text-transform: uppercase;
		background: $c-light;
		color: $c-dark;
	}
}
.capabilities-section{
	&{
		@include col(1,1,both);
		@include breakpoint(small){
			@include col(1,2);
			float: left;
		}
	}
	.header{}
	.title{
		@include font(sans);
		margin: 0 0 20px;
		font-size: 14px;
		line-height: 1em;
		letter-spacing: .5em;
		text-align: left;
		text-transform: uppercase;
		color: #585858;
	}
	ul{
		list-style: none;
		padding: 0 0 0 40px;
		margin: 0;
	}
	.item{
		position: relative;
		display: block;
		cursor: default;
		margin: 20px 0;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: -40px;
			width: 20px;
			height: 2px;
			background-color: #d9d9d9;
		}
	}
	.item h4{
		@include font(sans);
		margin: 0;
		font-size: 20px;
		line-height: 1.1em;
		letter-spacing: -.02em;
		text-align: left;
		color: $c-dark;

		@include breakpoint(small) {
			font-size: 18px;
		}
		@include breakpoint(medium) {
			font-size: 24px;
		}
	}
	.item p{
		@include font(freight);
		margin: 10px 0 0;
		font-size: 18px;
		line-height: 1.3em;
		letter-spacing: .04em;
		text-align: left;
		color: #999;
		padding-left: 0;
	}
	&.creative {
		padding-top: 30px;

		@include breakpoint(medium) {
			padding-top: 0;
			padding-left: 60px;
		}
	}
}
.case-study-section{
	&{
		@include col(1,1,both);
		overflow: hidden;
		@include breakpoint(small){
			@include col(1,2);
			float: left;
			padding-left: 30px;
		}
	}
	.content{
		@extend %cf;
		width: auto;
		padding: 20px 0;
		@include breakpoint(small){
			padding: 0 0 20px;
		}
	}
	.title{
		@include font(sans);
		margin: 0;
		font-size: 14px;
		line-height: 1em;
		letter-spacing: .5em;
		text-align: left;
		text-transform: uppercase;
		color: #585858;
	}
	.project{
		@include font(sans);
		margin: 17px 0 24px;
		width: 100%;
		font-size: 40px;
		line-height: 1em;
		letter-spacing: -.02em;
		text-align: left;
		color: $c-dark;
	}
	.description{
		@include font(freight);
		margin: 0;
		width: 100%;
		font-size: 18px;
		line-height: 1.5em;
		letter-spacing: .04em;
		text-align: left;
		color: #585858;
	}
	.content a {
		margin: 25px 0 0;
		float: right;
	}

	.PCS-image{
		position: relative;
		display: block;
		margin: 15px 0 0;
		// background: #aaa;
		img {
			width: 100%;
			margin-top: 15px;
		}
	}
	.PCS-video {
		position: relative;
		display: block;
		margin: 15px 0 0;
		iframe {
			margin-top: 30px;
			pointer-events: none;
		}
	}
	.PCS-image,
	.PCS-video {
		&:before {
			position: absolute;
			content: '';
			background-color: #d7d7d7;
			height: 18px;
			width: 100%;
			border-radius: 5px 5px 0 0;
		}
		> span {
			position: absolute;
			display: inline-block;
			width: 6px;
			height: 6px;
			background-color: $c-light;
			border-radius: 50%;
			top: 5px;
			left: 15px;
			&:nth-of-type(2) {
				left: 35px;
			}
			&:nth-of-type(3) {
				left: 55px;
			}
		}
	}
}