// loader container
.loading-animation-ctn{
	&{
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -2;
		opacity: 0;
		pointer-events: none;
		transition: 0.6s ease-in-out;
	}
	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		height: 100%;
		width: 100%;
		background: $c-light;
		opacity: 1;
	}
	.loading-animation{
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%,-50%);
		width: 200px;
		height: auto;
    opacity: 0;
    transition: opacity 0.4s ease-out;
    transition-delay: 0.7s;
	}
}
html:not(.browser-ie) .page-loading {
	.loading-animation-ctn{
		opacity: 1;
		z-index: 12;
    .loading-animation{
      opacity: 1;
      animation: fadeInLoader 1.4s ease-out;
    }
    .page-logo {
      opacity: 0;
    }
    .navigation-button {
      opacity: 0;
    }
	}
}
@keyframes fadeInLoader {
  0%{opacity: 0;}
  60%{opacity: 0;}
  100%{opacity: 1;}
}
html:not(.browser-ie) .page-transition {
  .loading-animation-ctn{
    opacity: 1;
    z-index: 12;
    .loading-animation{
      opacity: 1;
      transition: opacity 0.6s ease-out;
      transition-delay: .6s;
    }
    .page-logo {
      opacity: 0;
    }
    .navigation-button {
      opacity: 0;
    }
  }
}


//loader
#spinner {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 20px auto;
  box-sizing: content-box;
  i {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #6d6d6d;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    animation: spinLoader 1.5s ease infinite;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #6d6d6d;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    animation: spinLoaderInner 1.5s ease infinite;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #6d6d6d;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    animation: spinLoaderInner 1.5s ease infinite;
  }
}
@keyframes spinLoader {
  100% {transform:rotate(360deg);}
  0% {transform:rotate(0deg);}
}
@keyframes spinLoaderInner {
  100% {transform:rotate(0deg);}
  50% {transform:rotate(90deg);}
  0% {transform:rotate(0deg);}
}
.fancy {
	.fancy-loader{
		position: absolute;
		display: block;
		height: 100%;
		width: 100%;
		background: $c-light;
	}
	.contain{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0.50);
		display: block;
		width: 700px;
		color: #fff;

		@include breakpoint(small) {
			transform: translate(-50%,-50%) scale(0.75);
		}
	}
	.time{
		width: 100%;
		margin-left: -200px;
		@include font(sans);
		font-size: 175px;
		text-align: right;
		white-space: nowrap;
		color: #F1F1F1;

		@include breakpoint(small) {
			font-size: 200px;
			width: 700px;
		}
	}
	.flex{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		margin-left: 200px;
		@include font(serif);
		font-style: normal;
		letter-spacing: 0.05em;
		font-size: 50px;
		font-weight: 700;
		color: #444;

		@include breakpoint(small) {
			font-size: 60px;
			width: 700px;
			margin-left: 300px;
		}
	}
	.flex div:not(:last-child){
		position: absolute;
	}
	.flex div{
		transform: translateY(100%);
		opacity: 0;
		backface-visibility: hidden;
		filter: blur(0);
	}
}
.transition {
	& {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 15;
		background-color: $c-light;
		opacity: 0;
		pointer-events: none;
	}
	.page-transition-ctn {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%,-50%);
		width: 200px;
		height: auto;
	}
	svg{
		width: 100%;
		height: 100%;
		// stroke: $c-global;
    stroke: $c-dark;
		stroke-width: 0;
		fill: $c-dark;
	}
	.progress-meter {
		position: relative;
		display: block;
    width: 100%;
    height: 5px;
    background-color: #e9e9e9;
    margin: 20px 0 0;
	}
	.show-progress {
		position: absolute;
		top: 0;
		left: 0;
		background-color: $c-dark;
		width: 0;
		height: 100%;
		z-index: 2;
		transition: width 1.255s ease;
		&.loaded {
			width: 100%;
		}
	}
}
