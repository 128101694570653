.unordered-list-top {
  position: relative;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  .top-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: auto;
    overflow: hidden;
  }
  #pixie {
    position:relative;
    width:100%;
    height:100%;
  }
  .top-content {
    position: relative;
    height: 100%;
    z-index: 2;
    text-align: center;
  }
  .header-page {
    margin: 0;
    position: absolute;
    @include center(vh);
  }
}
.ul-logo {
  width: 100%;
  height: 150px;

  @include breakpoint(small) {
    height: 225px;
  }
}
.unordered-list{
  position: relative;
  z-index: 3;
  background: #fff;
  max-width: rem(1015px);
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: rem(140px);
  margin-bottom: 0;
  // &.track-visible {
  //   margin-top: -125px;
  // }
}
.ul-header{
  font-size: rem(30px);
  margin: rem(60px) 0;
  text-transform: none;
  letter-spacing: 0;
  &::before{
    content: "";
    display: block;
    height: rem(3px);
    width: rem(60px);
    margin-bottom: rem(30px);
    background: $c-med-grey;
  }
}

%ul-article-mobile{
  position: relative;
  z-index: 1;
  display: block;
  .UL-image{
    @include col(1,1,both);
    height: 200px;
    background-position: center;
    background-size: cover;
  }
  .UL-content{
    position: relative;
    @include col(1,1,both);
    padding-top: rem(20px);
    padding-left: rem(30px);
    padding-bottom: rem(40px);
    border-left: rem(30px) solid $c-dark;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #C6C6C6;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    @include breakpoint(small){
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
      &:after {
        display: none;
      }
    }
  }
  .UL-feature{
    display: none;
  }
  .UL-title{
    @include font(sans, 3);
    font-style: normal;
    margin: rem(10px) 0;
    color: $c-dark;
    font-size: 30px;
    line-height: 1em;
    @include breakpoint(small){
      font-size: 2.25rem;
      line-height: 2.625rem;
    }
  }
  .UL-btn{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2;
    overflow: hidden;
  }
}
%article-display{
  @include breakpoint(small){
    display: flex;
    align-items: center;
    // margin: rem(10px) 0;
    margin: 0;
    .UL-content{
      padding: rem(128px) rem(128px) rem(96px);
      border-left: none;
      border-bottom: 1px solid $c-dark;
    }
    &:last-of-type .UL-content {
      border-bottom: 0;
    }
    .UL-feature{
      @include font(sans, 3);
      display: inline-block;
      padding-top: rem(18px);
      border-top: rem(3px) solid $c-global;
      color: $c-global;
      margin-bottom: rem(12px);
      text-transform: uppercase;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      font-size: rem(14px);
    }
    .UL-title{
      margin: rem(8px) 0 rem(16px);
    }
    .UL-description{
      font-size: rem(20px);
      margin-bottom: rem(28px);
      letter-spacing: 0.04em;
      line-height: 1.5em;
    }
    .UL-btn{
      position: relative;
      opacity: 1;
      margin-left: rem(60px);
      overflow: hidden;
    }
  }
}
.UL-article{
  @extend %ul-article-mobile;
  @extend %article-display;
}
.unordered-list-button{
  @extend %cf2;
  border-top: rem(1px) solid darken($c-light-grey, 10);
}

//entry styles
.ul-article-content {
  background: #fff;
  @include breakpoint(small){
    .UL-article > .UL-content{
        padding: rem(60px) 0;
        @include col(8,12);
    }
  }
}

%ULE-compontent{
    @include col(1,1,both);
    @include breakpoint(small){
      @include col(8,12);
      margin: 0 auto;
    }
    @include breakpoint(medium){
      @include col(8,12);
      margin: 0 auto;
    }
}
.ul-entry-top {
  position: relative;
  display: table;
  width: 100%;
  height: 500px;
  padding-bottom: 105px;
    @include breakpoint(small){
      padding: 0 0 85px;
    }
  .bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .bg-tile {
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
  }
}
.ULEH-content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;

  @include col(1,1,both);
  text-align: left;
  color: $c-light;
}
.ULE-intro {
  position: relative;
  width: 100%;
  margin-top: -125px;
  margin-left: 0;
  padding: 0;
  background: #fff;
  .JEI-info {
    @include col(1,1,both);
    margin-top: 50px;
    padding: 0 18px;
    @include breakpoint(small){
      padding: 0;
      @include col(8,12);
      margin: 0 auto;
    }
    @include max-width();
    li svg {
      position: relative;
      top: 4px;
      height: 15px;
    }
  }
}
.ULEH-header {
  position: relative;
  width: 300px;
  margin: 0 auto rem(35px);
  font-size: rem(40px);
  letter-spacing: .015em;
  line-height: 44px;
  font-style: normal;
  @include font(sans, 3);
}
.ULE-copy{
  @extend %ULE-compontent;
  padding: 0 18px;
  @include breakpoint(small){
    padding: 0;
  }
}
.ULE-copy img{
  max-width: 100%;
}
.ULE-copy > p,
.ULE-copy > ul{
  @extend .text-copy;
  margin-bottom: rem(20px);
  font-size: rem(20px);
  letter-spacing: 0.04em;
  line-height: 1.5em;
}
.ULE-copy u {
  text-decoration: none;
}
.ULE-copy a {
  @extend %body-copy-links;
}
.ULE-copy .ul-header {
  margin-bottom: 30px;
}
pre.line-numbers {
  word-wrap: inherit;
}