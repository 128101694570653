.about-founders-team{
	position: relative;
	width: 100%;
	z-index: 1;
	overflow: hidden;

	@include breakpoint(laptop) {
	    padding: 0 0 80px;
	}
	@include breakpoint(desktop) {
		padding: 0;
	}
}

// Placeholders
// blurb styles
%AFT-blurb {
	@extend %shoot-up-about;
	position: relative;
	transition-delay: .3s;
	$delay: .3s;
	@for $i from 0 to 4 {
		&:nth-child(#{$i}) {
			transition-delay: $delay;
		}
		$delay: $delay + .3s;
	}
	padding: 30px 18px 0;
	@include breakpoint(small){
		padding: 0;
	}
}
%AFT-blurb .AFT-blurb-ctn {
	width: 100%;
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translateX(-50%) translateY(-50%);

	// @media (min-width: 767px) and (orientation: portrait) {
	// 	position: static;
	// 	top: auto;
	// 	left: auto;
	// 	transform: none;
	// }
}
%AFT-blurb h2{
	@extend .header-headline;
	font-size: 14px;
	letter-spacing: .5em;
	color: $c-dark;
	text-transform: uppercase;
}
%AFT-blurb h1{
	@include font(sans, 3);
	font-size: 40px;
	margin: rem(9px) 0 rem(32px);
	line-height: 1em;

	@media (min-width: 767px) and (orientation: portrait) {
		font-size: 30px;
	}
}
%AFT-blurb h1 span{
	display: block;
}
%AFT-blurb p{
	@extend .text-copy;
	font-size: 20px;
	margin: 0;
	line-height: 1.5em;

	@media (min-width: 767px) and (orientation: portrait) {
		font-size: 18px;
	}
}
// Slider styles
%AFT-slideshow{
	position: relative;
	@extend %shoot-up-about;
	float: none;
	margin: rem(1px) 0;
	overflow: hidden;
	&:before{
		content: "";
		display: block;
	}
	backface-visibility: hidden;
	transition-delay: .3s;
	$delay: .3s;
	@for $i from 0 to 12 {
		&:nth-child(#{$i}) {
			transition-delay: $delay;
		}
		$delay: $delay + .3s;
	}
	iframe {
		width: 100%;
		height: 100%;
	}

	@include breakpoint(small) {
		float: left;
	}
}

.AFT{
	// Founder background image - before element controls height with a ratio of the with of 4:5
	&-background-image{
		position: relative;
		top: 0;
		z-index: -1;
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 75%;
		background-position: top center;
		background-size: cover;
		@include breakpoint(small) {
			position: absolute;
			height: 100%;
			padding: 0;
		}
	}
	&-background-image::before{
		content: "";
		display: block;
		padding-bottom: 98%;
	}

	// Founder blurb container - before and after gives ratio for margin around blurb
	&-founders{
		@extend %shoot-up-about;
		position: relative;
		width: 100%;
		margin-bottom: rem(70px);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-founders::before{
		content: "";
		display: block;
		padding-bottom: 30px;
		@include breakpoint(small) {
			padding-bottom: 23%;
		}
	}
	&-founders::after{
		content: "";
		display: block;
		padding-bottom: 5%;

		@include breakpoint(max) {
			padding-bottom: 10%;
		}
	}
	&-team {
		@include max-width();

		@include breakpoint(max) {
			position: relative;
			top: -125px;
			margin: 0 auto 0;
		}
	}

	// Rows
	&-row {
		@extend %cf;

		@include breakpoint(small) {
			&:nth-of-type(even) {
				margin: 3% 0;
			}
		}
	}

	// Blurbs
	&-blurb-1{
		@extend %AFT-blurb;
		@include col(12,12,none);

		@include breakpoint(small) {
			@include col(5,12,both,1);
		}
	}
	&-blurb-1 div {
		font-size: rem(20px);
		letter-spacing: 0.04em;
		line-height: 1.5em;
	}
	&-blurb-2{
		@extend %AFT-blurb;
		@include col(12,12,false);
		display: table;

		@include breakpoint(small) {
			@include col(5,12,false);
			float: left;
		}
	}
	&-blurb-2 &-blurb-ctn {
		display: table-cell;
		vertical-align: middle;
		padding: 0 30px;

		@include breakpoint(medium) {
			padding: 0 60px;
		}
	}
	&-blurb-3 {
		@extend %AFT-blurb;
		@include col(12, 12, false);
		display: table;

		@include breakpoint(small) {
			@include col(4.88, 12, false,0,.12);
			float: left;
		}
	}
	&-blurb-3 &-blurb-ctn {
		display: table-cell;
		vertical-align: middle;
		padding: 0 10px;

		@include breakpoint(medium) {
			padding-right: 60px;
		}
	}

	//Slide shows - before element controls the height for slideshows
	&-slides {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #333;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		overflow: hidden;
		&.cycle-sentinel {
			display: none;
		}
		& iframe {
			pointer-events: none;
		}
	}
	&-slideshow-1{
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 111.47%;
		}

		@include breakpoint(small) {
			@include col(3,12,false,0,.36);
		}
	}
	&-slideshow-2{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 86.07%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(3.64,12,false,0,.36); //4
			&:before {
				padding-bottom: 0;
			}
		}
	}
	&-slideshow-3{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 69.38%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(4.64,12,false); //5
			&:before {
				padding-bottom: 0;
			}
		}
	}
	&-slideshow-4{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before{
			padding-bottom: 82.71%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(4,12,false);
		}
	}
	&-slideshow-5{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 106.45%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(3,12,false);
			&:before {
				padding-bottom: 0;
			}
		}
	}
	&-slideshow-6{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before{
			padding-bottom: 49.44%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(7,12,false,0,.36);
		}
	}
	&-slideshow-7{
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 70%;
		}

		@include breakpoint(small) {
			@include col(4.64,12,false); // 5
			&:before {
				padding-bottom: 0;
			}
		}
	}
	&-slideshow-8{
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 97.43%;
		}

		@include breakpoint(small) {
			@include col(3.64,12,false,0,.36);
		}
	}
	&-slideshow-9{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 122.58%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(3,12,false);
			&:before {
				padding-bottom: 0;
			}
		}
	}
	&-slideshow-10{
		display: none;
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 67.79%;
		}

		@include breakpoint(small) {
			display: block;
			@include col(5.64,12,false,0,.36);
		}
	}
	&-slideshow-11{
		@extend %AFT-slideshow;
		@include col(12,12,false);
		&:before {
			padding-bottom: 67.79%;
		}

		@include breakpoint(small) {
			@include col(6,12,false);
			&:before {
				padding-bottom: 0;
			}
		}
	}
}
.video-slide-container{
	height: 0;
}
