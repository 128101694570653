.error- {
	&content {
		max-width: rem(700px);
	}
	&title {
		display: inline-block;
		padding: rem(30px) rem(110px);
		color: $c-dark;
		background-color: $c-light;
		font-size: rem(30px);
		letter-spacing: 0.2em;
	}
	&ctn {
		background-color: $c-light;
		padding: rem(50px) rem(30px);
	}
	&ctn h3 {
		margin-top: 0;
		color: $c-dark;
		font-size: rem(40px);

		@include breakpoint(medium) {
			font-size: rem(54px);
		}
	}
	// &ctn a {
	// 	display: inline-block;
	// 	border: 1px solid $c-dark;
	// 	padding: rem(15px) rem(30px);
	// 	color: $c-dark;
	// 	text-transform: uppercase;
	// 	font-size: rem(14px);
	// 	@include font(sans, 3);
	// 	letter-spacing: 0.6em;
	// 	text-decoration: none;
	// }
}
.hero-image iframe {
	width: 100%;
	height: 100%;
}