// Hero
.AH-content{
	@include col(6,8,false);
	max-width: 850px;
}
.AH-main-title {
	margin: 0;
}
.AH-header{
	margin: 0 0 10px;
}

// info
.about-info{
	@include max-width();
	margin: rem(40px) 0;
	padding: 0 18px;
	@include breakpoint(small){
		margin-top: 0;
		margin-bottom: rem(140px);
		padding: rem(140px) 0 0;
	}
	@include breakpoint(medium) {
		padding: rem(140px) 10px 0;
	}
	@extend %shoot-up-element;
}
.AI-small-content p {
	@include font(freight, 0, none);
	font-size: rem(18px);
	line-height: 32px;
	letter-spacing: 0.04em;
	margin: 0 0 rem(24px);
	&:nth-of-type(2) {
		padding-bottom: rem(20px);
	}
	@include breakpoint(medium) {
		font-size: rem(22px);
		margin: 0 0 rem(20px);
	}
}
.AI-small-content a {
	font-size: 15px;

	@include breakpoint(small) {
		font-size: 14px;
	}
	@include breakpoint(medium) {
		font-size: 16px;
	}
}
.AI-header {
	font-style: normal;
	@include font(sans, 3);
	letter-spacing: 0;
	margin: rem(20px) 0 rem(48px);
	font-size: 40px;
	line-height: 44px;
	@include breakpoint(small) {
		padding: 0 rem(8px);
		margin: rem(20px) 0;
	}
	@include breakpoint(medium) {
		font-size: rem(56px);
		padding: 0 rem(50px) 0 0;
		margin: rem(20px) 0;
		line-height: rem(55px);
	}
}

// press
.about-press{
	@include col(1,1,false);
	@include max-width();
	margin-top: rem(100px);
	margin-bottom: rem(32px);
	@include breakpoint(small) {
		margin-bottom: rem(135px);
	}
}
.press-track > div {
	display: inline-block;
	@extend %shoot-up-about;
	$delay: 0s;
	@for $i from 0 to 5 {
		&:nth-child(#{$i}) {
			transition-delay: $delay;
		}
		$delay: $delay + .3s;
	}
}
.AP-header{
	@extend %shoot-up-about;
	margin-bottom: rem(50px);
	text-align: center;
	font-size: 14px;
  	letter-spacing: 0.5em;
  	text-transform: uppercase;
  	color: $c-dark;
}

// slider
.about-slider{
	@extend %shoot-up-element;
	padding-bottom: rem(40px);
	border-bottom: solid 1px $c-med-grey;
	margin: rem(40px) 0 rem(60px);
	@include breakpoint(small){
		margin: rem(100px) 0 rem(100px);
	}
}
.AS-btns{
	&::before{
		content: "";
		display: block;
		height: 0;
		padding-bottom: 50%;
		opacity: 0;
	}
}
.AS-photo{
	position: relative;
	background-position: center;
	background-size: cover;
  overflow: hidden;
	
	>iframe{
		display: none;
	}
	
	&::before{
		content: "";
		display: block;
		height: 0;
		padding-bottom: 50%;
		opacity: 0;
	}
	
	&::after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		height: 100%;
		width: 100%;
		opacity: 0;
	}
	
	@include breakpoint(small){
		>iframe{
			@include center(vh);
			position: absolute;
			display: block;
		}
	}
	
}
.AS-header{
	margin: 0 0 rem(20px);
	text-align: left;
}
.AS-text{
	margin: rem(20px) 0;
	text-align: left;
}
.AS-button{
	margin-top: rem(0px);
}
.AS-content{
	position: relative;
	overflow: hidden;
}
.AS-content-left{
	margin-bottom: rem(107px);
	@include breakpoint(small){
		@include col(8,12,false);
			margin-bottom: 0;
	}
}
.AS-content-right{
	@include center(h);
	position: absolute;
	bottom: 0;
	@include breakpoint(small){
		@include center(v);
		left: auto;
		@include col(4,12,false,8);
		
	}
}

// team
.about-team{
	@extend %shoot-up-element;
	@include max-width();
	padding-bottom: rem(20px);
	border-bottom: solid 1px $c-med-grey;
	margin-top: rem(40px);
	margin-bottom:  rem(60px);
	
	@include breakpoint(small){
		margin-top: rem(100px);
		margin-bottom:  rem(100px);
		padding-bottom: rem(100px);
	}
}
.AT-header{
	text-align: center;
	margin: 0 0 rem(10px);
}
.AT-subtext{
	text-align: center;
	margin: rem(10px) 0 rem(40px);
	@include breakpoint(small){
		margin-bottom: rem(60px);
	}
}
.AT-members-ctn{
	@extend %cf;
	@include breakpoint(small){
		@include col(11,12,false);
		margin: 0 auto;
		padding: 0;
	}
}
.AT-member{
	@include col(1,2,both);
	margin-bottom: rem(20px);
	float: left;
	@include breakpoint(small){
		@include col(1,4,false);
		padding: 0;
	}
}
.ATM-photo{
	position: relative;
	display: block;
	height: rem(140px);
	width: 100%;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	>iframe{
		display: none;
	}
	@include breakpoint(small){
		height: rem(300px);
		>iframe{
			@include center(vh);
			position: absolute;
			display: block;
			pointer-events: none;
		}
	}
}
.ATM-name{
	font-size: rem(16px);
	line-height: 1.1em;
	margin: rem(10px) 0 rem(5px);
	@include breakpoint(small){
		padding-top: rem(30px);
		padding-right: rem(20px);
		margin-bottom: rem(10px);
		&:before{
			content: "";
			display: block;
			height: rem(2px);
			width: rem(60px);
			margin-bottom: rem(30px);
			background: $c-med-grey;
		}
	}
}
.ATM-position{
	font-size: rem(12px);
	line-height: 1.3em;
	margin: 0;
	@include breakpoint(small){
		padding-right: rem(20px);
	}
}