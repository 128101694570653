// Covers parent
%cover-all {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Center Blocks
%center-v{
	top: 50%;
	transform: translateY(-50%);
}
%center-h{
	left: 50%;
	transform: translateX(-50%);
}
%center-vh{
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	transform: translate3d(-50%,-50%,0);
}

%cf2{
	overflow: hidden; // instead of clearfix, so margins still work
}

// Clear Fix
// @extend %cf;

%cf{
	*zoom: 1;

	&:before,
	&:after {
		content: " "; /* 1 */
		display: table; /* 2 */
	}

	&:after {
		clear: both;
	}
}
%image-lock-up-ctn{
	@include col(1,1,both);
	position: relative;
	z-index: 1;
	margin: rem(40px) 0;
	background: $c-light;
	box-shadow: 0 0 rem(20px) rem(20px) $c-light;
}
%image-lock-up-mobile{
	display: block;
	width: 100%;
	margin-bottom: rem(10px);
	background-color: $c-med-grey;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	&:before{
		content: "";
		display: block;
		height: 0;
		padding-bottom: 56%;
		opacity: 0;
	}
}

%shoot-up-element{
	opacity: 1;
	@include breakpoint(medium) {
		transform: translateY(rem(50px));
		opacity: 0;
		transition: transform .5s, opacity .5s;
		// transform: translateY(rem(200px));
		// transition: transform .s, opacity .3s;
		&.track-visible{
			transform: translateY(0);
			opacity: 1;
		}
	}
}

%shoot-left {
	opacity: 1;
	@include breakpoint(medium) {
		transform: translateX(rem(-100px));
		opacity: 0;
		transition: transform .8s, opacity .5s;
		&.track-visible{
			transform: translateX(0);
			opacity: 1;
		}
	}
}

%shoot-up-about {
	opacity: 1;
	@include breakpoint(medium) {
		transform: translateY(rem(50px));
		opacity: 0;
		transition: transform .5s, opacity .5s;
		&.track-visible{
			transform: translateY(0);
			opacity: 1;
		}
	}
}

%body-copy-links {
	position: relative;
	z-index: 1;
	display: inline-block;
	font-weight: bold;
	line-height: 1.25em;
	color: $c-dark;
	text-decoration: none;
	border-bottom: 1px solid $c-dark;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		display: block;
		width: 0;
		height: 100%;
		background-color: #ddd;
		transition: .3s ease-out;
	}
	&:hover{
		&:before {
			width: 100%;
			transition: .2s ease-out;
		}
	}
}
