.page-footer{
	@include breakpoint(small){
		background: #000;
		position: relative;
		z-index: 10;
	}
}

// Contact section
.PF-join-us,
.PF-contact{
	@include col(1,1,true);
	position: relative;
	height: rem(225px);
	@include breakpoint(small){
		height: rem(380px);
	}
}
.new-business-form-submitted {
	.PF-contact {
		height: 0 !important;
		overflow: hidden;
		opacity: 0;
		transition: none;
	}
}
.PFJ-background,
.PFC-background{
	position: relative;
	z-index:1;
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	&.holiday {
		background-position: 0 90%;
		opacity: 0.5;
	}
}
.PFC-background iframe {
	position: absolute;

	@include breakpoint(medium) {
		//top: -50%; removed to straighten out video
	}
}
.PFJ-background {
	background-position: top;
}
.PFJ-link,
.PFC-link{
	position: absolute;
	top: 0;
	left: 0;
	z-index:1;
	width: 100%;
	height: 100%;
	cursor: pointer;

	.underlined-button-new {
		display: none;
		@include breakpoint(small){
			display: inline-block;
			@include center(vh);
			padding-top: 34px;
			z-index: 1;
		}
	}
	.underlined-button-new .rectangle{
		width: 23px;
	}
	.underlined-button-new svg{
		right: 0;
		opacity: 1;
		transition: opacity 0.3s ease 0.5s, right 0.3s ease 0.5s;
	}
	.underlined-button-new::after{
		width: 100%;
	}

	@include breakpoint(medium) {
		&.birdwatch:after {
			background-color: #f6f6f6;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $c-dark;
			transition: opacity 0.3s ease;
			opacity: 0;
		}
		&:hover:after {
			opacity: 0.5;
		}
		.underlined-button-new {
			opacity: 0;
			top: 52%;
			transition: all 0.3s ease;
		}
		&:hover .underlined-button-new {
			opacity: 1;
			top: 50%;
		}
	}
}
button.PFC-link {
	display: block;
  padding: 0;
  background: transparent;
  outline: 0;
	border: 0;
  border-radius: 0;
  .underlined-button-new {
  	position: absolute;
		@include center(h);
  }
}
.PFJ-header,
.PFC-header{
	@extend %center-vh;
	position: absolute;

	@include breakpoint(small) {
		white-space: nowrap;
	}
}

.PFC-header {
	font-size: 40px;
	line-height: 1em;
	width: 200px;
	&:after {
		display: none;
	}
	@include breakpoint(small){
		width: auto;
	}
}

.PFJ-header {
	font-size: rem(14px);
	color: #fff;
	padding: 20px;
	border: 2px solid #fff;
	white-space: nowrap;
	&:before {
		display: none;
	}
	&:hover {
		color: $c-dark;
	}

	@include breakpoint(small) {
		padding: 35px;
	}
	@include breakpoint(medium) {
		&:before {
			display: block;
			background-color: #fff;
		}
	}
}

// New Business section
.PF-start {
	&{
		padding: 40px 0;
		background: $c-dark;
		text-align: center;
		@include breakpoint(small){
			padding: 40px 0 10px;
		}
	}
	h1 {
		@include font(sans);
		margin: 0 auto 20px;
		font-size: 24px;
		color: $c-light;
	}
	a {
		display: inline-block;
		text-align: center;
		@include font(sans);
		font-size: 16px;
		border: 2px solid $c-light;
		color: $c-light;
		text-decoration: none;
		padding: 20px;

		@include breakpoint(small) {
			width: 300px;
			padding: 20px 0;
		}
		@include breakpoint(medium) {
			transition: all 0.3s ease;

			&:hover {
				background-color: $c-light;
				color: $c-dark;
			}
		}
	}
}

// Sign up section
.PF-sign-up{
	padding: rem(40px) 0 0;
	background: $c-dark;
	text-align: center;
	@include breakpoint(small){
		padding: rem(64px) 0 rem(8px);
	}
}
.PFS-header-title,
.PFS-header-copy {
	display: block;
	text-align: center;
	font-style: normal;
}
.PFS-header-title {
	@include font(sans);
	margin: 0 auto;
	font-size: rem(24px);
	color: #fff;
}
.PFS-email-form{
	position: relative;
	display: block;
	width: 50%;
	padding: 0;
	margin: 10px auto 0;
	text-align: center;
	-webkit-font-smoothing: antialiased;

	@include breakpoint(small) {
		width: 100%;
	}
}
.PFS-email-input {
	width: 100%;
	@include font(sans);
	border: none;
	border-radius: 0;
	border-bottom: 1px solid $c-light;
	padding: 0 40px 10px 0;
	outline: none;
	color: $c-light;
	overflow: hidden;
	background-color: transparent;
	font-size: rem(12px);

	@include breakpoint(small) {
		color: $c-light;
		background-color: $c-dark;
		border-bottom: 1px solid $c-light;
	}
}
.invalid .PFS-email-input{
	border-color: #c50a08;
	&:focus{
		border-color: $c-med-grey;
	}
}
.PFS-email-submit {
	position: absolute;
	top: 5px;
	right: 0;
	width: 25px;
	height: 6px;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
  transform: rotate(180deg);
	svg {
		position: absolute;
		top:0;
		right:0;
		fill: $c-light;
		width: 100%;
		height: 100%;
	}

	@include breakpoint(small) {
		svg {
			fill: $c-light;
		}
	}
}
.valid .PFS-email-submit{

}
.invalid .PFS-email-submit{

}
.valid.invalid .PFS-email-submit{
	background: rgba(#fff,0.2);
}
.subscribe-message-ctn {
	position: relative;
	width: 100%;
	padding: 10px 0 0;
	@include breakpoint(small){
		position: absolute;
	}
}
#fps-subscribe-message {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding: 0;
	@include font(sans, 3);
	font-size: rem(10px);
	line-height: 1em;
	letter-spacing: 0.02em;
	opacity: 0.7;
}
// Footer info
.footer-info{
	@extend %cf;
	padding: 30px 0 0;
	@include breakpoint(small){
		padding: rem(65px) 0 rem(30px) 0;
		overflow: hidden;
		background-color: $c-dark;
	}
}

// Three footer boxes
%PFI-section{
	position: relative;
	display: block;
	text-align: center;
	@include breakpoint(small){
		float: left;
		color: $c-dark-grey;
		text-align: left;
		height: 140px;
		.PFI-wrap {
			white-space: nowrap;
			position: absolute;
			@include center(vh);
		}
	}
}

// Contact box
.PFI-header {
	@extend %PFI-section;
	margin-bottom: 20px;
	@include breakpoint(small){
		@include col(1,4,false);
		margin: 0;
	}
}

.PFI-header h1 {
	margin: 0;
	margin-bottom: 10px;
	color: $c-dark;
	@include font(sans);
	font-size: 40px;

	@include breakpoint(small) {
		color: #EDEDED;
	}
}

// Company box
.PFI-company{
	@extend %PFI-section;
	margin-bottom: 20px;
	@include breakpoint(small){
		@include col(1,4,false);
		border-left: 1px solid #787878;
		border-right: 1px solid #787878;
		margin: 0;
	}
}
.PFIC-text{
	font-size: rem(16px);
	letter-spacing: 0.06em;
	margin-bottom: 4px;
	text-align: center;

	@include breakpoint(small) {
		text-align: left;
	}
	@media (min-width: 767px) and (orientation: portrait) {
		font-size: rem(14px);
	}
}
.PFIC-text,
.PFIC-text a{
	letter-spacing: 0.06em;
	color: $c-dark;
	text-decoration: none;
	margin: 0;
	@include breakpoint(small){
		color: #EDEDED;
	}
}
.PFIC-link {
	text-decoration: none;
	.PFIC-header,
	.PFIC-text {
		transition: color 0.3s ease;
	}
	&:hover .PFIC-header,
	&:hover .PFIC-text {
		color: #767676;
	}
}
// Contact box
.PFI-contact{
	@extend %PFI-section;
	text-align: center;
	margin-bottom: 30px;
	@include breakpoint(small){
		margin-bottom: 20px;
		@include col(1,4,false);
		text-align: left;
		margin: 0;
	}
}
.PFI-contact a{
	display: block;
	font-size: rem(16px);
	letter-spacing: 0.06em;
	text-decoration: none;
	margin-bottom: rem(4px);
	color: $c-dark;
	transition: .3s ease-out;
	@include breakpoint(small){
		color: #EDEDED;
		&:hover{
			color: #767676;
		}
	}
	@media (min-width: 767px) and (orientation: portrait) {
		font-size: rem(14px);
	}
}
.PFI-contact i{
	margin-right: rem(4px);
	font-size: rem(16px);
	font-style: normal;
	text-transform: lowercase;

	@media (min-width: 767px) and (orientation: portrait) {
		font-size: rem(14px);
	}
}
// Social links
.PFI-section{
	@extend %PFI-section;
	display: block;
	font-size: 0;
	text-align: center;
	position: relative;
	background-color: $c-dark;
	padding: 30px 0;
	@include breakpoint(small){
		background-color: transparent;
		padding: 0;
		@include col(1,4,false);
		height: 140px;
		float: right;
		border-left: 1px solid #787878;
	}
}
.PFI-section .PFI-wrap {
	white-space: normal;

	@include breakpoint(small) {
		width: 75%;
	}
	@include breakpoint(medium) {
		width: 60%;
	}
}
.PFI-section h3 {
	@include font(sans);
	color: $c-light;
	margin: 0;
	font-size: 22px;

	@include breakpoint(small) {
		text-align: left;
	}
	@include breakpoint(medium) {
		font-size: 26px;
	}
}
.PFI-section p {
	color: #EDEDED;
	margin: 10px 0 20px;
	font-size: 16px;
	letter-spacing: 0.04em;

	@include breakpoint(small) {
		text-align: left;
		font-size: 14px;
	}
	@include breakpoint(medium) {
		font-size: 16px;
	}
}
.PF-social {
	text-align: center;
	padding: 0 0 20px;
	background-color: $c-dark;
}
.PF-social a{
	position: relative;
	display: inline-block;
	height: rem(26px);
	width: rem(26px);
	border: rem(2px) solid #EDEDED;
	margin: 0 rem(6px);
	border-radius: 100%;
	transition: .3s ease-out;
	&:hover{
		border-color: #767676;
		svg{
			fill: #767676;
		}
	}
	@include breakpoint(small) {
		border-color: #EDEDED;
		margin: 0 5px;
	}
	@include breakpoint(medium) {
		margin: 0 rem(6px);
	}
}
.PF-social svg{
	@extend %center-vh;
	position: absolute;
	display: block;
	height: rem(12px);
	width: rem(12px);
	fill: #EDEDED;
	@include breakpoint(small){
		fill: #EDEDED;
	}
	@include breakpoint(medium) {
		transition: .3s ease-out;
	}
}
.PF-legal {
	background-color: $c-dark;
	padding-top: 10px;
	padding-bottom: 40px;
	text-align: center;
	span,
	a {
		@include font(sans);
		color: #767676;
		font-size: 12px;
		-webkit-font-smoothing: antialiased;

	}
	span {
		padding-right: 15px;
	}
	a {
		padding: 0 15px;
		border-left: 1px solid #787878;
		text-decoration: none;
		transition: all 0.3s ease;
		&:hover {
			color: $c-light;
		}
	}
	@include breakpoint(small){
		padding-top: 0;
	}
}
