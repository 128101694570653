%birdwatch-outer{
	margin: 30px 0;
	padding: 0 30px 30px;
	border-bottom: rem(1px) solid rgba(107, 107, 107, 0.4);
	@include breakpoint(medium) {
		display: block;
		width: 78%;
		max-width: rem(1300px);
		padding: 0 0 80px;
		margin: 80px auto;
	}
  div{
    @include breakpoint(medium) {
      display: inline-block;
      width: grid(5.5);
      vertical-align: text-top;
    }
  }
}
%birdwatch-subtitle {
	font-family: 'NeuzeitSLTW01-BookHeavy';
	color: #848484;
	margin: 0 0 15px;
	font-size: 18px;
	letter-spacing: 0.04em;

	@include breakpoint(medium) {
		font-size: 22px;
	}
}
%birdwatch-title{
	margin: 0 0 15px;
	& {
		@include font(sans);
		font-size: 42px;
		line-height: 1em;
	}
	&::after{
		content: "";
		display: block;
		height: rem(3px);
		width: rem(54px);
		background-color: #dadada;
		margin: 30px 0 0;
	}
	@include breakpoint(medium){
		margin: 0 0 30px;
		&{
			@include font(sans);
		    font-size: 50px;
				line-height: 1em;
		}
	}
}
%bw-header{
  color: #282828;
  font-family: 'NeuzeitSLTW01-BookHeavy';
  line-height: 0.8823;
  font-size: 40px;
  margin: 0;
  font-weight: 300;
  @include breakpoint(medium) {
    font-size: 50px;
    line-height: 1em;
  }
}
%bw-text{
  color: #848484;
  font-family: 'Freight Big W01 Light Regular', serif;
  font-size: 18px;
  line-height: 1.4em;
  letter-spacing: 0.04em;
  @include breakpoint(medium){
    font-size: 22px;
  }
  @include breakpoint(max) {
    margin: 10px 0 20px;
  }
}
%bw-carousel{
	// Slider control buttons
	.bw-lightbox-next{

	}
	.slide{
		position: relative;
		width: 100%;
		margin: 0 auto;
	}
	%btn{
		position: absolute;
		top: 20%;
		display: block;
		background: transparent;
		z-index: 200;
		transform: translateY(-50%);

		@include breakpoint(medium) {
			top: 200px;
			transform: none;
		}
	}
	%btn button{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		height: 100%;
		width: 100%;
		border: none;
		opacity: 0;
		outline: none;
	}
	%btn svg{
		width: 30px;
		fill: $c-dark;
		transition: .3s;
	}
	%btn button:hover + svg,
	%btn button:focus + svg{
		fill: $c-dark-grey;
	}
	.prev-lightbox{
		@extend %btn;
		left: 0;
		svg{
			transform: rotate(-180deg);
		}

		@include breakpoint(small) {
			left: 20px;
		}
		@include breakpoint(medium) {
			left: auto;
			right: calc(100% + 20px);
		}
	}
	.next-lightbox{
		@extend %btn;
		right: 0;

		@include breakpoint(small) {
			right: 20px;
		}
		@include breakpoint(medium) {
			right: auto;
			left: calc(100% + 20px);
			margin-left: -100px;
		}
	}
	img{
		display: block;
		max-width: 100%;
		max-height: 200px;
		margin: 0 auto;

		@include breakpoint(small) {
			max-height: 500px;
		}

		@include breakpoint(medium) {
			max-height: 600px;
			padding-right: 100px;
		}
	}
	.captions h2{
		@include font(sans);
		font-size: 20px;
		letter-spacing: .002em;
		color: $c-dark;
		margin: 40px 0 0;
	}
	.captions p{
		margin: 0;
		width: 75%;

		@include breakpoint(medium) {
			width: 100%;
		}
	}
	.bw-lightbox-pager{
		@include font(freight);
		position: absolute;
		right: 0;
		bottom: -45px;
		padding-left: 20px;
		font-size: 28px;
		color: $c-dark;

		@include breakpoint(medium) {
			bottom: auto;
			right: 100px;
			transform: translate(100%, -100%);
		}
	}
	.bw-lightbox-pager .slide-pager{
		border-left: 1px solid $c-dark;
	}
	.bw-lightbox-pager .current-slide{
		padding: 10px 30px 10px 10px;
		border-bottom: 1px solid $c-dark;
	}
	.bw-lightbox-pager .total-slide{
		padding: 10px;
	}
}
