.site-slider{
	@extend %cf;
	position: relative;
	z-index: -1;
}
.SS-slider-track{
	z-index: 1;
}
.SS-btns{
	position: absolute;
	z-index: 2;
	width: 100%;
	pointer-events: none;
}
.SS-btn{
	bottom: 0;
	margin: rem(4px);
	pointer-events: all;
	@include breakpoint(small){
		@include center(v);
	}
}
.SS-pager{
	@extend %cf;
	@include col(1,1,both);
	@include font(serif, 0);
	margin-top: rem(40px);
	@include breakpoint(small){
		@include col(1,12,false);
		padding: 0;
		float: left;
	}
	.SS-pager-active{
		padding: rem(10px) rem(4px) rem(16px) 0;
		border-right: rem(1px) solid $c-med-grey;
		margin-right: rem(4px);
		float: left;
		font-size: rem(26px);
		line-height: .8em;
		@include breakpoint(small){
			width: 100%;
			border-bottom: rem(1px) solid $c-med-grey;
			border-right: rem(1px) solid $c-med-grey;
			margin-right: rem(4px);
			float: none;
			font-size: rem(40px);
			line-height: .8em;
			text-align: center;
		}
	}
	.SS-pager-total{
		color: $c-med-grey;
		padding: rem(10px) rem(4px) rem(16px) 0;
		float: left;
		font-size: rem(26px);
		line-height: .8em;
		@include breakpoint(small){
			width: 100%;
			border-right: rem(1px) solid $c-med-grey;
			float: none;
			text-align: center;
		}
	}
}
.SS-slide{
	height: 100%;
	width: 100%;
}
.SS-photo{
	@include col(1,1,false);
	background: $c-med-grey;
}
.SS-content{
	@include col(1,1,both);
	margin: rem(20px) 0 0;
	text-align: center;
	@include breakpoint(small){
		@include col(10,12,right,1);
		padding-left: 0;
		margin-top: rem(40px);
		float: left;
	}
}