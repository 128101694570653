// Functions
@function grid($columns:12, $grid:12){
  @return ((100/$grid) * $columns) + 0%;
}
$circle-size: 60px;

// annotation track
.annotation-track{
  &{
    position: absolute;
    left: grid(9);
    top: 0;
    bottom: 0;
    z-index: 3;
    width: grid(2);
  }
  & header > * {
    transform: translateY(rem(50px));
    opacity: 0;
    transition: transform .5s, opacity .5s;
  }
  &.track-visible header > * {
    transform: translateY(0);
    opacity: 1;
  }
  header{
    margin-left: 2px;
    text-align: center;
  }
  header > span{
    @include font(serif);
    font-size: 18px;
    letter-spacing: .05em;
    color: $c-dark;
    transition-delay: 0.1s;
  }
  header > h3{
    @include font(sans);
    font-size: 10px;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: $c-dark;
    margin: 10px 0;
    transition-delay: 0.2s;
  }
  header > .arrow{
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid $c-dark;
    margin: 15px auto 0;
    transition-delay: 0.3s;
  }
  .track{
    position: absolute;
    top: 69px;
    bottom: 0;
    width: 100%;
    background-image: url(http://s3.amazonaws.com/thecharles2.0/uploads/global/circle-spacer.gif);
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 2px;
    opacity: 0;
    transition: opacity 0.3s ease 0.8s;
  }
  &.track-visible .track {
    opacity: 1;
  }
  .track::after{
		content: "";
		display: block;
  	width: 8px;
  	height: 8px;
  	border-radius: 100%;
  	background-color: black;
 		position: absolute;
  	bottom: 0;
  	left: 50%;
  	transform: translate(-50%);
  }
  .track > *{
    position: absolute;
    width: 100%;
    @extend %shoot-up-element;
    transform: translateY(rem(70px));
    &.track-visible {
      .annotation-open .id-text,
      .annotation-open .grey-text {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

// annotation icons
.annotation-open{
  $icon-size: 38px;
  &{
    @include center(vh);
    position: absolute;
    z-index: 10;
    display: block;
    height: $icon-size;
    padding: 0;
    border: none;
    // border-radius: 100%;
    outline: none;
    transform: translateY(-50%) scale(1);
    transition: .3s;
    background: transparent;
    overflow: visible;
  }
  &.active{
  	transform: translateY(-50%) scale(0);
  }
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: $icon-size;
    width: $icon-size;
    border-radius: 100%;
    background: $c-dark;
  	transform: translateX(-50%);
  }
  .circle{
    content: "";
    cursor: pointer;
    position: absolute;
    top: 50%;
    height: $icon-size;
    width: $icon-size;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }
  .circle::before{
    content: "";
    position: absolute;
    top: ($icon-size/100)*6;
    left: ($icon-size/100)*6;
    height: ($icon-size/100)*88;
    width: ($icon-size/100)*88;
    border: 3px solid $c-dark;
    border-radius: inherit;
    box-sizing: border-box;
    transform: scale(1.2);
    transition: .2s;
  }
  &:hover .circle::before{
  	transform: scale(1.3);
  }
  .icon-title{
    cursor: pointer;
  	padding: 10px 0 10px 30px;
  	text-align: left;
    transform: translateY(-10px);
  }
  .icon-title .id-text{
  	@include font(sans);
  	display: block;
    color: $c-dark;
  	font-size: 16px;
  	line-height: 20px;
  	margin-bottom: 2px;
    transform: translateX(rem(-10px));
    opacity: 0;
    transition: transform .5s, opacity .5s;
    transition-delay: 0.3s;
  }
  .icon-title .grey-text{
  	display: inline-block;
  	font-size: 16px;
  	color: #555555;
    transform: translateX(rem(-10px));
    opacity: 0;
    transition: transform .5s, opacity .5s;
    transition-delay: 0.6s;
  }
  .icon-title .grey-text::after{
  	content: "";
  	display: block;
  	width: 100%;
  	height: 1px;
  	background: #555555;
  	margin-top: 0;
  }
	.icon {
		@include center(vh);
    position: absolute;
    left: 0;
    height: ($icon-size/100)*30;
    width: ($icon-size/100)*30;
	}
  svg{
		position: absolute;
		top: 0;
		left: 0;
  	fill: #fff;
  	stroke: #fff;
  	stroke-width: .25px;
  }
  button.annotation-button {
    outline: none;
    border: none;
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -25px;
    padding: 0;
  }
}
@keyframes pulse{
	0% {
		transform: translate(-50%,-50%) scale(0.2);
  		opacity: 0;
  	}
  	25% {
  		opacity: 1;
  	}
  	50% {
  		transform: translate(-50%,-50%) scale(1);
  		opacity: 0;
  	}
  	100%{
  		opacity: 0;
  	}
}


// annotations
.case-study-module{
  position: relative;
  width: 100%;
}
.annotation-container{
  &{
    position: absolute;
    right: 0;
    z-index: 900;
    height: 0;
    width: 100%;
  }
  &.active{
    z-index: 1000;
  }
}
%arrow{
  content:"";
  position: absolute;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
%annotation-button{
  position: absolute;
  top: 0;
  right: 40px;
  height: 30px;
  width: 30px;
  background: #fff;
  border: 1px solid $c-dark;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  &::before{
    @extend %arrow;
    left: -4px;
    border-right:4px solid $c-dark;
  }
  &::after{
    @extend %arrow;
    left: -3px;
    border-right:4px solid #fff;
  }
}

// Expanded Annotation Styles
%annotation{
  &{
    position: absolute;
    right: 0;
    z-index: 200;
    display: block;
    padding: 50px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 50px rgba($c-dark, .1);
    opacity: 0;
    transform: scale(0);
    transform-origin: calc(100% - 40px) 0;
    transition: .3s;
    border-radius: 2px;
  }
  & > *{
    opacity: 0;
    transform: translateY(50px);
    transition: .6s;
  }
  .active &{
    opacity: 1;
    transform: scale(1);
  }
  .active & > *{
    opacity: 1;
    transform: translateY(0);
    transition-delay: .3s;
  }
  .annotation{}
  .annotation header{
    position: relative;
  }
  .annotation .full{
    height: $circle-size;
  }
  .annotation .small{
  }
  .photo{
    position: absolute;
    display: block;
    height: $circle-size;
    width: $circle-size;
    border-radius: 100%;
    background-color: #ccc;
    background-position: center;
    background-size: cover;
  }
  .text{
    padding-top: 12px;
  }
  .title{
    @include font(sans);
    margin: 0 0 10px;
    font-size: 14px;
    letter-spacing: .2em;
    line-height: 12px;
    text-transform: uppercase;
    color: #888888;
  }
  .name{
    @include font(sans);
    margin: 0 0 10px $circle-size+10px;
    font-size: 14px;
    letter-spacing: .25em;
    line-height: 12px;
    text-transform: uppercase;
    color: #888888;
  }
  .position{
    @include font(serif);
    margin-left: $circle-size+10px;
    font-size: 14px;
    letter-spacing: .2em;
    line-height: 12px;
    letter-spacing: .05em;
    color: #888888;
  }
  .content{
    // padding-top: 30px;
    letter-spacing: .05em;
  }
  // .content::before{
  //   content: "";
  //   display: block;
  //   height: 1px;
  //   width: 100%;
  //   background: #ccc;
  //   margin-bottom: 30px;
  // }
  .content > h1{
    @include font(sans);
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    color: $c-dark;
  }
  .content > h1::after{
    display: none;
  }
  .content > p{
    @include font(frieght);
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    color: $c-dark;
  }
  .content > .grey-text{
    @include font(serif);
    color: #888888;
  }
  .content a{
    color: #888888;
  }
}

.video-annotation{
  &{
    @extend %annotation;
    max-width: 800px;
    width: grid(8);
  }
  .video-container{
    display: block;
    min-height: 100px;
    width: grid(1,1);
    margin-top: 40px;
    float: left;
  }
  .video-container video{
		display: block;
		width: 100%;
    border: 1px solid #ddd;
  }
  .annotation{
    width: grid(1,1);
    float: left;
  }
}
.team-annotation{
  &{
    @extend %annotation;
    max-width: 800px;
    width: grid(8);
  }
}
.general-annotation{
  &{
    @extend %annotation;
    max-width: 800px;
    width: grid(8);
  }
}

// exit button
.annotation-exit{
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  .circle{
  	@include center(vh);
  	position: absolute;
  	height: 24px;
  	width: 24px;
  	border-radius: 100%;
  	border: 1px solid #ccc;
  	transform: translate(-50%,-50%) scale(0);
  	transition: .3s;
  }
  &:hover .circle{
  	transform: translate(-50%,-50%) scale(1);
  }
  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 12px;
    width: 1px;
    background: #7A7A7A;
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 12px;
    width: 1px;
    background: #7A7A7A;
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}

// Overlay
.annotation-over{
	&{
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 7;
		display: block;
		transform: translateY(-100%);
	}
	.annotation-opened &{
		transform: translateY(0%);
	}
	&::before{
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($c-light, .95);
		opacity: 0;
		transition: .3s;
	}
	.annotation-opened &::before{
		opacity: 1;
	}
}
