.half-half-text{
	@extend %cf;
	@include col(1,1,both);
	margin: 0;
	@include breakpoint(small){
		position: relative;
		margin: rem(40px) 0;
	}
}
.HHT-large-content{
	@include col(1,1,false);
	text-align: center;
	@include breakpoint(small){
		@include col(1,2,false);
		@include center(v);
		position: absolute;
		padding: 0 rem(20px);
		float: left;
		text-align: left;
	}
	@include breakpoint(medium){
		padding: 0 rem(40px);
	}
}
.HHT-large-content p{
	text-align: center;
	@include breakpoint(small){
		text-align: left;
	}
}
.HHT-small-content{
	@include col(1,1,false);
	@include breakpoint(small){
		@include col(1,2,false, 1);
		padding: 0 rem(20px);
		border-left: 1px solid $c-med-grey;
		float: left;
		text-align: left;
		font-size: 16px;
	}
	@include breakpoint(medium){
		padding: 0 rem(40px);
	}
}