html{
	box-sizing: border-box;
	&:not(.system-windows) body{
		@include font-smoothing(false);
	}
}
*, *:before, *:after{
	box-sizing: inherit;
}
body{
	@include font(freight, 0, none);
	font-weight: 600;
	color: $c-global;
	opacity: 1;
	background: #fff;
	position: relative;
	@include breakpoint(small){
		font-weight: 400;
	}
	&.page-loading,
	&.page-page-transition {
		pointer-events: none;
	}
}
.max-width{
	@include max-width();
}
.scroll-track{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: transform .8s;
	@include breakpoint(small){
		padding: rem(40px);
	}
}
main{
	display: block;
	position: relative;
	z-index: 1;
	margin-top: rem(52px);
	@include breakpoint(small){
		margin-top: 0;
		padding: rem(40px);
	}
}
.content-contain{
  opacity: 1;
}
.page-loading{
  .content-contain{
    opacity: 0;
  }
}
.hidden{
	display: none;
}
.btn-scroll.scrolled{
	transform: translate(-50%, 100%);
	pointer-events: none;
	transition: .3s ease-out;
	& .BS-ctn {
		opacity: 0;
		transition: .3s ease-out;
	}
}
a, img {
	outline: 0;
}
[placeholder]::-webkit-input-placeholder {
  transition: opacity 0.3s ease; 
}
[placeholder]:focus::-webkit-input-placeholder {
  opacity: 0;
}
.invisible {
	@include breakpoint(small){
		opacity: 0;
		transition: opacity 0.3s ease-out; 
	}
}
.visible {
	opacity: 1;
}