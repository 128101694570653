#thanks-ctn {
  position: relative;
  display: none;
  overflow: hidden;
  transition: 0.4s ease-out;
}
.no-ty-subsection {
  .ty-subsection {
    display: none;
  }
}
@keyframes fadeInConfirmation {
  100% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
}
.ty {
  &-ctn {
    max-width: 950px;
    margin: 0 auto;
    padding: 50px 15px 50px;
    text-align: center;
    @include breakpoint(small) {
      padding: 130px 15px 130px;
    }
    animation: fadeInConfirmation 1s ease-out;
  }
  &-section-top {}
  &-title {
    margin: 0 0 20px;
    padding: 0;
    @include font(sans);
    font-size: 14px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #212121;
  }
  &-subtitle {
    margin: 0 0 30px;
    padding: 0;
    @include font(sans);
    font-size: 40px;
    color: #212121;
    @include breakpoint(small) {
      font-size: 60px;
    }
  }
  &-copy {
    margin: 0;
    padding: 0;
    max-width: 620px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.25em;
    letter-spacing: 0.04em;
    color: #949494;
    white-space: pre-wrap;
    @include breakpoint(small) {
      font-size: 22px;
    }
  }
  &-subsection {
    margin: 50px 0 0;
    padding: 50px 0 0px;
    border-top: 1px solid #989898;
    @include breakpoint(small) {
      margin: 120px 0 0;
      padding: 120px 0 120px;
    }
  }
  &-subsection-title {
    margin: 0 0 20px;
    padding: 0;
    @include font(sans);
    font-size: 32px;
    color: #212121;
  }
  &-page-links-ctn {
    max-width: 800px;
    margin: 30px auto 0;
  }
  &-page-links {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    max-width: 380px;
    margin: 0 auto 20px;
    padding: 20px;
    @include font(sans);
    @include font-smoothing(yes);
    font-size: 20px;
    text-decoration: none;
    color: #212121;
    background: #fff;
    border: 1px solid #bcbcbc;
    overflow: hidden;
    transition: 0.3s ease-out;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      transform: translate(-100%,0);
      background: #585858;
      transition: 0.3s ease-out;
    }
    &:hover {
      color: #fff;
      &:before {
        transform: translate(0,0);
      }
    }
    @include breakpoint(small) {
      width: 45%;
      max-width: 45%;
      margin: 0 2%;
    }
  }
}
