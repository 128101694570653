// For main landing page:

.birdwatchHeader,
.birdwatchEntryHeader {
    background-color: #f6f6f6;
	text-align: center;
    position: relative;
    .hero-image {
        background-repeat: no-repeat;
        background-size: cover;
    }
    .birdwatch-image{
        position: absolute;
        width: 30%;
        min-width: 235px;
        max-width: 450px;
        @include center(vh);
        fill: $c-dark;
        pointer-events: none;
    }

    @include breakpoint(medium) {
        .birdwatchLogo{
            width: 470px;
        }
    }
}
.birdwatchHeader canvas{
	@include mobilebp(){
		display: none;
	}
}

// For individual entries:

.birdwatchEntryHeader {
    color: #fff;
    .birdwatch-back-link{
      @extend .navigation-button;
      left: auto;
      right: rem(18px);
      width: auto;
      text-decoration: none;
    	@include breakpoint(small){
        left: auto;
    		right: rem(60px);
        padding: 10px 24px;
      }
    }
    .birdwatch-back-link .birdwatch-image-single{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 140px;
      min-width: 140px;
      max-width: 140px;
      padding-left: 20px;
      fill: $c-dark;
    }
    .birdwatch-back-link .birdwatch-arrow{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      font-size: 0;
    }
    .birdwatch-back-link .birdwatch-arrow svg{
      display: inline-block;
      vertical-align: middle;
      width: 5px;
      height: 8px;
      fill: $c-dark;
    }
    .birdwatch-back-link .birdwatch-arrow:after{
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 2px;
      width: 18px;
      background: $c-dark;
    }
    .birdwatch-back-link .birdwatch-image-single svg{
      display: block;
    }
    .heroCopy {
        position: absolute;
        @include center(vh);
    }
    .birdwatchEntryTitle {
        color: #fff;
        font-family: 'NeuzeitSLTW01-BookHeavy';
        font-weight: 300;
        background-color: transparent;
        font-size: 60px;
        margin: 0;
        @include breakpoint(small){
            font-size: 70px;
        }
    }

    .birdwatchEntrySubtitle {
      @include font(freight-text);
      font-style: normal;
      font-size: rem(22px);
      letter-spacing: .04em;
    }
}
